import { Formik } from "formik";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import { Button, Divider, Grid, Typography } from "@mui/material";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import { ScrollToFieldError } from "../../../components/admin/common/ScrollToFieldError";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import * as yup from 'yup';
import OZInput from "../../../components/admin/common/OZInput";
import { addEditPlan, getFeatureList, getRoleList } from "../../../services/api-service";
import { useEffect, useMemo, useState } from "react";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";

const AddPlans = (props) => {

  const [loading, setLoading] = useState(false);

  async function saveEditPlan(data, type) {
    setLoading(true);
    try {
      addEditPlan(data)
        .then((resp) => {
          console.log("Status", resp)
          if (resp?.Status === "Success") {
            if (type === 'save') {
              showSuccessNotification(`Plan ${data.name} Created Successfully`)
            } else {
              showSuccessNotification(`Plan ${data.name} Updated Successfully`)
            }
            props?.onClose();
            props?.updateFunction();
          }
          else {
            showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message || "Failed to create the Plan");
          }
        })
        .catch((e) => {
          if (type === 'save') {
            showErrorNotification("Failed to create the Plan");
          } else {
            showErrorNotification("Failed to Update the Plan");
          }
        });
    } catch (e) {
      if (type === 'save') {
        showErrorNotification("Failed to create the Plan");
      } else {
        showErrorNotification("Failed to Update the Plan");
      }
    }
    setLoading(false);
  };

  const DataForm = (props) => {
    const [roles, setRoles] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [disableForm, setDisableForm] = useState(props?.mode != ADMIN_FORM_MODES.add || false);

    useEffect(() => {
      getFeatureLists();
      getRoleLists();
    }, [])

    async function getRoleLists() {
      try {
        getRoleList()
          .then((resp) => {
            const respData = resp?.Data?.map(item => ({
              id: item.id,
              name: item.name
            }))
            setRoles(respData || []);
          })
          .catch((e) => {
            setRoles([]);
          });
      } catch (e) {
        showErrorNotification("Error in Feature list");
      }
    };

    async function getFeatureLists() {
      try {
        getFeatureList()
          .then((resp) => {
            const respData = resp?.Data?.map(item => ({
              id: item.id,
              name: item.name
            }))
            setFeatureList(respData || []);
          })
          .catch((e) => {
            setFeatureList([]);
          });
      } catch (e) {
        showErrorNotification("Error in Feature list");
      }
    };

    const onClickEdit = () => {
      setDisableForm(false);
    }

    const onClickDiscardEdit = () => {
      setDisableForm(true);
    }

    let initData = (props?.mode != ADMIN_FORM_MODES.add && props.rowData) || {
      name: '',
      roles: [],
      features: [],
    }
    const validationSchema = useMemo(() => (yup.object({
      name: yup
        .string('Enter Plan Name')
        .test("maxDigits",
          "Plan Name should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-",
          (number) => String(number).length >= 3 && String(number).length <= 50)
        .matches(/^([A-Za-z\d]((\.|@|_|\-)?[A-Za-z\d])+)$/, "Plan Name should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
        .required('Plan Name is required'),
      roles: yup
        .array()
        .of(yup.object().shape({
          id: yup.number().required("Role Selection is required"),
          name: yup.string().required("Role Selection is required"),
        }))
        .required("Role Selection is required")
        .min(1, "Role Selection field must have at least 1 item "),
      features: yup
        .array()
        .of(yup.object().shape({
          id: yup.number().required("List Selection is required"),
          name: yup.string().required("List Selection is required"),
        }))
        .required("List Selection is required")
        .min(1, "List Selection field must have at least 1 item "),
    })), []);

    return (
      <div className="ozAddSubAdminForm">
        <Formik
          initialValues={initData}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(data) => {
            console.log("saved data", data)
            if (props?.mode != ADMIN_FORM_MODES.add) {
              delete data.createdDate;
              delete data.updatedDate;
            }
            if (props?.mode === ADMIN_FORM_MODES.add) {
              saveEditPlan(data, 'save');
            } else {
              saveEditPlan(data, 'edit');
            }
          }}
        >
          {(propsForm) => (
            <form
              onSubmit={propsForm.handleSubmit}
              id="userForm"
              className="ozcw-scrollbar"
            >
              <Grid container={true} display="flex">
                <div
                  style={{
                    marginLeft: "80px",
                  }}
                >
                  <Typography
                    className="oz-page-header"
                    onClick={props.onClose}
                  >
                    {" "}
                    Plans / Add Plan{" "}
                  </Typography>
                  <Typography className="oz-page-heading">
                    {" "}
                    {props?.mode === ADMIN_FORM_MODES.add ? "Plan Creation" : props?.rowData?.name}{" "}
                  </Typography>
                  <div
                    style={{
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                      width: "81vw",
                    }}
                  >
                    <Grid
                      className="fieldsStyle"
                      container
                      spacing="20px"
                    >
                      {props?.mode != ADMIN_FORM_MODES.add && (
                        <>
                          <Typography
                            style={{ fontSize: '14px', marginLeft: 'auto', marginRight: '2%', color: disableForm ? '#536580' : '#3D8BF8' }}
                            className="oz-page-header"
                            onClick={() => {
                              if (disableForm) {
                                onClickEdit();
                              } else {
                                propsForm.resetForm({ values: props?.rowData });
                                onClickDiscardEdit();
                              }
                            }}
                          >
                            {" "}
                            {disableForm ? 'Edit' : 'Discard Edit'}{" "}
                          </Typography>
                        </>
                      )}
                      <Grid
                        className="fieldsStyle"
                        container
                        spacing="20px"
                      >
                        <Grid item xs={5}>
                          <OZInput
                            name="name"
                            label="Plan Name *"
                            placeholder="Plan Name"
                            disabled={disableForm}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        className="fieldsStyle"
                        container
                        spacing="20px"
                      >
                        <Grid item xs={5}>
                          <CustomAutoComplete
                            mode='Add'
                            limitTags={2}
                            name="roles"
                            labelHeader='Role Selection *'
                            placeholder={"Please Select"}
                            Datas={roles}
                            optionLabelName='name'
                            showCheckBox={true}
                            multiple={true}
                            showselectall="true"
                            popupIcon={null}
                            disabled={disableForm}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        paddingTop: "32px",
                        color: "#212121",
                        fontSize: "18px",
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "20px",
                        lineHeight: "21px",
                        fontWeight: 400,
                      }}
                    >
                      <label>Configuration Access</label>
                      <label style={{ color: "#4a5363", fontSize: "12px" }}>Configure the required acces to the user</label>
                    </div>
                    <Divider style={{ borderStyle: 'dotted' }} />
                    <Grid
                      className="fieldsStyle"
                      container
                      spacing="20px"
                    >
                      <Grid item xs={5}>
                        <CustomAutoComplete
                          mode='Add'
                          limitTags={2}
                          name="features"
                          labelHeader='Lists *'
                          placeholder={"Please Select"}
                          Datas={featureList}
                          optionLabelName='name'
                          showCheckBox={true}
                          multiple={true}
                          showselectall="true"
                          popupIcon={null}
                          disabled={disableForm}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{ paddingTop: "43px", paddingRight: "20px" }}
                      justifyContent="end"
                    >
                      <Grid item>
                        <Button
                          onClick={props?.onClose}
                          variant="outlined"
                          className="cancelButton"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            propsForm.handleSubmit();
                          }}
                          variant="contained"
                          className="nextButton"
                          disabled={disableForm}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <ScrollToFieldError />
            </form>
          )}
        </Formik>
      </div>
    );
  }

  return (
    <div>
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
            <LoadingScreen />
          </div>
        ) :
        (
          <div>
            <DataForm {...props} />
          </div>
        )
      }
    </div>
  );
};

export default AddPlans;