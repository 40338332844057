import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, IconButton, Link, Menu, MenuItem, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import React,{useRef} from 'react';
import OZCheckbox from '../OZCheckbox/OZCheckbox';

import '../FilterDrawer/FilterDrawer.css';
import { getFilters } from '../../services/api-service';
import { LocationfilterTypes, filterTypes } from '../../utils/constants';

const useStyles: any = makeStyles((theme: Theme) => ({
	filterContainer: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	filterHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121'
	},
	drawerGutter: {
		paddingTop: '74px'
	}
}));

type Props = {
	toggleDrawer: any;
	isOpen: boolean | undefined;
	allowedFilters?: string[];
	applyFilters?: any;
	existingSelectedFilters?: any[];
	existingFilterCategory?: any | undefined;
	applyFilterOnExistingData?: any;
	additionalApiParams?: any;
	filterType?: any;
	NewWidget?: any;
	setResponseLength?: any;
	RunningStatusWidget?: any;
};

const FilterDrawer = ({ isOpen, toggleDrawer, NewWidget, allowedFilters, applyFilters = () => { }, applyFilterOnExistingData = () => { }, existingSelectedFilters = [], existingFilterCategory, additionalApiParams = {}, setResponseLength = () => { }, filterType='', RunningStatusWidget}: Props) => {
	const classes = useStyles();
	const { useState, useEffect } = React;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [selectedFilters, setSelectedFilters] = useState<any[]>(existingSelectedFilters || []);
	const [searchText, setSearchText] = useState<string>('');
	const searchInputRef = useRef<any>();
	// const [selectAllClicked, setSelectAllClicked] = useState(false);

	const [filters, setFilters] = useState([]);

	const allowedFilterCategoryList = allowedFilters?.length ? NewWidget ? LocationfilterTypes.filter((item) => allowedFilters.includes(item.value)) : filterTypes.filter((item) => allowedFilters.includes(item.value)) : [...filterTypes];
	const existingFilteredList = existingFilterCategory ? allowedFilterCategoryList?.filter(item => item.id === existingFilterCategory) : [];
	const [filterSection, setFilterSection] = useState(existingFilteredList?.length > 0 ? existingFilteredList[0] : allowedFilterCategoryList[0]);

	const filterCategoryElements = allowedFilterCategoryList.map((item: any, index: number) => (
		<MenuItem key={index} className="download-item" onClick={() => handleClose(item)}>
			{item.label}
		</MenuItem>
	));

	const handleOnCheckboxChange = (event: any) => {
		// setSelectAllClicked(false);
		if (event?.target?.checked && event?.target?.value) {
			const existingSelectedFilters: any = [...selectedFilters];
			existingSelectedFilters.push(event.target.value);
			setSelectedFilters(existingSelectedFilters);
		} else {
			const newSelectedFilters: any = selectedFilters.filter((item) => item.id !== event?.target?.value?.id);
			setSelectedFilters(newSelectedFilters);
		}
	};

	const isChecked = (item: any) => {
		return selectedFilters.some((filterItem) => item.Id === filterItem.id);
	};


	const filterItems = searchText.length ? filters.filter((item: any) => item.Name && item.Name.toLowerCase().includes(searchText.toLowerCase())) : filters;

	// const filterItems = searchText.length ? filters.filter((item: any) => item.Name.toLowerCase().includes(searchText.toLowerCase())) : filters;

	const filterItemListElements = filterItems?.map((item: any, index: number) => (
		<Grid key={index} item xs={6} className="filter-checkbox-col">
			<OZCheckbox checked={isChecked(item)} label={item.Name} id={item.Id} onChange={handleOnCheckboxChange} />
		</Grid>
	));
	const handleClose = (type: any = null) => {
		if (type) {
			setFilterSection(type);
			clearAll();
		}
		setAnchorEl(null);
	};
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSearchTextChange = (event: any) => {
		setSearchText(event?.target?.value);
	};

	const handleDoubleClick = () => {
		if (searchInputRef.current) {
			searchInputRef.current.select();
		}
	};

	const getFilterData = async () => {
		if (!filterSection?.value) return;
		let runningStatusFilterSection = {
			"id": 1,
			"label": "Campaign",
			"value": "Campaign"
		};
		let response : any = [];

		if(RunningStatusWidget){
			response = await getFilters(filterType ? filterType : runningStatusFilterSection.value, additionalApiParams);
		}else{
			response = await getFilters(filterType ? filterType : filterSection.value, additionalApiParams);
		}
		setFilters(response);
		setResponseLength(response.length)
	};

	const selectAll = () => {
		// setSelectAllClicked(true);
		setSelectedFilters(filters.map((item: any) => ({ id: item.Id, label: item.Name })));
	};
	const clearAll = () => {
		setSelectedFilters([]);
	};

	const handleApplyFilters = () => {
		// if (selectedFilters.length > 10 && !selectAllClicked) {
		// 	alert("Cannot apply more than 10 filters.");
		// 	return;
		// }
		applyFilters({ selectedFilters, filterType: filterSection.id , filterSection: filterSection });
	};
	useEffect(() => {
		setSelectedFilters(existingSelectedFilters);
	}, [existingSelectedFilters]);

	React.useEffect(() => {
		if(existingSelectedFilters?.length >0){
			getFilteredSelectedData();
		}
	}, []);

	const getFilteredSelectedData = async () => {
		if (!filterSection?.value) return;
		const responseData: any = await getFilters(filterType ? filterType : filterSection.value, additionalApiParams);
		const filteredSelectedFilters = selectedFilters.filter((filter: any) =>
			responseData.some((responseFilter: any) => responseFilter.Id === filter.id)
		);
		applyFilterOnExistingData(filteredSelectedFilters);
		setSelectedFilters(filteredSelectedFilters)
	};
	
	useEffect(() => {
		if (isOpen) {
			getFilterData();
		}
		return () => { };
	}, [isOpen, filterSection]);

	return (
		<Drawer open={isOpen} anchor={'right'} onClose={() => toggleDrawer(false)}>
			<Box sx={{ width: 455 }} className={classes.filterContainer}>
				<div className={classes.drawerGutter}>
					<div className={classes.filterHeader}>
						<span className={classes.headerText}>Filter</span>
						<IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
							<CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
						</IconButton>
					</div>
					<div className="drawer-search-container">
						<>
							<div className="drawer-search-dd" onClick={handleMenuOpen}>
								{RunningStatusWidget && <span>Campaign</span>}
								{!RunningStatusWidget && <span>{filterSection.label}</span> }
								{/* <span>{filterSection.label}</span> */}
							</div>
							{!RunningStatusWidget &&
								<Menu
									id="download-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left'
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left'
									}}
									open={open}
									onClose={() => handleClose()}
									className="custom-search-dropdown"
								>
									{filterCategoryElements}
								</Menu>
							}
						</>
						<>
							<div className="drawer-search-bar">
								<input type="text" ref={searchInputRef} placeholder="Search" value={searchText} onChange={handleSearchTextChange} onDoubleClick={handleDoubleClick} />
								<img src={`${process.env.PUBLIC_URL}/img/search-icon.svg`} width="12" alt="Filter Search"></img>
							</div>
						</>
					</div>
				</div>
				<div>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<div className="filter-select-links">
								<Link onClick={() => selectAll()} underline="hover">
									Select All
								</Link>
								<Link onClick={() => clearAll()} underline="hover">
									Clear All
								</Link>
							</div>
						</Grid>
					</Grid>
				</div>
				<div className="filter-items-container">
					<Grid container spacing={2}>
						<>{filterItemListElements}</>
					</Grid>
				</div>
				<div className="filter-items-footer">
					<Button variant="contained" onClick={() => handleApplyFilters()}>
						Apply Filter
					</Button>
					{/* <div className="footer-line">
						<img src={`${process.env.PUBLIC_URL}/img/info-icon.svg`} width="12" alt="Info"></img> Adding more than 5 filters will take you to table view
					</div> */}
				</div>
			</Box>
		</Drawer>
	);
};

export default FilterDrawer;
