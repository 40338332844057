import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../../components/admin/common/NotiService';
import OZDialog from '../../../components/admin/common/OZDialog';
import OZInput from '../../../components/admin/common/OZInput';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import OZSelect from '../../../components/admin/common/OZSelect';
import { getAvailableIntegration, saveCloudStorage } from '../../../services/api-service';

const CloudStorage = (mainprops) => {

    console.log(mainprops);

    const [availableIntegrations, setAvailableIntegrations] = useState([])
    const [firstScreen, setFirstScreen] = useState(true);
    const [secondScreen, setSecondScreen] = useState(false);
    const namesWithData = [];
    const [alreadyIntegratedData, setAlreadyIntegratedData] = useState(null)
    console.log(alreadyIntegratedData)
    const [loading, setLoading] = useState(false);
    const [provider, setProvider] = useState(null);
    const [type, setType] = useState(null);
    let providersToShowInNewConnection = ["AmazonS3", "GoogleCloud", "Azure"]
    let typesToShowInNewConnection = ["Key", "ARN"]

    if (mainprops.awskey !== undefined && mainprops.awskey !== null) {
        namesWithData.push({
            name: mainprops.awskey.name,
            date: mainprops.awskey.date || ""
        })
        typesToShowInNewConnection = typesToShowInNewConnection.filter(type => type !== "Key");
    }
    if (mainprops.awsArn !== undefined && mainprops.awsArn !== null) {
        namesWithData.push({
            name: mainprops.awsArn.name,
            date: mainprops.awsArn.date || ""
        })
        typesToShowInNewConnection = typesToShowInNewConnection.filter(type => type !== "ARN");
    }
    if (mainprops.gcp !== undefined && mainprops.gcp !== null) {
        namesWithData.push({
            name: mainprops.gcp.name,
            date: mainprops.gcp.date || ""
        })
        providersToShowInNewConnection = providersToShowInNewConnection.filter(provider => provider !== "GoogleCloud");
    }
    if (mainprops.azure !== undefined && mainprops.azure !== null) {
        namesWithData.push({
            name: mainprops.azure.name,
            date: mainprops.azure.date || ""
        })
        providersToShowInNewConnection = providersToShowInNewConnection.filter(provider => provider !== "Azure");
    }
    if (mainprops.awskey !== undefined && mainprops.awskey !== null && mainprops.awsArn !== undefined && mainprops.awsArn !== null) {
        providersToShowInNewConnection = providersToShowInNewConnection.filter(provider => provider !== "AmazonS3");
    }

    useEffect(() => {
        getAvailableIntegrations();
    }, [])

    async function getAvailableIntegrations() {
        try {
            getAvailableIntegration()
                .then((resp) => {
                    if (resp.Status === "Success") {
                        setAvailableIntegrations(resp.Data)
                    }
                    else {
                        showWarningNotification(resp.Message);
                    }
                })
                .catch((e) => {
                    setAvailableIntegrations([])
                });
        } catch (e) {
            showErrorNotification("Error in integrations list");
        }
    };

    const validationSchema1 = useMemo(
        () =>
            yup.object({
                accessKey: yup
                    .string()
                    .required("AccessKey is Required"),
                secretKey: yup
                    .string()
                    .required("SecretKey is Required"),
                bucket: yup
                    .string()
                    .required("Bucket Name is Required"),
                folder: yup
                    .string()
                    .required("Folder Name is Required"),
                isZip: yup
                    .string()
                    .required("IsZip is Required"),
            }),
        []
    );

    const validationSchema2 = useMemo(
        () =>
            yup.object({
                accessKey: yup
                    .string()
                    .required("AccessKey is Required"),
                secretKey: yup
                    .string()
                    .required("SecretKey is Required"),
                bucket: yup
                    .string()
                    .required("Bucket Name is Required"),
                folder: yup
                    .string()
                    .required("Folder Name is Required"),
                roleArn: yup
                    .string()
                    .required("Role Arn is Required"),
                externalId: yup
                    .string()
                    .required("External Id is Required"),
                isZip: yup
                    .string()
                    .required("IsZip is Required"),
            }),
        []
    );

    const validationSchema3 = useMemo(
        () =>
            yup.object({
                gcptype: yup
                    .string()
                    .required("GCP Type is Required"),
                project_id: yup
                    .string()
                    .required("ProjectId is Required"),
                private_key_id: yup
                    .string()
                    .required("Private Key Id is Required"),
                private_key: yup
                    .string()
                    .required("Private Key is Required"),
                client_email: yup
                    .string()
                    .required("Client Email is Required"),
                client_id: yup
                    .string()
                    .required("Client Id is Required"),
                auth_uri: yup
                    .string()
                    .required("Auth Uri is Required"),
                token_uri: yup
                    .string()
                    .required("Token Uri is Required"),
                auth_provider_x509_cert_url: yup
                    .string()
                    .required("auth_provider_x509_cert_url is Required"),
                client_x509_cert_url: yup
                    .string()
                    .required("client_x509_cert_url is Required"),
                bucket: yup
                    .string()
                    .required("Bucket Name is Required"),
                folder: yup
                    .string()
                    .required("Folder Name is Required"),
            }),
        []
    );

    const validationSchema4 = useMemo(
        () =>
            yup.object({
                accountUrl: yup
                    .string()
                    .required("Account Url is Required"),
                credential: yup
                    .string()
                    .required("Credential is Required"),
                containerName: yup
                    .string()
                    .required("Container Name is Required"),
                folder: yup
                    .string()
                    .required("Folder Name is Required"),
                isZip: yup
                    .string()
                    .required("IsZip is Required"),
            }),
        []
    );

    useEffect(() => {
        setProvider(alreadyIntegratedData !== null ? alreadyIntegratedData?.provider : providersToShowInNewConnection.length > 0 ? providersToShowInNewConnection[0] : null);
        setType(alreadyIntegratedData !== null ? alreadyIntegratedData?.type : typesToShowInNewConnection.length > 0 ? typesToShowInNewConnection[0] : null)
    }, [alreadyIntegratedData])

    const handleProviderChange = (event) => {
        if (alreadyIntegratedData === null) {
            setProvider(event.target.value);
        }
    };

    const handleTypeChange = (event) => {
        if (alreadyIntegratedData === null) {
            setType(event.target.value);
        }
    };

    const onClickNewConnectionButton = () => {
        setFirstScreen(false);
        setSecondScreen(true);
        setAlreadyIntegratedData(null)
    }

    const onClickCancelOnCloudStorageScecondScreen = () => {
        setFirstScreen(true);
        setSecondScreen(false);
    }

    const onClickName = (name) => {
        setFirstScreen(false);
        setSecondScreen(true);
        if (name === "AWS-Key") {
            setAlreadyIntegratedData(mainprops.awskey)
        }
        if (name === "AWS-ARN") {
            setAlreadyIntegratedData(mainprops.awsArn)
        }
        if (name === "GCP") {
            setAlreadyIntegratedData(mainprops.gcp)
        }
        if (name === "Azure") {
            setAlreadyIntegratedData(mainprops.azure)
        }
    }

    function convertToRequiredDateString(dateStr) {
        const date = new Date(dateStr);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
        return formattedDate;
    }

    const DataForm = () => {
        let initData = {}
        if ((provider === "AmazonS3" && type === "Key")) {
            initData = {
                accessKey: alreadyIntegratedData !== null ? alreadyIntegratedData?.accessKey : '',
                secretKey: alreadyIntegratedData !== null ? alreadyIntegratedData?.secretKey : '',
                bucket: alreadyIntegratedData !== null ? alreadyIntegratedData?.bucket : '',
                folder: alreadyIntegratedData !== null ? alreadyIntegratedData?.folder : '',
                isZip: alreadyIntegratedData !== null ? alreadyIntegratedData?.isZip : '',
            }
        }
        if (provider === "Azure") {
            initData = {
                accountUrl: alreadyIntegratedData !== null ? alreadyIntegratedData?.accountUrl : '',
                credential: alreadyIntegratedData !== null ? alreadyIntegratedData?.credential : '',
                containerName: alreadyIntegratedData !== null ? alreadyIntegratedData?.containerName : '',
                folder: alreadyIntegratedData !== null ? alreadyIntegratedData?.folder : '',
                isZip: alreadyIntegratedData !== null ? alreadyIntegratedData?.isZip : '',
            }
        }
        if (provider === "AmazonS3" && type === "ARN") {
            initData = {
                accessKey: alreadyIntegratedData !== null ? alreadyIntegratedData?.accessKey : '',
                secretKey: alreadyIntegratedData !== null ? alreadyIntegratedData?.secretKey : '',
                bucket: alreadyIntegratedData !== null ? alreadyIntegratedData?.bucket : '',
                folder: alreadyIntegratedData !== null ? alreadyIntegratedData?.folder : '',
                roleArn: alreadyIntegratedData !== null ? alreadyIntegratedData?.roleArn : '',
                externalId: alreadyIntegratedData !== null ? alreadyIntegratedData?.externalId : '',
                isZip: alreadyIntegratedData !== null ? alreadyIntegratedData?.isZip : '',
            }
        }
        if (provider === "GoogleCloud") {
            initData = {
                gcptype: alreadyIntegratedData !== null ? alreadyIntegratedData?.gcptype : '',
                project_id: alreadyIntegratedData !== null ? alreadyIntegratedData?.project_id : '',
                private_key_id: alreadyIntegratedData !== null ? alreadyIntegratedData?.private_key_id : '',
                private_key: alreadyIntegratedData !== null ? alreadyIntegratedData?.private_key : '',
                client_email: alreadyIntegratedData !== null ? alreadyIntegratedData?.client_email : '',
                client_id: alreadyIntegratedData !== null ? alreadyIntegratedData?.client_id : '',
                auth_uri: alreadyIntegratedData !== null ? alreadyIntegratedData?.auth_uri : '',
                token_uri: alreadyIntegratedData !== null ? alreadyIntegratedData?.token_uri : '',
                auth_provider_x509_cert_url: alreadyIntegratedData !== null ? alreadyIntegratedData?.auth_provider_x509_cert_url : '',
                client_x509_cert_url: alreadyIntegratedData !== null ? alreadyIntegratedData?.client_x509_cert_url : '',
                bucket: alreadyIntegratedData !== null ? alreadyIntegratedData?.bucket : '',
                folder: alreadyIntegratedData !== null ? alreadyIntegratedData?.folder : '',
            }
        }


        const saveCloudstorage = async (values) => {
            setLoading(true);

            const nameToFind = (provider === "GoogleCloud" ? "GCP" : provider === "Azure" ? "Azure" : (provider === "AmazonS3" && type === "Key") ? "AWS-Key" : (provider === "AmazonS3" && type === "ARN") ? "AWS-ARN" : null);
            const exists = availableIntegrations.some(obj => obj.name === nameToFind);
            let foundObjInAvailableIntegrations = null;
            if (exists) {
                foundObjInAvailableIntegrations = availableIntegrations.find(obj => obj.name === nameToFind);
            }

            if (foundObjInAvailableIntegrations !== null && foundObjInAvailableIntegrations !== undefined) {

                if (provider === "GoogleCloud") {
                    const credJson = {
                        type: values.gcptype,
                        project_id: values.project_id,
                        private_key_id: values.private_key_id,
                        private_key: values.private_key,
                        client_email: values.client_email,
                        client_id: values.client_id,
                        auth_uri: values.auth_uri,
                        token_uri: values.token_uri,
                        auth_provider_x509_cert_url: values.auth_provider_x509_cert_url,
                        client_x509_cert_url: values.client_x509_cert_url
                      };
                      const restJson = {
                        bucket: values.bucket,
                        project: values.project_id,
                        folder: values.folder,
                      }
                    values = { credJson: JSON.stringify(credJson), ...restJson, provider: provider, type: (provider === "AmazonS3" ? type : null), id: foundObjInAvailableIntegrations.id, name: foundObjInAvailableIntegrations.name }
                }
                else if (provider === "Azure") {
                    values = { ...values, provider: provider, type: (provider === "AmazonS3" ? type : null), id: foundObjInAvailableIntegrations.id, name: foundObjInAvailableIntegrations.name }
                }
                else if (provider === "AmazonS3" && type === "Key") {
                    values = { ...values, provider: provider, type: (provider === "AmazonS3" ? type : null), id: foundObjInAvailableIntegrations.id, name: foundObjInAvailableIntegrations.name }
                }
                else if (provider === "AmazonS3" && type === "ARN") {
                    values = { ...values, provider: "AmazonS3WithRole", type: (provider === "AmazonS3" ? type : null), id: foundObjInAvailableIntegrations.id, name: foundObjInAvailableIntegrations.name }
                }

                try {
                    saveCloudStorage(values)
                        .then((resp) => {
                            if (resp.Status === "Success") {
                                setLoading(false);
                                showSuccessNotification("Cloud Storage details saved successfully")
                                setFirstScreen(false);
                                setSecondScreen(false);
                                mainprops.getUserIntegrationsData()
                                mainprops.onClickCancel()
                            }
                            else {
                                setLoading(false);
                                showErrorNotification(resp.Message)
                                console.log(resp.Message)
                            }
                        })
                        .catch((e) => {
                            setLoading(false);
                            console.error(e);
                            showErrorNotification("Error saving CloudStorage details");
                        });
                }
                catch {
                    setLoading(false);
                    showErrorNotification(`Error in saving CloudStorage details`)
                }
            }
            else {
                setLoading(false);
                showWarningNotification("Integration is not available")
            }
        }

        return (
            <div>
                <Formik
                    initialValues={initData}
                    validationSchema={((provider === "AmazonS3" && type === "Key")) ? validationSchema1 : (provider === "AmazonS3" && type === "ARN") ? validationSchema2 : (provider === "GoogleCloud") ? validationSchema3 : (provider == "Azure") ? validationSchema4 : null}
                    onSubmit={(values) => {
                        console.log(values);
                        saveCloudstorage(values);
                    }}
                >
                    {
                        (props) => (
                            <div>
                                <form
                                    onSubmit={props.handleSubmit}
                                    id="cloudStorageForm"
                                    style={{
                                        width: "682px",
                                    }}
                                >
                                </form>

                                <div>
                                    <Divider variant="fullWidth" sx={{ borderColor: "#D6D6D7" }} />
                                </div>
                                {firstScreen && !secondScreen && namesWithData && namesWithData.length > 0 &&
                                    <div style={{ margin: "20px", border: '1px solid #D6D9DC', borderRadius: "8px", padding: "10px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                                            <label style={{ fontSize: "14px", fontWeight: 600, color: "#909DAD", minWidth: "80px" }}>
                                                S.no.
                                            </label>
                                            <label style={{ fontSize: "14px", fontWeight: 600, color: "#909DAD", minWidth: "275px" }}>
                                                Connection Name
                                            </label>
                                            <label style={{ fontSize: "14px", fontWeight: 600, color: "#909DAD", minWidth: "275px" }}>
                                                Created Date
                                            </label>
                                        </div>
                                        <Divider variant="fullWidth" sx={{ borderColor: "#D6D6D7", marginTop: "8px" }} />
                                        {namesWithData.map((data, index) => {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "row", gap: "20px", marginTop: "8px" }}>
                                                    <label style={{ fontSize: "12px", fontWeight: 400, color: "#3D4C5E", minWidth: "80px" }}>
                                                        {`0${index + 1}.`}
                                                    </label>
                                                    <label style={{ fontSize: "12px", fontWeight: 400, color: "#008DFF", minWidth: "275px", cursor: "pointer" }} onClick={() => { onClickName(data.name) }}>
                                                        {data.name}
                                                    </label>
                                                    <label style={{ fontSize: "12px", fontWeight: 400, color: "#909DAD", minWidth: "275px" }}>
                                                        {convertToRequiredDateString(data.date)}
                                                    </label>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                }
                                {firstScreen && !secondScreen && namesWithData && namesWithData.length === 0 &&
                                    <div style={{ display: "flex", alignItems: 'center', fontSize: "14px", fontWeight: 600, color: "#D6D9DC", justifyContent: "center", margin: "20px" }}>
                                        No Data Found
                                    </div>
                                }
                                {secondScreen && !firstScreen &&
                                    <div style={{ margin: "10px" }}>
                                        <div style={{ marginLeft: "20px", marginRight: "20px", display: "flex", flexDirection: "column" }}>
                                            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                <div style={{ fontSize: "14px", fontWeight: 400, color: "#546881", width: "60px" }}>
                                                    Provider
                                                </div>
                                                <label>:</label>
                                                <div>
                                                    <FormControl sx={{
                                                        paddingLeft: "15px",
                                                        '& .css-s1wlvs-MuiButtonBase-root-MuiRadio-root': {
                                                            paddingRight: "5px"
                                                        }
                                                    }}>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            value={provider}
                                                            onChange={handleProviderChange}
                                                        >
                                                            {(alreadyIntegratedData === null && providersToShowInNewConnection.some(provider => provider === "AmazonS3")) || (alreadyIntegratedData?.provider === "AmazonS3") ?
                                                                <FormControlLabel value="AmazonS3" control={<Radio />} sx={{
                                                                    '& .MuiTypography-root': {
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                        color: "#47586E",
                                                                        cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                    }
                                                                }} label="AWS" />
                                                                : null
                                                            }
                                                            {(alreadyIntegratedData === null && providersToShowInNewConnection.some(provider => provider === "GoogleCloud")) || (alreadyIntegratedData?.provider === "GoogleCloud") ?
                                                                <FormControlLabel value="GoogleCloud" control={<Radio />} sx={{
                                                                    '& .MuiTypography-root': {
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                        color: "#47586E",
                                                                        cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                    }
                                                                }} label="Google Cloud Storage" />
                                                                : null
                                                            }
                                                            {(alreadyIntegratedData === null && providersToShowInNewConnection.some(provider => provider === "Azure")) || (alreadyIntegratedData?.provider === "Azure") ?
                                                                <FormControlLabel value="Azure" control={<Radio />} sx={{
                                                                    '& .MuiTypography-root': {
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                        color: "#47586E",
                                                                        cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                    }
                                                                }} label="Azure" />
                                                                : null
                                                            }
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </Stack>

                                            {provider === "AmazonS3" &&
                                                <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} marginTop={"-10px"}>
                                                    <div style={{ fontSize: "14px", fontWeight: 400, color: "#546881", width: "60px" }}>
                                                        Type
                                                    </div>
                                                    <label>:</label>
                                                    <div>
                                                        <FormControl sx={{
                                                            paddingLeft: "15px",
                                                            '& .css-s1wlvs-MuiButtonBase-root-MuiRadio-root': {
                                                                paddingRight: "5px"
                                                            }
                                                        }}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={type}
                                                                onChange={handleTypeChange}
                                                            >
                                                                {(alreadyIntegratedData === null && typesToShowInNewConnection.some(type => type === "Key")) || (alreadyIntegratedData?.type === "Key") ?
                                                                    <FormControlLabel value="Key" control={<Radio />} sx={{
                                                                        '& .MuiTypography-root': {
                                                                            fontSize: "12px",
                                                                            fontWeight: 400,
                                                                            color: "#47586E",
                                                                            cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                        }
                                                                    }} label="Key" />
                                                                    : null
                                                                }
                                                                {(alreadyIntegratedData === null && typesToShowInNewConnection.some(type => type === "ARN")) || (alreadyIntegratedData?.type === "ARN") ?
                                                                    <FormControlLabel value="ARN" control={<Radio />} sx={{
                                                                        '& .MuiTypography-root': {
                                                                            fontSize: "12px",
                                                                            fontWeight: 400,
                                                                            color: "#47586E",
                                                                            cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                        }
                                                                    }} label="ARN" />
                                                                    : null
                                                                }
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                </Stack>
                                            }
                                        </div>
                                        <Divider style={{ border: '1px dashed #B2BBC6' }} />
                                        <div style={{ marginTop: "10px", backgroundColor: "#F6F8F9", maxHeight: "220px", overflowY: "scroll" }}>
                                            <div style={{ padding: "10px" }}>
                                                <div style={{ color: "#546881", fontSize: "16px", fontWeight: 500 }}>
                                                    {provider === "AmazonS3" ? type === "Key" ? "AWS S3 Auth Using Key" : "AWS S3 Auth Using ARN" : provider === "GoogleCloud" ? "Google Cloud Storage Auth" : "Azure Auth"}
                                                </div>
                                                <div style={{ color: "#47586E", fontSize: "12px", fontWeight: 400, marginBottom: "10px" }}>
                                                    Enter the below information to schedule the report
                                                </div>
                                                <Divider style={{ border: '1px dashed #B2BBC6' }} />
                                            </div>
                                            <Grid style={{ margin: "10px", display: "flex", flexDirection: "column", gap: "12px" }}>
                                                {(provider === "AmazonS3") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='accessKey'
                                                            label='Access Key'
                                                            placeholder="Enter Access key"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "AmazonS3") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='secretKey'
                                                            label='Secret Key'
                                                            placeholder="Enter Secret key"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "Azure") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='accountUrl'
                                                            label='Account URL'
                                                            placeholder="Enter Account URL"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "Azure") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='credential'
                                                            label='Credential'
                                                            placeholder="Enter Credential"
                                                        />
                                                    </Grid>
                                                }
                                                {provider === "GoogleCloud" &&
                                                    <>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='gcptype'
                                                                label='Type'
                                                                placeholder="Service Account"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='project_id'
                                                                label='Project Id'
                                                                placeholder="Enter Project Id"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='private_key_id'
                                                                label='Private Key Id'
                                                                placeholder="Enter Private Key Id"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='private_key'
                                                                label='Private Key'
                                                                placeholder="Enter Private Key"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='client_email'
                                                                label='Client Email'
                                                                placeholder="Enter Client Email"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='client_id'
                                                                label='Client Id'
                                                                placeholder="Enter Client Id"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='auth_uri'
                                                                label='Auth URI'
                                                                placeholder="Enter Auth Uri"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='token_uri'
                                                                label='Token URI'
                                                                placeholder="Enter Token Uri"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='auth_provider_x509_cert_url'
                                                                label='Auth_provider_x509_cert_url'
                                                                placeholder="Enter auth_provider_x509_cert_url"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='client_x509_cert_url'
                                                                label='Client_x509_cert_url'
                                                                placeholder="Enter client_x509_cert_url"
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                                {(provider === "AmazonS3" || provider === "GoogleCloud") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='bucket'
                                                            label='Bucket Name'
                                                            placeholder="Enter Bucket Name"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "Azure") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='containerName'
                                                            label='Container Name'
                                                            placeholder="Enter Container Name"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "AmazonS3" || provider === "Azure" || provider === "GoogleCloud") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='folder'
                                                            label='Folder Name'
                                                            placeholder="Enter Folder Name"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "AmazonS3" && type === "ARN") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='roleArn'
                                                            label='Role Arn'
                                                            placeholder="Enter Role Arn"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "AmazonS3" && type === "ARN") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='externalId'
                                                            label='External Id'
                                                            placeholder="Enter External Id"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "AmazonS3" || provider === "Azure") &&
                                                    <Grid item xs={12}>
                                                        <OZSelect
                                                            name="isZip"
                                                            label="is Zip? "
                                                            placeholder="Select"
                                                            options={["Yes", "No"]}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    }
                </Formik >
            </div >
        )
    }

    return (
        <>
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                <OZDialog open={mainprops.open} onClose={mainprops.onClickCancel} title="Cloud Storage" saveButtonProps={{ form: 'cloudStorageForm', type: 'submit' }} form="cloudStorageForm" showNewConnectionButton={(firstScreen && providersToShowInNewConnection.length > 0) ? true : false} onClickNewConnectionButton={() => { onClickNewConnectionButton() }} onClickCancelOnCloudStorage={onClickCancelOnCloudStorageScecondScreen} hideCancelButton={firstScreen ? true : false} hideSaveButton={firstScreen ? true : false}>
                    <DataForm />
                </OZDialog>
            }
        </>
    )
}

export default CloudStorage