import { useMemo, } from 'react';
import './multiSelect.css';
import { Divider } from '@mui/material';

const MultiSelect = ({
  loggedInAgentsByGroup,
  setSelectedAgents,
  selectedAgents,
  setOpenMenu,
  setOptions,
  options,
  searchOptions,
}) => {

  const toggleItem = (groupId, itemId) => {
    const updatedOptions = options.map(group => {
      if (group.groupName === groupId) {
        return {
          ...group,
          items: group.items.map(item =>
            item.id === itemId ? { ...item, checked: !item.checked } : item
          ),
        };
      }
      return group;
    });
    setOptions(updatedOptions);
  };

  const toggleGroup = groupName => {
    const updatedOptions = options.map(group => {
      if (group.groupName === groupName) {
        const allChecked = group.items.every(item => item.checked);
        return {
          ...group,
          items: group.items.map(item => ({ ...item, checked: !allChecked })),
        };
      }
      return group;
    });
    setOptions(updatedOptions);
  };

  const toggleAll = () => {
    const allChecked = options.every(group =>
      group.items.every(item => item.checked)
    );
    const updatedOptions = options.map(group => ({
      ...group,
      items: group.items.map(item => ({ ...item, checked: !allChecked })),
    }));
    setOptions(updatedOptions);
  };

  const isEveryOneSelected = useMemo(() => options.every(group => group.items.every(item => item.checked)), [options]);

  return (
    <div>
      <div className="multi-select-custom-checkbox">
        <input
          id="everyone"
          type="checkbox"
          onChange={toggleAll}
          checked={isEveryOneSelected}
        />
        <label
          style={{
            fontWeight: 500,
            color: isEveryOneSelected ? "#008DFF" : "#1D242D",
            fontSize: "12px"
          }}
          htmlFor="everyone">Everyone</label>
      </div>
      {searchOptions && searchOptions.length > 0 && searchOptions.map(group => (
        <div key={"grp" + group.groupName}>
          {group.groupName !== "NullGroupIdRecords" && <div className='multi-select-custom-checkbox' style={{}}>
            <input
              type="checkbox"
              id={group.groupName}
              checked={group.items.filter(item => item.checked).length === group.items.length}
              onChange={() => toggleGroup(group.groupName)}
            />
            <label htmlFor={group.groupName}
              style={{
                fontWeight: 500,
                color: group.items.filter(item => item.checked).length === group.items.length ? "#008DFF" : "#1D242D",
                fontSize: "12px"
              }}>
              {group.groupName}</label>
          </div>}
          {group.items.map(item => (
            <div
              key={"data" + item.id + group.groupName}
              style={{
                paddingLeft: group.groupName === "NullGroupIdRecords" ? "" : "26px"
              }}
              className='multi-select-custom-checkbox'>
              <input
                id={item.id + group.groupName}
                type="checkbox"
                checked={item.checked}
                onChange={() => toggleItem(group.groupName, item.id)}
              />
              <label htmlFor={item.id + group.groupName}
                style={{
                  fontWeight: 400,
                  color: item.checked ? "#008DFF" : "#1D242D",
                  fontSize: "12px",
                }}
              >{item.label}</label>
            </div>
          ))}
          <Divider className='ozcw-multi-divider' sx={{ borderStyle: "dashed" }} />
        </div>
      ))}
    </div>
  );
};

export default MultiSelect;
