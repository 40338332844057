import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState, useMemo, useEffect } from 'react';
import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import '../../pages/admin/AdminSettings/Adminsettings.css';
import { MuiOtpInput } from 'mui-one-time-password-input';
import './Login.css';
import LoadingButton from '@mui/lab/LoadingButton';


const OtpVerification = (props) => {
    const [open, setOpen] = useState(props?.open);
    const [otp, setOtp] = useState("");
    const [seconds, setSeconds] = useState(30);
    const [loading, setLoading] = useState(false);

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '44px', fontSize: '14px', fontWeight: 400, backgroundColor: '#3D8BF8', marginLeft: '55px' }), [])

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };

    const onClickPreviousButton = () => {
        setOpen(false);
        props.onClose();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const handleChange = (newValue) => {
        setOtp(newValue)
    };

    const resendOTP = () => {
        setOtp("");
        setSeconds(30);
        props.onClickResend();
    };

    const VerifyOTP = (e) => {
        setLoading(true);
        props.onClickVerify(otp);
        setLoading(false);
    }

    return (
        <div className='admin-change-password-form'>

            <Dialog
                open={open}
                onClose={handleClose}
                sx={{ zIndex: 10000 }}
            >
                <Stack direction={'row'} display={'flex'}>
                    <Stack direction={'row'} sx={{ marginLeft: '1rem' }}>
                        <IconButton
                            onClick={onClickPreviousButton}>
                            <img
                                src={`${process.env.PUBLIC_URL}/icons/previousButton.svg`}
                                alt="previousButton"
                            ></img>
                        </IconButton>
                        <DialogTitle sx={{ fontSize: '18px', paddingLeft: '8px' }}>OTP</DialogTitle>
                    </Stack>
                    {handleClose ? (
                        <IconButton
                            style={{ marginLeft: '12rem' }}
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                color: '#99A0A8',
                            }}
                        >
                            <img src={`${process.env.PUBLIC_URL}/icons/close.svg`} alt='x' ></img>
                        </IconButton>
                    ) : null}
                </Stack>
                <div style={{ alignSelf: 'center' }}>
                    <img
                        style={{ marginLeft: '3rem' }}
                        src={`${process.env.PUBLIC_URL}/icons/forgotPassword.svg`}
                        alt="forgotpasswordimage"
                    ></img>
                    <Typography sx={{ fontSize: '18px' }}>Please enter 6 digit digital code</Typography>
                </div>
                <div className="col-text-center">
                    <div style={{ width: '20rem', padding: '15px' }} >
                        <MuiOtpInput length={6} TextFieldsProps={{ size: 'small', type: 'password' }} value={otp} onChange={handleChange} />
                    </div>
                    <div className="countdown-text" style={{ display: 'flex', direction: 'row', marginBottom: '24px' }} >
                        {seconds > 0 ? (
                            <>
                                <Typography sx={{ marginLeft: '20px', marginTop: '10px' }}>
                                    Resend OTP in
                                </Typography>
                                <Typography sx={{ color: '#008DFF', marginLeft: '5px', marginTop: '10px' }}>
                                    00:
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                </Typography>
                            </>
                        ) : (
                            <Button
                                disabled={seconds > 0}
                                onClick={resendOTP}
                                sx={{ borderRadius: '8px', height: '44px', fontSize: '14px', fontWeight: 400, paddingBottom: '10px', marginLeft: '30px' }}
                            >
                                Resend Code
                            </Button>
                        )}
                        <LoadingButton sx={buttonStyle} type="submit" size="large" onClick={VerifyOTP} loading={loading} variant="contained">
                            Verify
                        </LoadingButton>
                    </div>
                </div>
            </Dialog>
        </div >
    )
}
export default OtpVerification