import React from 'react';
import { Badge, Box, Card, Grid, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useRef, useState } from 'react';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { getOutboundMonitorPacing } from '../../../services/api-service';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';

const useStyles: any = makeStyles((theme: Theme) => ({
	typeButton: {
		padding: '5px !important'
	},
	filterItem: {
		fontSize: 12,
		height: 24,
		borderRadius: 20,
		background: 'rgba(0, 142, 255, 0.1)',
		border: '1px solid #008eff',
		padding: `0 ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center'
	},
	itemIndicator: {
		width: 5,
		height: 5,
		borderRadius: 1,
		display: 'inline-block',
		position: 'relative',
		top: '-2px',
		marginRight: 4
	},
	filterItemText: {
		margin: 0,
		color: '#536580'
	},
	filterItemSubText: {
		color: '#99A0A8'
	}
}));

let interval: any;

function Pacing(props: any) {
	const classes = useStyles();
	const downloadContent = useRef<null | any>(null);
	const chartElement: any = useRef(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [pacingFilter, setPacingFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [graphData, setGraphData] = useState<any>([]);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '1');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [campaignName, setCampaignName] = useState<any>([]);

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const clearFilter = () => {
		setPacingFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = pacingFilter.filter((item: any) => item.id !== id);
		setPacingFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = pacingFilter.map((item: any, index: number) => (
		<div className={classes.filterItem}>
			<Grid container direction={'row'} alignItems="center" gap={2}>
				<Grid item>
					<p className={classes.filterItemText}>
						<span className={classes.itemIndicator} style={{ background: item.color }}></span>
						{item.label}
					</p>
					<b>{item.target}</b>
				</Grid>
				<Grid item display={'flex'} alignItems="center">
					<CloseOutlinedIcon onClick={() => handleFilterDelete(item.id)} color="primary" style={{ cursor: 'pointer', fontSize: '14px' }} />
				</Grid>
			</Grid>
		</div>
	));

	const handleFilterExistingData = (filteredData: any) => {
		setPacingFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setPacingFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const retrieveGraphData = async (filters: any) => {
		const apiResponse: any[] = await getOutboundMonitorPacing({ campaignId: filters });

		const callsData = apiResponse.map((item: any) => ({
			y: +item.currentPacing,
			...item
		}));
		const campaignName = apiResponse.map((item: any) => item.campaignName);
		setCampaignName(campaignName);
		const graphOptions = [
			{
				name: 'Calls',
				color: '#008DFF',
				data: callsData
			}
		];

		setGraphData(graphOptions);
	};

	const options = {
		chart: {
			type: 'column',
			height: 350,
		},
		credits: false,
		title: {
			text: ''
		},
		xAxis: {
			title: {
				text: 'Campaigns ➡',
				style: {
					fontWeight: 'bold'
				}
			},
			categories: campaignName,
			crosshair: true,
			labels: {
				rotation: -45,
				style: {
					fontSize: '12px',
					fontFamily: 'Roboto'
				}
			}
		},
		yAxis: {
			title: {
				text: 'Calls',
				style: { fontWeight: 'bold' }
			},
			gridLineDashStyle: 'dash'
		},
		legend: {
			// layout: 'horizontal',
			// align: 'center',
			// verticalAlign: 'top',
			// itemMarginTop: 10
			enabled: false
		},
		tooltip: {
			shared: true
		},
		plotOptions: {
			series: {
				cursor: 'pointer',
				pointWidth: 10,
				borderRadius: 2,
				groupPadding: 0.3
			}
		},
		series: graphData
	};
	// [
	// 	{
	// 		name: 'Upload',
	// 		color: 'rgba(165,170,217,1)',
	// 		data: [
	// 			13.93, 13.63, 13.73, 13.67, 14.37, 14.89, 14.56, 14.32, 14.13, 13.93,
	// 			18.8, 13.21,
	// 		],
	// 		pointPadding: 0.3,
	// 		pointPlacement: -0.2,
	// 	},
	// 	{
	// 		name: 'Success',
	// 		color: 'rgba(126,86,134,.9)',
	// 		data: [
	// 			10.0, 9.93, 9.97, 10.01, 10.23, 10.26, 10.0, 9.12, 9.36, 8.72, 8.38,
	// 			8.69,
	// 		],
	// 		pointPadding: 0.4,
	// 		pointPlacement: -0.2,
	// 	},
	// 	{
	// 		name: 'Failed',
	// 		color: 'rgba(248,161,63,1)',
	// 		data: [
	// 			4.35, 4.32, 4.34, 4.39, 4.46, 4.52, 4.58, 4.55, 4.53, 4.51, 4.49,
	// 			4.57,
	// 		],
	// 		pointPadding: 0.3,
	// 		pointPlacement: 0.2,
	// 		yAxis: 1,
	// 	},
	// 	{
	// 		name: 'Pending',
	// 		color: 'rgba(186,60,61,.9)',
	// 		data: [
	// 			203.6, 198.8, 208.5, 203.6, 198.8, 208.5, 203.6, 198.8, 208.5, 203.6,
	// 			198.8, 208.5,
	// 		],
	// 		pointPadding: 0.4,
	// 		pointPlacement: 0.2,
	// 		yAxis: 1,
	// 	},
	// ],

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = (pacingFilter.length && !(responseLength === pacingFilter.length)) ? pacingFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = pacingFilter.length ? pacingFilter.map((item: any) => item.id).join(',') : '0';
		retrieveGraphData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retrieveGraphData(filterIds), WIDGET_REFRESH_INTERVAL.PACING);
	}, [pacingFilter]);

	return (
		<>
			<Card ref={downloadContent} className="customCard">
				<div className="card-header">
					<div className="card-title">
						Pacing<span className="card-sub-header">(Predictive)</span>
						<span className="card-title-badge">Live</span>
					</div>
					<div className="card-actions">
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'Pacing'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={pacingFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				<Box sx={{ pt: 2, pl: 2 }}>
					{pacingFilter.length > 0 && (
						<div className="filter-outer">
							<div className="filter-container">
								<Stack direction={'row'} spacing={1}>
									{filterItems}
								</Stack>
							</div>
							<div className="filter-remove" onClick={clearFilter}>
								Remove
							</div>
						</div>
					)}
				</Box>
				<HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
			</Card>
			<FilterDrawer
				isOpen={isDrawerOpen}
				toggleDrawer={toggleDrawer}
				applyFilters={handleApplyFilters}
				existingSelectedFilters={pacingFilter}
				allowedFilters={['Campaign']}
				additionalApiParams={{ campaignType: 'predictive' }}
				setResponseLength={setresponseLength}
				existingFilterCategory={filterType} 
				applyFilterOnExistingData={handleFilterExistingData}
			/>
		</>
	);
}

export default Pacing;
