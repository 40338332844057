import React, { useMemo, useState, useEffect } from 'react';
import { Button, Stack, Typography, Grid, Tooltip, IconButton, MenuItem, Menu, } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import AddSubUser from "./AddSubUserForm";
import { getAdminSettingsList, getsubadmin, getsubadminById, updatesubadminById } from "../../../services/api-service";
import './SubUsers.css';
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import SimpleSwitch from '../../../components/admin/common/OZSwitch/SimpleSwitch';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../../components/admin/common/NotiService';
import LockedAndStatusConfirmationDialog from '../../../components/admin/common/OZLockedAndStatusDialog';
import { AdminPages, authPage, NewAdminPages, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import { ReactComponent as Edit } from '../../../static/icons/edit.svg';

function SubUsers() {

  const GetUserNameRenderer = (props) => {
    return (
      <Tooltip title={(props?.data?.username) ? props?.data?.username : ''}>
        <span style={{ color: "#3D8BF8", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: 'pointer' }}>{props.value}</span>
      </Tooltip>
    );
  };

  const TextRenderer = (props) => {
    return (
      <div>
        <label style={{ color: props.data.enabled ? "#000000" : "#99A0A8" }}>{props.value}</label>
      </div>
    );
  };

  const DateRenderer = (props) => {
    const date = new Date(props.data.createdDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return (
      <div>
        <label style={{ color: props.data.enabled ? "#000000" : "#99A0A8" }}>{date.toLocaleString("en-GB", options)}</label>
      </div>
    );
  };

  const ProfileRenderer = (props) => {
    return (
      <div>
        <label style={{ color: props.data.enabled ? "#000000" : "#99A0A8" }}>{props?.value?.name || props?.value}</label>
      </div>
    );
  };

  const LockedRenderer = (props) => {

    const [lockedState, setLockedState] = useState(props.value);
    const [lockedconfirmationDialogState, setLockedConfirmationDialogState] = useState(false);

    const handlelockedState = (e) => {
      setLockedState(e.target.checked);
      setLockedConfirmationDialogState(true);
    };

    const onCloseLocked = () => {
      setLockedConfirmationDialogState(false);
      setLockedState(props.value);
    };

    return (
      <div>
        {lockedconfirmationDialogState &&
          <LockedAndStatusConfirmationDialog title={"Locked State Confirmation"} content={"Are you Sure you want to change the Locked State?"}
            open={lockedconfirmationDialogState} updateFunction={() => { updateUser(lockedState, !props.data.enabled, props.data.id); }} onClose={() => { onCloseLocked() }} />
        }
        {props.data.enabled === true &&
          <div style={{ display: "flex", alignContent: "center" }}>
            <SimpleSwitch name={props.value} checked={lockedState}
              onChange={(e) => authPage(AdminPages.USERS_EDIT) && handlelockedState(e)}
            />
          </div>
        }
      </div>
    );
  };

  const EditRenderer = (props) => {
    const showEditForm = () => {
      if (props.data.enabled) {
        onClickRow(props);
        setSubUserForm(true);
        setMode(ADMIN_FORM_MODES.edit);
      } else {
        showErrorNotification(`${props?.data?.username} is Deactivated, Kindly Activate the User first`);
      }
    };
    return (
      <div className='oz-user-Status-container'>
        <div>
          <label style={{ color: props.data.enabled ? "#000000" : "#99A0A8" }}>{props?.value ? 'Active' : 'Deactivated'}</label>
        </div>
        {/* <IconButton className="oz-admin-holidays-edit-icon" onClick={showEditForm}>
          <Edit />
        </IconButton> */}
        {(newAuthPages(NewAdminPages.SUB_USERS) || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'Full')) && (
          <IconButton className="oz-admin-holidays-edit-icon" onClick={showEditForm}>
          <Edit />
        </IconButton>
        )}
      </div>
    );
  };

  const StatusRenderer = (props) => {

    const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
    const anchorRef = React.useRef();
    const [statusState, setStatusState] = useState(props.value);
    const [statusconfirmationDialogState, setStatusConfirmationDialogState] = useState(false);

    const onMorevertIconClick = () => {
      setTimeout(() => setStatusAnchorEl(anchorRef?.current), 1);
    };

    const onMenuClose = () => {
      setStatusAnchorEl(null);
    };

    const onCloseStatus = () => {
      setStatusConfirmationDialogState(false);
      setStatusState(props.value);
    };

    const onClickActivateHandleStatus = () => {

      console.log("hideButoon::::::::: i came in deactivate")
      if (hideAddButton()) {
        setStatusConfirmationDialogState(true);
        setStatusAnchorEl(null);
      }
      else {
        showErrorNotification
          ("User cannot be activated. limit exceeded")
      }

    };

    const onClickDeactivateActivateHandleStatus = () => {
      setStatusConfirmationDialogState(true);
      setStatusAnchorEl(null);

    };

    const styleListItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          height: '16px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "4px",
        },
      }),
      []
    );

    return (
      <div className='oz-user-Status-container'>
        <label style={{ color: props.data.enabled ? "#000000" : "#99A0A8" }}>{props?.value ? 'Active' : 'Deactivated'}</label>
        {(newAuthPages(NewAdminPages.SUB_USERS) || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'Full')) && localStorage.getItem('parentUser') === 'null' &&
          <img className="userMorevertIcon"
            onClick={onMorevertIconClick}
            src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
          >
          </img>
        }
        <div ref={anchorRef}>
          <Menu anchorEl={statusAnchorEl} open={Boolean(statusAnchorEl)} onClose={onMenuClose} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "133px",
              height: "35px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-0.3625rem",
              marginTop: "0rem",
            },
          }}>
            <MenuItem onClick={props.data.enabled ? onClickDeactivateActivateHandleStatus : onClickActivateHandleStatus} sx={{ ...styleListItemButton, marginTop: "1px" }}>{!props.data.enabled ? 'Activate' : 'Deactivate'}</MenuItem>
          </Menu>
          {statusconfirmationDialogState &&
            <LockedAndStatusConfirmationDialog title={"Status Confirmation"} content={"Are you Sure you want to change the Status?"}
              open={statusconfirmationDialogState} updateFunction={() => { updateUser(props.data.accountLocked, statusState, props.data.id); }} onClose={() => { onCloseStatus() }} />
          }
        </div>
      </div>
    );
  };

  const [subUserForm, setSubUserForm] = useState(false);
  const [selectedSubUser, setSelectedSubUser] = useState({});
  const [gridRef, setGridRef] = useState(null);
  const [subUserList, setSubUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState([]);
  const [mode, setMode] = useState(null);


  async function getSettingsData() {
    try {
      getAdminSettingsList().then(resp => {
        console.log(":::::::::???????????::::::::::::", resp.Data)
        setSettingsData(resp.Data);
      }).catch(e => {
        setSettingsData({})
        console.log(e)
      })
    }
    catch (e) {
      console.log(e)
    }
  }
  function hideAddButton() {
    let usersCount = "";
    if (settingsData.length > 0) {
      settingsData.map((item) => {
        if (item?.ParameterCode === "USER_CREATION_LIMIT") {
          if (item?.ParameterValue === null) {
            usersCount = item?.DefaultValue;
            return usersCount
          }
          else {
            usersCount = (item?.ParameterValue)
            return usersCount
          }
        }
      })
      if (usersCount === "-1") {
        return true;
      } else if (filteredUserList?.length < Number(usersCount)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  useEffect(() => {
    getSubUsersList();
    getSettingsData();
  }, []);

  const columnDefs = [
    // {
    //   field: 'accountLocked',
    //   headerName: 'Locked',
    //   enableRowGroup: false,
    //   maxWidth: 80,
    //   cellRenderer: LockedRenderer
    // },
    { field: 'username', headerName: 'User Name ', enableRowGroup: false, cellRenderer: GetUserNameRenderer, maxWidth: 250 },
    { field: 'email', headerName: 'Email', cellRenderer: TextRenderer, maxWidth: 370 },
    { field: 'createdDate', headerName: 'Created Date', cellRenderer: DateRenderer, maxWidth: 370 },
    { field: 'profileName', headerName: 'User Profile', cellRenderer: ProfileRenderer, maxWidth: 300 },
    { field: 'enabled', headerName: 'Status', cellRenderer: StatusRenderer},
  ];

  async function getSubUsersList() {
    setLoading(true);
    try {
      await getsubadmin()
        .then((resp) => {
          setFilteredUserList(resp.Data.filter(item => item.enabled === true));
          setSubUserList(resp.Data);
        })
        .catch((e) => {
          showErrorNotification("Error in fetching Sub Users list");
          setSubUserList([]);
        });
    } catch (e) {
      showErrorNotification("Error in fetching Sub Users list");
      setSubUserList([]);
    }
    setLoading(false);
  };

  async function updateUser(locked, status, id) {
    setLoading(true);
    try {
      let data=[];
      await getsubadminById(id)
        .then((resp) => {
          data = resp.Data[0];
          data.confirmPassword = data.password;
          data.accountLocked = locked;
          data.enabled = !status;
        })
        .catch((e) => {
          setSelectedSubUser({});
          showErrorNotification("Error updating Sub user");
        });
      updatesubadminById(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification(`Sub User ${data.username} Updated Successfully`);
            getSubUsersList();
          }
          else {
            showWarningNotification(resp.Message.allowedIp || resp.Message);
          }
        })
        .catch((e) => {
          console.log(e);
          showErrorNotification("Error updating Sub user");
        });
    }
    catch (e) {
      showErrorNotification("Error updating Sub user");
    }
    setLoading(false);
  };

  let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400 }), []);

  const showSubAdminForm = () => {
    setSubUserForm(true);
    setMode(ADMIN_FORM_MODES.add);
  };

  const closeSubAdminForm = () => {
    setSubUserForm(false);
    localStorage.removeItem('subUserForm');
  };

  async function onClickRow(rowItem) {
    if (rowItem.data.enabled === false) {
      showErrorNotification(`${rowItem?.data?.username} is Deactivated, Kindly Activate the User first`);
      return null;
    }
    else {
      setSelectedSubUser({});
      setLoading(true);
      try {
        await getsubadminById(rowItem.data.id)
          .then((resp) => {
            if (resp?.Status === 'Success') {
              setSelectedSubUser(resp.Data[0]);
              setSubUserForm(true);
              setMode(ADMIN_FORM_MODES.view);
            } else {
              showErrorNotification("Error getting Selected Sub User");
              setSelectedSubUser({});
            }
          })
          .catch((e) => {
            setSelectedSubUser({});
            showErrorNotification("Error getting Selected Sub User");
          });
      } catch (e) {
        showErrorNotification("Error getting Selected Sub User");
        setSelectedSubUser({});
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {!subUserForm &&
        <div className="ozAdminUsersContainer">
          <Grid sx={{ marginBottom: "10px" }}>
            <Typography className='oz-page-header'> People </Typography>
          </Grid>
          <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
            <Typography className='oz-page-heading'> Sub Users </Typography>
            {/* {localStorage.getItem('parentUser') === 'null' && authPage(AdminPages.USERS_EDIT) && hideAddButton() &&
              <Button
                sx={buttonStyle}
                variant="contained"
                startIcon={<AddBoxOutlinedIcon />}
                onClick={showSubAdminForm}
              >
                Add User
              </Button>
            } */}
            {(newAuthPages(NewAdminPages.SUB_USERS) || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'Full')) && localStorage.getItem('parentUser') === 'null' && hideAddButton() &&
              <Button
                sx={buttonStyle}
                variant="contained"
                startIcon={<AddBoxOutlinedIcon />}
                onClick={showSubAdminForm}
              >
                Add User
              </Button>
            }
          </Stack>
          <OZGridToolbar title='List of Sub Users' searchPlaceholder='User Name' rowCount={subUserList.length} dataGridRef={gridRef} fileName='Users' showFilters={true} filtertProps={{ column: 'enabled', options: [{ Active: true }, { Deactivated: false }] }} />
          <OZDataGrid height="26.3rem" columns={columnDefs} data={subUserList} rowClickCallback={onClickRow} setGridRef={setGridRef} />
          {loading &&
            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
              <LoadingScreen />
            </div>
          }
        </div >
      }
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (
          <>
            {subUserForm && <AddSubUser onClose={closeSubAdminForm} rowData={selectedSubUser} updateFunction={getSubUsersList} mode={mode} editAccess={(newAuthPages(NewAdminPages.SUB_USERS) || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'Full') || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'Edit'))}/>}
          </>
        )
      }
    </div>
  )
};

export default SubUsers;