import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import { Badge, Box, Card, Chip, FormControl, Grid, MenuItem, Popover, Select, SelectChangeEvent, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef, useState } from 'react';
import { comparativeCallTrends, getComparativeCallTrendsDrawerDeets } from '../../../services/api-service';
import { getLastFetchedDetail } from '../../../services/application-service';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import BreakDownDrawer from '../../BreakdownDrawer/BreakdownDrawer';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';

const useStyles: any = makeStyles((theme: Theme) => ({
	typeButton: {
		padding: '5px !important',
		minWidth: 50
	},
	filterItem: {
		fontSize: 12,
		height: 24,
		borderRadius: 20,
		background: 'rgba(0, 142, 255, 0.1)',
		border: '1px solid #008eff',
		padding: `0 ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center'
	},
	itemIndicator: {
		width: 5,
		height: 5,
		borderRadius: 1,
		display: 'inline-block',
		position: 'relative',
		top: '-2px',
		marginRight: 4
	},
	filterItemText: {
		margin: 0,
		color: '#536580'
	},
	filterItemSubText: {
		color: '#99A0A8'
	},
	popoverContent: {
		minWidth: 350,
		fontSize: '12px',
		lineHeight: 2
	}
}));

let interval: any;

function ComparativeCallTrends(props: any) {
	const classes = useStyles();
	const [callType, setCallType] = useState<string>('all');
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [selectedPoint, setSelectedPoint] = useState<any>({});
	const [graphRefPoint, setGraphRefPoint] = useState<any>(null);
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
	const [hourlyTrendsFilter, setHourlyTrendsFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '1');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [graphData, setGraphData] = useState<any>([]);
	const [isBreakdownDrawerOpen, setIsBreakdownDrawerOpen] = useState<boolean | undefined>(false);
	const [breakdownDrawerValues, setBreakdownDrawerValues] = useState<any>({});
	const [categoriesData, setCategoriesData] = useState<any>([]);
	const [hour, setHour] = useState('');
	const [dateTypeId, setDateTypeId] = useState(0);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');

	const modeList = [
		{ name: 'All', value: 'all' },
		{ name: 'Voice', value: 'voice' },
		{ name: 'Manual', value: 'manual' },
		{ name: 'Preview', value: 'preview' },
		{ name: 'Progressive', value: 'progressive' },
		{ name: 'Predictive', value: 'predictive' }
	];

	const graphPointData = [
		{ label: 'Answered Calls', key: 'AnsweredCalls' },
		{ label: 'Unanswered Calls', key: 'UnansweredCalls' },
		{ label: 'Agents Available', key: 'AgentsAvailable' }
	];

	const downloadContent = useRef<null | any>(null);
	const chartElement: any = useRef(null);

	const toggleBreakdownDrawer = (isOpen: boolean, data: any = {}) => {
		setIsBreakdownDrawerOpen(isOpen);
	};

	const retrieveGraphData = async (filters = '0', type = '1', callType = 'all') => {
		const currentDayResponse: any = await comparativeCallTrends({
			filters,
			type,
			callType
		});
		const yesterdayResponse: any = await comparativeCallTrends({
			filters,
			type,
			callType,
			dateTypeId: 1
		});
		const hoursDone: any = {};
		const hourArray = currentDayResponse.length > yesterdayResponse.length ? currentDayResponse : yesterdayResponse;
		const currentDayData: any[] = [];
		const yesterdayData: any[] = [];
		const lastWeekData: any[] = [];
		hourArray.forEach((item: any) => {
			if (!hoursDone[item.CallHour]) {
				hoursDone[item.CallHour] = true;
				const currentHourData = currentDayResponse.find((curr: any) => curr.CallHour === item.CallHour);
				if (currentHourData) {
					currentDayData.push({
						y: +currentHourData.TotalCalls,
						...currentHourData
					});
				} else {
					currentDayData.push({
						y: null
					});
				}
			}
			if (item.DateType === 'YesterDay') {
				yesterdayData.push({
					y: +item.TotalCalls,
					...item
				});
			}
			if (item.DateType === '7DaysAvg') {
				lastWeekData.push({
					y: +item.TotalCalls,
					...item
				});
			}
		});

		const categories = Array.from(new Set(hourArray.map((item: any) => `${item?.CallHour}:00`)));

		function fillMissingHours(data: any) { //this function new added for mking full data graph
			const filledData = [];
			let currentHour = 0;
		
			for (const item of data) {
				const callHour = parseInt(item.CallHour);
		
				while (currentHour < callHour) {
					filledData.push({ y: null });
					currentHour++;
				}
		
				filledData.push(item);
				currentHour++;
			}
		
			while (currentHour < 24) {
				filledData.push({ y: null });
				currentHour++;
			}
		
			return filledData;
		}

		let transformedDataYesrterday = fillMissingHours(yesterdayData);
		let transformedData7Day = fillMissingHours(lastWeekData);
		let transformedDataCurrent = fillMissingHours(currentDayData);

		const callTrendsData = [
			{
				name: '7 Day Average',
				color: '#E3ECFD',
				// data: lastWeekData
				data: transformedData7Day //this new added for mking full data graph
			},
			{
				name: 'Yesterday',
				color: '#E7AF5B',
				// data: yesterdayData
				data: transformedDataYesrterday //this new added for mking full data graph
			},
			{
				name: 'Today',
				color: '#008DFF',
				// data: currentDayData
				data: transformedDataCurrent //this new added for mking full data graph
			}
		];

		console.log("here value is ", categories, callTrendsData);
		setCategoriesData(categories);
		setGraphData(callTrendsData);
		setLastFetchedTime(getLastFetchedDetail());
		chartElement?.current?.chart?.redraw();
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const toggleRefresh = () => {
		const filterIds = hourlyTrendsFilter.length ? hourlyTrendsFilter.map((item: any) => item.id).join(',') : '0';
		retrieveGraphData(filterIds, filterType, callType);
	};

	const clearFilter = () => {
		setHourlyTrendsFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = hourlyTrendsFilter.filter((item: any) => item.id !== id);
		setHourlyTrendsFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const filterItems = hourlyTrendsFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const retrieveDrawerData = async (point: any, filters = '0', type = '1', callType = 'all') => {
		const dateTypeId = point.DateType === 'YesterDay' ? 1 : 0;
		setDateTypeId(dateTypeId);
		const apiResponse: any = await getComparativeCallTrendsDrawerDeets({
			filters,
			type,
			callType,
			hour: point.CallHour,
			dateTypeId
		});
		setBreakdownDrawerValues(apiResponse[0]);
		setHour(point.CallHour);
	};

	useEffect(() => {
		const resizeListener = () => {
			chartElement?.current?.chart?.reflow();
		};
		window.addEventListener('widgetResize', resizeListener);
		window.addEventListener('resize', resizeListener);

		return () => {
			clearInterval(interval);
			window.removeEventListener('widgetResize', resizeListener);
		};
	}, []);

	useEffect(() => {
		const filterIds = (hourlyTrendsFilter.length && !(responseLength === hourlyTrendsFilter.length)) ? hourlyTrendsFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = hourlyTrendsFilter.length ? hourlyTrendsFilter.map((item: any) => item.id).join(',') : '0';
		retrieveGraphData(filterIds, filterType, callType);
		if (interval) {
			clearInterval(interval);
		}

		// interval = setInterval(() => retrieveGraphData(filterIds, filterType, callType), WIDGET_REFRESH_INTERVAL.COMPARATIVE_CALL_TRENDS);
	}, [callType, filterType, hourlyTrendsFilter]);

	const handleFilterExistingData = (filteredData: any) => {
		setHourlyTrendsFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setHourlyTrendsFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const handleModeChange = (event: SelectChangeEvent<string>) => {
		setCallType(event?.target?.value);
	};

	const handleOnPopoverClose = () => {
		setGraphRefPoint(null);
		setSelectedPoint({});
		setIsPopoverOpen(false);
	};

	const handlePointClick = (point: any) => {
		setSelectedPoint(point);
		setGraphRefPoint(point?.graphic?.element);
		setIsPopoverOpen(true);
	};

	const options = {
		chart: {
			type: 'column',
			height: 350,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: 'Time ➡',
				style: { fontWeight: 'bold' }
			},
			// categories: categoriesData,
			categories: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00',
				'09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00',
				'18:00','19:00','20:00','21:00','22:00','23:00'
			],
			crosshair: true
		},
		yAxis: {
			title: {
				text: 'Interaction Volume',
				style: { fontWeight: 'bold' }
			},
			gridLineDashStyle: 'dash'
		},
		legend: {
			layout: 'horizontal',
			align: 'right',
			verticalAlign: 'top',
			itemMarginTop: 10
		},
		tooltip: {
			// enabled: false
		},
		plotOptions: {
			series: {
				cursor: 'pointer',
				borderRadiusTopLeft: '50%',
				borderRadiusTopRight: '50%',
				pointWidth: 10,
				borderRadius: 5,
				grouping: true,
				point: {
					events: {
						// mouseOver: function ( {target} : any) {
						// 	handlePointClick(target);
						// },
						click: function ({ point }: any) {
							retrieveDrawerData(point);
							// setHour(point.CallHour);
							toggleBreakdownDrawer(true);
						}
					}
				}
				// events: {
				// 	mouseOut: function ( ) {
				// 		handleOnPopoverClose();
				// 	},
				// }
			}
		},
		series: graphData
	};

	return (
		<>
			<Card ref={downloadContent} className="customCard">
				<div className="card-header">
					<div className="card-title">
						Comparative Call Trends Hourly
						<span className="card-title-badge">Live</span>
						<div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
							<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
					</div>
					<div className="card-actions">
						<FormControl sx={{ minWidth: 120, p: 0 }}>
							<Select
								placeholder="Select mode"
								id="mode-select"
								value={callType}
								size="small"
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								onChange={handleModeChange}
								sx={{ p: 0 }}
							>
								{modeList.map((mode, index) => (
									<MenuItem key={index} value={mode.value}>
										{mode.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'Comparative Call Trends Hourly'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={hourlyTrendsFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				<Box sx={{ pt: 2, pl: 2 }}>
					{hourlyTrendsFilter.length > 0 && (
						<div className="filter-outer">
							<div className="filter-container">
								<Stack direction="row" spacing={1}>
									{filterItems}
								</Stack>
							</div>
							<div className="filter-remove" onClick={clearFilter}>
								Remove
							</div>
						</div>
					)}
				</Box>

				<HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={hourlyTrendsFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>
			<Popover
				disableRestoreFocus
				open={isPopoverOpen}
				onClose={handleOnPopoverClose}
				anchorReference="anchorEl"
				anchorEl={graphRefPoint}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				<Box sx={{ p: 2 }} className={classes.popoverContent}>
					<b>Yesterday's call volume</b>
					<div>
						{graphPointData.map((item: any, idx: number) => (
							<Grid key={idx} container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item xs={7}>
									{item.label}
								</Grid>
								<span>
									<b>{selectedPoint?.options?.[item.key] || 0}</b>
								</span>
							</Grid>
						))}
					</div>
				</Box>
			</Popover>
			{isBreakdownDrawerOpen && (
				<BreakDownDrawer
					isOpen={isBreakdownDrawerOpen}
					toggleDrawer={toggleBreakdownDrawer}
					widgetName={'COMPARATIVE_CALL_TRENDS'}
					drawerValues={breakdownDrawerValues}
					hour={hour}
					dateTypeId={dateTypeId}
				/>
			)}
		</>
	);
}

export default ComparativeCallTrends;
