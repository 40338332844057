import * as React from 'react';
import { Alert, Button, FormControlLabel, Snackbar, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OZCheckbox from '../../components/OZCheckbox/OZCheckbox';
import '../Login/Login.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { login, getSuperAdminUserList, loginForMfa, loginForMfaResend, forgotPassword } from '../../services/api-service';
import { setUserData, setUserDataWithUserList } from '../../services/auth-service';
import jwt from 'jwt-decode';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { pageAuthService } from '../../services/page-service';
import { isLoggedIn } from '../../services/auth-service';
import cookie from 'cookiejs';
import CryptoES from 'crypto-es';
import LoadingButton from '@mui/lab/LoadingButton';
import { AGENT_LOGIN_URL, LOGIN_PAGE_BACKGROUND_IMG_URL } from '../../utils/properties';
import * as _ from "lodash"
import OtpVerification from './OtpVerification';
import { Userpilot } from 'userpilot';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Login() {
	const [value, setValue] = React.useState('2');
	const [user, setUser] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [rememberMe, setRememberMe] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [loadingForGetPassword, setLoadingForGetPassword] = React.useState(false);
	const [isPathnameOzonetel, setPathname] = React.useState(false);
	const [headerLogo, setImageLink] = React.useState('');

	const [mfaRole, setMfaRole] = React.useState(false);
	const [fullUserDetails, setFullUserDetails] = React.useState("");
	const [fullToken, setFullToken] = React.useState("");
	const [snackbarMessageSuccess, setSnackbarMessageSuccess] = React.useState('');
	const [openSnackbarSuccess, setOpenSnackbarSuccess] = React.useState(false);
	const [isPageloading, setPageLoading] = React.useState(false);
	
	const crexdendoBackgroundStyle = {
		backgroundImage: `url(${process.env.PUBLIC_URL}/img/crexendo_bg.jpg)`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		height: '100vh'
	};

	const generateRandomNumber = (num: any) => {
		const randomBytes = CryptoES.lib.WordArray.random(num / 8); // Generate random bytes
		const randomHex = randomBytes.toString(CryptoES.enc.Hex); // Convert bytes to hexadecimal string
		// const randomNumber = parseInt(randomHex, 16); // Parse hexadecimal string to integer
		return randomHex;
	};

	const navigate = useNavigate();
	let location = useLocation();

	function checkPathnameOzonetel() {
		let domain = window.location.href;
		if (domain.includes("engage")) {
			setPathname(false)
		}
		else if (domain.includes("crexendocx")) { setPathname(false) }
		else if (domain.includes("mavenir")) { setPathname(false) }
		else setPathname(true);
	}

	// let isPathnameOzonetel = checkPathnameOzonetel();

	const handleOnCheckboxChange = (event: any) => {
		// console.log(event);
	};
	const isChecked = (item: any) => {
		return false;
	};
	const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUser(event.target.value);
	};
	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const encrypted = (password: any) => {
		const newKeyEncrypt = cookie.get('_set');
		const encrypted = CryptoES.AES.encrypt(password, newKeyEncrypt.toString()).toString();
		return encrypted;
	};

	const decryptPassword = (passwordFromCookie: any) => {
		const newKeyDecrypt = cookie.get('_set');
		const decrypted = CryptoES.AES.decrypt(passwordFromCookie, newKeyDecrypt.toString()).toString(CryptoES.enc.Utf8);
		return decrypted;
	};

	const newFunction = async (user: any, password: any, resp: any) => {
		try {
			var adminListData: any;
			if (user.isSupperAdmin === true) {
				adminListData = await getSuperAdminUserList({ token: resp.token, userId: user.userId });
				// setUserDataWithUserList({ UserId: user.userId, token: resp.token, name: user.sub, roles: JSON.stringify(user.role), userList: JSON.stringify(adminListData), apiKey: user.apiKey, userTZ: user.userTimeZone });
				setUserDataWithUserList({ UserId: user.userId, token: resp.token, name: user.sub, userList: JSON.stringify(adminListData), apiKey: user.apiKey, userTZ: user.userTimeZone });
			} else {
				// setUserData({ UserId: user.userId, token: resp.token, name: user.sub, roles: JSON.stringify(user.role), apiKey: user.apiKey, userTZ: user.userTimeZone });
				setUserData({ UserId: user.userId, token: resp.token, name: user.sub, apiKey: user.apiKey, userTZ: user.userTimeZone });
			}
			await pageAuthService();

			if (rememberMe) { //new key written for user
				cookie.set('session&&U$$', user?.sub, { expires: 2 });
				cookie.set('sessi@@R$$##', (rememberMe ? 'true' : 'false'), { expires: 2 });

				//here i have to encrypt the password
				cookie.set('_set', generateRandomNumber(16).toString());

				// const encryptedPassword : any = CryptoES.AES.encrypt(password, cookie.get('_set').toString()).toString();
				const encryptedPassword: any = encrypted(password);
				cookie.set('se^^(ion@@P#@@##', encryptedPassword, { expires: 2 });

			} else {
				cookie.set('sessi@@R$$##', 'false', { expires: 2 });
				cookie.remove('session&&U$$');
				cookie.remove('se^^(ion@@P#@@##');
				cookie.remove('_set');
			}

			setLoading(false);
			navigate('/preset');
		} catch (e) {
			setLoading(false);
			console.log(e);
		}
	};

	const handleForgetPassword = async (event: any) => {
		event.preventDefault();
		if (value === '2') {
			setLoadingForGetPassword(true);

			if (!user.length) {
				setLoadingForGetPassword(false);
				setSnackbarMessage('Please enter username!!');
				setOpenSnackbar(true);
				return;
			}

			try {
				await forgotPassword({ user }).then(async (resp) => {
					if (resp) {
						if (resp?.status === "error") {
							setLoadingForGetPassword(false);
							setSnackbarMessage(resp?.message);
							setOpenSnackbar(true);
							return;
						} else if (resp?.status === "success") {
							setLoadingForGetPassword(false);
							setSnackbarMessageSuccess(resp?.message);
							setOpenSnackbarSuccess(true);
							return;
						}
					}
				}).catch((e) => {
					setLoadingForGetPassword(false);
					setSnackbarMessage('Please Try again Later');
					setOpenSnackbar(true);
					return;
				})
			}
			catch (e) {
				setLoadingForGetPassword(false);
				setSnackbarMessage('Please Try again Later');
				setOpenSnackbar(true);
				return;
			}
		}
	};

	const handleLogin = async (event: any) => {
		event.preventDefault();
		if (value === '2') {
			setLoading(true);

			if (!user?.length && !password.length) {
				setLoading(false);
				setSnackbarMessage('Please enter username and password!!');
				setOpenSnackbar(true);
				return;
			}
			else {
				if (user.length && !password.length) {
					setLoading(false);
					setSnackbarMessage('Password is required!!');
					setOpenSnackbar(true);
					return;
				}
				else if (!user.length && password.length) {
					setLoading(false);
					setSnackbarMessage('Username is required!!');
					setOpenSnackbar(true);
					return;
				}
			}

			try {
				await login({ user, password }).then(async (resp) => {
					if (resp) {
						console.log("my response is --->",resp.roles);
						const user: any = jwt(resp.token);

						setFullUserDetails(user?.userId);
						setFullToken(resp?.token);
						let mfa = "";
						resp?.roles.map((item: any) => {
							if (item.name === "ROLE_MFA") {
								mfa = "true";
							}
							return null;
						})
						if (mfa === "true") {
							setLoading(false);
							setSnackbarMessageSuccess('OTP Sent Successfully, Please Check');
							setOpenSnackbarSuccess(true);
							setMfaRole(true);

						} else if (mfa === "") {
							newFunction(user, password, resp);

							// try {
							// 	var adminListData: any;
							// 	if (user.isSupperAdmin === true) {
							// 		adminListData = await getSuperAdminUserList({ token: resp.token, userId: user.userId });
							// 		setUserDataWithUserList({ UserId: user.userId, token: resp.token, name: user.sub, roles: JSON.stringify(user.role), userList: JSON.stringify(adminListData), apiKey: user.apiKey, userTZ: user.userTimeZone });
							// 	} else {
							// 		setUserData({ UserId: user.userId, token: resp.token, name: user.sub, roles: JSON.stringify(user.role), apiKey: user.apiKey, userTZ: user.userTimeZone });
							// 	}
							// 	pageAuthService();
							// 	setLoading(false);
							// 	navigate('/preset');
							// } catch (e) {
							// 	setLoading(false);
							// 	console.log(e);
							// }
						}
					}
				}).catch((e) => {
					setLoading(false);
					setSnackbarMessage('Invalid Username/password!');
					console.log(e);
					setOpenSnackbar(true);
					return;
				})
			}
			catch (e) {
				setLoading(false);
				setSnackbarMessage('Invalid Username/password!');
				console.log(e);
				setOpenSnackbar(true);
				return;
			}

			// if (rememberMe) {
			// 	cookie.set('session&&U$$', user, { expires: 2 });
			// 	cookie.set('sessi@@R$$##', (rememberMe ? 'true' : 'false'), { expires: 2 });

			// 	//here i have to encrypt the password
			// 	cookie.set('_set', generateRandomNumber(16).toString());

			// 	// const encryptedPassword : any = CryptoES.AES.encrypt(password, cookie.get('_set').toString()).toString();
			// 	const encryptedPassword: any = encrypted(password);
			// 	cookie.set('se^^(ion@@P#@@##', encryptedPassword, { expires: 2 });

			// } else {
			// 	cookie.set('sessi@@R$$##', 'false', { expires: 2 });
			// 	cookie.remove('session&&U$$');
			// 	cookie.remove('se^^(ion@@P#@@##');
			// 	cookie.remove('_set');
			// }
		}
	};

	const handleVerifyForOtp = async (event: any) => {
		if (value === '2') {
			setLoading(true);
			try {
				await loginForMfa({ event, user, password, fullToken, fullUserDetails }).then(async (resp) => {
					// console.log("resp here ", resp);
					if (resp !== "Unauthorized") {
						const userNew: any = jwt(resp.token);
						newFunction(userNew, password, resp);
						// try {
						// 	var adminListData: any;
						// 	if (userNew.isSupperAdmin === true) {
						// 		adminListData = await getSuperAdminUserList({ token: resp.token, userId: userNew.userId });
						// 		setUserDataWithUserList({ UserId: userNew.userId, token: resp.token, name: userNew.sub, roles: JSON.stringify(userNew.role), userList: JSON.stringify(adminListData), apiKey: userNew.apiKey, userTZ: userNew.userTimeZone });
						// 	} else {
						// 		setUserData({ UserId: userNew.userId, token: resp.token, name: userNew.sub, roles: JSON.stringify(userNew.role), apiKey: userNew.apiKey, userTZ: userNew.userTimeZone });
						// 	}
						// 	pageAuthService();

						// 	if (rememberMe) { //new key written for user
						// 		console.log("user here ", userNew?.sub, "password also here ", password);
						// 		cookie.set('session&&U$$', userNew?.sub, { expires: 2 });
						// 		cookie.set('sessi@@R$$##', (rememberMe ? 'true' : 'false'), { expires: 2 });

						// 		//here i have to encrypt the password
						// 		cookie.set('_set', generateRandomNumber(16).toString());

						// 		// const encryptedPassword : any = CryptoES.AES.encrypt(password, cookie.get('_set').toString()).toString();
						// 		const encryptedPassword: any = encrypted(password);
						// 		cookie.set('se^^(ion@@P#@@##', encryptedPassword, { expires: 2 });

						// 	} else {
						// 		cookie.set('sessi@@R$$##', 'false', { expires: 2 });
						// 		cookie.remove('session&&U$$');
						// 		cookie.remove('se^^(ion@@P#@@##');
						// 		cookie.remove('_set');
						// 	}

						// 	setLoading(false);
						// 	navigate('/preset');
						// } catch (e) {
						// 	setLoading(false);
						// 	console.log(e);
						// }

					} else {
						setSnackbarMessage('Invalid OTP!, Please enter correct OTP and try again');
						setOpenSnackbar(true);
						setLoading(false);
					}
				}).catch((e) => {
					setSnackbarMessage('Invalid OTP!, Please enter correct OTP and try again');
					setOpenSnackbar(true);
					setLoading(false);
					return;
				})
			}
			catch (e) {
				setSnackbarMessage('Invalid OTP!, Please enter correct OTP and try again');
				setOpenSnackbar(true);
				setLoading(false);
				return;
			}
		}
	};

	const handleResendForOtp = async () => {
		setLoading(true);
		try {
			await loginForMfaResend({ user, password, fullToken, fullUserDetails }).then(async (resp) => {
				if (resp !== 'Unauthorized') {
					setFullToken(resp.token);
					setSnackbarMessageSuccess('OTP Re-Sent Successfully');
					setOpenSnackbarSuccess(true);
					setLoading(false);
				} else {
					setLoading(false);
					setSnackbarMessage('Error in sending OTP!');
					setOpenSnackbar(true);
				}
			}).catch((e) => {
				setSnackbarMessage('Invalid Username/password!');
				setLoading(false);
				setOpenSnackbar(true);
				return;
			})
		}
		catch (e) {
			setSnackbarMessage('Invalid Username/password!');
			setLoading(false);
			setOpenSnackbar(true);
			return;
		}
	};

	const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
		setValue(newValue);
		if (newValue === '1') {
			try {
				let currentURl = window.location.href;

				// if (currentURl && !currentURl.includes('cloudagent') && !currentURl.includes('ozonetel')) {
				if (currentURl && !currentURl.includes('ozonetel')) {
					let urlParts = currentURl.split('/')
					if (urlParts[2].includes('cxhub')) {
						urlParts[2] = urlParts[2].replace("cxhub", 'cxagent')
					} else if (urlParts[2].includes('cxagent')) {
						// urlParts[2] = urlParts[2].replace("cxagent", 'cxagent')
					} else {
						urlParts[2] = 'cxagent.' + urlParts[2] + '/login';
					}

					let agentURL = urlParts.join("/");
					window.location.href = agentURL;
				} else {
					window.location.href = AGENT_LOGIN_URL;
				}
			} catch (e) {
				console.error("error while switching tabs", e);
			}
		}
	};

	const handleCloseSuccess = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbarSuccess(false);
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};

	const closeAdminEditForm = () => {
		setMfaRole(false);
	}

	React.useEffect(() => {
		const AdminUserName: any = cookie.get('session&&U$$');
		const AdminRememberMe: any = cookie.get('sessi@@R$$##');

		if (AdminRememberMe === true || AdminRememberMe === 'true') {
			// setUser(AdminUserName);
			// setRememberMe(true);

			const AdminPassword: any = cookie.get('se^^(ion@@P#@@##');

			//here i have to decrypt the password got from cookies
			const decryptedPassword = decryptPassword(AdminPassword);
			setUser(AdminUserName);
			setRememberMe(true);
			setPassword(decryptedPassword);
		}
		checkPathnameOzonetel()
	}, []);

	function handleSetPageTitle() {
		let url = window.location.href;
		// let url = "https://cxhub.engage.personacloud.net"
		// let url = "https://admin.engage.personacloud.net";
		const titleElement = document.getElementById('title-ozonetel');
		if (url.includes('cxhub')) {
			if (titleElement) {
				titleElement.innerText = 'CxHub';
			}
		} else if (url.includes('admin')) {
			if (titleElement) {
				titleElement.innerText = 'Admin';
			}
		}
	}

	React.useEffect(() => {
		if (isLoggedIn()) {
			let userID: any = localStorage.getItem('userId') ? localStorage.getItem('userId') : '00000';
			Userpilot.initialize(userID);
			Userpilot.identify(userID,
				{
					userid: localStorage.getItem("userId"),
					username: localStorage.getItem("userName"),

				}
			);
			Userpilot.track('User LoggedIn', { name: localStorage.getItem("userName") });
		}
		AdminHeaderLogo()
	}, [])
	function AdminHeaderLogo() {
		let domainNew = window.location.href;
		if (!domainNew.includes("ozonetel")) {
			setPageLoading(true)
			// handleSetPageTitle()
			let endpoint = getEndPoint(window.location.href)

			// let endpoint = getEndPoint("https://cxhub.engage.personacloud.net")
			// let endpoint = getEndPoint("https://admin.engage.personacloud.net")
			// let endpoint = getEndPoint("https://portal.ccicx.ccicom.com/login")
			// let endpoint = getEndPoint("https://cxhub.cirruscx.com/login")
			getHeaderImages(endpoint)

		}
		// return (
		// 	// <img className={(imageLink === "https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_engage.personacloud.net.png?v=1666335600000" || imageLink === `${process.env.PUBLIC_URL}/img/logo-1x.svg` ) ? "admin-header-logo" : ""} src={imageLink} alt="Ozonetel" ></img >
		// 	<img className={(domainNew.includes("mavenir") || domainNew.includes("engage")) ? "admin-header-logo common-prop-header" : "common-prop-header"} src={!_.isEmpty(headerLogo) ? headerLogo : `${process.env.PUBLIC_URL}/icons/ozonetellogowhite.svg`} alt="Ozonetel" ></img >
		// )
	}
	const getEndPoint = (url: any) => {
		const domain = new URL(url).hostname;
		const domainParts = domain.split(".");
		// const dynamicPart = domainParts.slice(1).join(".");
		if (domainParts.length > 1) {
			return domainParts.slice(1).join(".")
		}
		else if (domainParts.length === 1) {
			return domainParts
			// return domainParts
		} else {
			return null; // or any default value you want to use for URLs with a single path
		}


	}
	async function getHeaderImages(endpoint: any) {
		fetch(LOGIN_PAGE_BACKGROUND_IMG_URL + endpoint + ".js", {})
			.then(res => res.text())
			.then((result) => {
				if (result) {
					let res = String(result)
					const urlRegex = /(https:\/\/\S+)/g;
					const urls = res.match(urlRegex);
					if (urls) {
						urls.map((item: any) => {
							if (item.includes('logo_')) {
								localStorage.setItem('headerLogo', item.slice(0, -1));
								setImageLink(item.slice(0, -1))
								setPathname(false);
							}
							if (item.includes('fav_')) {
								const link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
								if (link) {
									localStorage.setItem('favIcon', item.slice(0, -1));
									link.href = item.slice(0, -1); // Replace 'new-favicon.ico' with the desired favicon image path
								}
							}
						})
					}
				}
				// console.log("final login page logo")
				// console.log(headerLogo)
			})
			.catch(error => {
				console.log("error in api ")
			}).finally(() => {
				setPageLoading(false);
			});

		// try enabling below code, if api fails only for testing purpose
		// console.log('faild to read header img')
		// const code1 = `document.querySelectorAll("img[class~='logo-img']").forEach(function(k,v){k.src='https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_engage.personacloud.net.png?v=1666335600000';});document.querySelectorAll("link[rel~='icon']").forEach(function(k,v){k.href='https://s3.ap-south-1.amazonaws.com/ca.cdn/fav_engage.personacloud.net.png?v=1666335600000';});var style = document.createElement('style');style.innerHTML = ':root { --primary: #960000 !important; --secondary: #005192 !important;--logo: url(https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_engage.personacloud.net.png?v=1666335600000)!important;--fav: url(https://s3.ap-south-1.amazonaws.com/ca.cdn/fav_engage.personacloud.net.png?v=1666335600000)!important;} .am-nosidebar-left .am-top-header .navbar-header .navbar-brand, div#header { background-image: url(https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_engage.personacloud.net.png?v=1666335600000)!important; }';document.getElementsByTagName('HEAD')[0].appendChild(style);`;
		// const code2 = `document.querySelectorAll("img[class~='logo-img']").forEach(function(k,v) {k.src='https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_portal.pressonecx.com.png?v=1684220400000';});document.querySelectorAll("link[rel~='icon']").forEach(function(k,v) {k.href='https://s3.ap-south-1.amazonaws.com/ca.cdn/fav_portal.pressonecx.com.png?v=1684220400000';});var style = document.createElement('style'); style.innerHTML = ':root { --primary: #faa633 !important; --secondary: #45166a !important;--logo: url(https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_portal.pressonecx.com.png?v=1684220400000) !important;--fav: url(https://s3.ap-south-1.amazonaws.com/ca.cdn/fav_portal.pressonecx.com.png?v=1684220400000) !important;} .am-nosidebar-left .am-top-header .navbar-header .navbar-brand, div#header { background-image: url(https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_portal.pressonecx.com.png?v=1684220400000) !important; }'; document.getElementsByTagName('HEAD')[0].appendChild(style);`
		// const code3 = `document.querySelectorAll("img[class~='logo-img']").forEach(function(k,v) {k.src='https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_crexendocx.com.png?v=1655276400000';});document.querySelectorAll("link[rel~='icon']").forEach(function(k,v) {k.href='https://s3.ap-south-1.amazonaws.com/ca.cdn/fav_crexendocx.com.png?v=1655276400000';});var style = document.createElement('style'); style.innerHTML = ':root { --primary: #298fc2 !important; --secondary: #5b6770 !important;--logo: url(https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_crexendocx.com.png?v=1655276400000) !important;--fav: url(https://s3.ap-south-1.amazonaws.com/ca.cdn/fav_crexendocx.com.png?v=1655276400000) !important;} .am-nosidebar-left .am-top-header .navbar-header .navbar-brand, div#header { background-image: url(https://s3.ap-south-1.amazonaws.com/ca.cdn/logo_crexendocx.com.png?v=1655276400000) !important; }'; document.getElementsByTagName('HEAD')[0].appendChild(style);`
		// const urlRegex = /(https:\/\/\S+)/g;
		// const urls = code3.match(urlRegex);
		// console.log("extracted urls from response")
		// console.log(urls);
		// data = urls
		// return data
		// https://s3.ap-south-1.amazonaws.com/ca.cdn/<host-name>.js

	}


	const PageLoader = () => {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		)
	}

	return (
		isPageloading ? <PageLoader /> :
			<>
				{isPathnameOzonetel && <img className='green-circle-top-left' src={`${process.env.PUBLIC_URL}/icons/greencircle.svg`} alt="Ozonetel" ></img >}
				{!isPathnameOzonetel && !window.location.href.includes("crexendovip") && <img className='vector-backgroung-img-left' src={`${process.env.PUBLIC_URL}/icons/loginpagevectorsLeft.svg`} alt="Ozonetel" ></img >}
				{!isPathnameOzonetel && !window.location.href.includes("crexendovip") && <img className='vector-backgroung-img-right' src={`${process.env.PUBLIC_URL}/icons/loginpagevectorRight.svg`} alt="Ozonetel" ></img >}
				<div className={isPathnameOzonetel ? "login_container login-container-blue" : "login_container"} style={window.location.href.includes("crexendovip")? crexdendoBackgroundStyle:undefined}>
						<div className="login-left">
							{window.location.href.includes("crexendovip") ?
								<img className="admin-header-logo common-prop-header" src={`${process.env.PUBLIC_URL}/img/crexendo_logo.png`} alt="Crexendo" ></img >
								:
								<img className={window.location.href.includes("ccicx.ccicom") ? "admin-header-logo cci-logo" : "admin-header-logo common-prop-header"} src={!_.isEmpty(headerLogo) ? headerLogo : `${process.env.PUBLIC_URL}/icons/ozonetellogowhite.svg`} alt="Ozonetel" ></img >
							}
							{!window.location.href.includes("crexendovip") &&(
								<div>
									<div className={isPathnameOzonetel ? "login-title login-title-white" : "login-title"}>Modernize your Contact Center Software today!</div>
									<div className={isPathnameOzonetel ? "login-sub-title login-sub-title-white" : "login-sub-title"}>Enterprise-grade contact center suite built for fast-growing businesses</div>
								</div>
							)}
						</div>
					<div className="login-right">
						<div className="login-form-title">Login</div>
						<TabContext value={value}>
							<TabList onChange={handleChange} aria-label="lab API tabs example" className="customLoginTab">
								{/* <Tab label="Agent" value="1" disabled={window.location.hostname.includes('localhost') || window.location.hostname.includes('cloudagent.ozonetel.com') || window.location.hostname.includes('preprod-cx.ozonetel.com') ? false : true} /> */}
								<Tab label="Agent" value="1" />
								<Tab label="Admin" value="2" />
							</TabList>
							<TabPanel value="1" className="loginTabContainer">
								<div className="ozonetel-form-control">
									<label className="form-label">Account ID</label>
									<input type="text" placeholder="Enter Account ID" />
								</div>
								<div className="ozonetel-form-control">
									<label className="form-label">Agent ID</label>
									{/* <input type="text" value={user} onChange={handleUserChange} placeholder="Enter Agent ID" /> */}
									<input type="text" placeholder="Enter Agent ID" />
								</div>
								<div className="ozonetel-form-control">
									<label className="form-label">Phone Number</label>
									<input type="text" placeholder="Enter Phone Number" />
								</div>
								<div className="ozonetel-form-control">
									<label className="form-label">Password</label>
									{/* <input type="password" value={password} onChange={handlePasswordChange} placeholder="Enter Password" /> */}
									<input type="password" placeholder="Enter Password" />
								</div>
								{false && <div className="ozonetel-form-control-inline">
									<OZCheckbox checked={isChecked('1')} label="Remember Me" id="1" onChange={handleOnCheckboxChange} />
									< Link to="/forgot-password" > Forgot password ?</Link >
								</div >}
								<div className="ozonetel-form-control-inline">
									<Button variant="contained" size="large" className="loginBtnLg" onClick={handleLogin}>
										Login
									</Button>

								</div>
							</TabPanel >
							<TabPanel value="2" className="loginTabContainer">
								<form onSubmit={handleLogin}>
									<div>
										<div className="ozonetel-form-control">
											<label className="form-label">User Name</label>
											<input disabled={mfaRole || loadingForGetPassword} required type="text" value={user} onChange={handleUserChange} placeholder="Enter User Name" />
										</div>
										<div className="ozonetel-form-control">
											<label className="form-label">Password</label>
											<input disabled={mfaRole || loadingForGetPassword} required type="password" value={password} onChange={handlePasswordChange} placeholder="Enter Password" />
										</div>
										{/* {false && <div className="ozonetel-form-control-inline">
										<OZCheckbox checked={isChecked('1')} label="Remember Me" id="1" onChange={handleOnCheckboxChange} />
										<Link to="/forgot-password">Forgot password?</Link>
									</div>} */}
										<div className='ozonetel-form-control-inline'>
											<div className='rememberMe'>
												<FormControlLabel
													disabled={mfaRole || loadingForGetPassword}
													checked={rememberMe}
													control={<OZCheckbox checked={rememberMe} label="" id="2" onChange={() => setRememberMe(!rememberMe)} />}
													label={<Typography variant='subtitle2' className='rememberMe-label' >Remember Me</Typography>}
													labelPlacement='end'
												// onChange={() => setRememberMe(!rememberMe)}
												/>
											</div>
											{/* <Link to={`/resetpassword`}>Forgot password?</Link> */}
											<LoadingButton disabled={mfaRole || loadingForGetPassword} size="small" onClick={handleForgetPassword} loading={loadingForGetPassword} variant="text">
												Forgot password?
											</LoadingButton>
										</div>
										<div className="ozonetel-form-control-inline">
											<LoadingButton disabled={mfaRole || loadingForGetPassword} className="loginBtnLg" type="submit" size="large" onClick={handleLogin} loading={loading} variant="contained">
												Login
											</LoadingButton>
										</div>
									</div>
									{mfaRole && (
										<OtpVerification open={mfaRole} onClose={closeAdminEditForm} onClickVerify={handleVerifyForOtp} onClickResend={handleResendForOtp} />
									)}
								</form>
							</TabPanel>
						</TabContext >
					</div >
					<Snackbar sx={{ zIndex: 10005 }} open={openSnackbarSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSuccess}>
						<Alert
							iconMapping={{
								error: <DoDisturbOnIcon style={{ color: 'green' }} />
							}}
							onClose={handleCloseSuccess}
							severity="error"
						>
							{snackbarMessageSuccess}
						</Alert>
					</Snackbar>
					<Snackbar sx={{ zIndex: 10005 }} open={openSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleClose}>
						<Alert
							iconMapping={{
								error: <DoDisturbOnIcon style={{ color: 'red' }} />
							}}
							onClose={handleClose}
							severity="error"
						>
							{snackbarMessage}
						</Alert>
					</Snackbar>
				</div >
			</>
	);
}

export default Login;
