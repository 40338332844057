// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { AppBar, Grid, Menu, MenuItem, Tab, Tabs, Theme, Toolbar, Avatar, Autocomplete, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OZContext } from '../../contexts/OZContext';
import { clearSession, getUserName } from '../../services/auth-service';
import { deleteValueInStorage } from '../../services/storage-service';
// import { getValueInStorage, setValueInStorage } from '../../services/storage-service';
// import {useMatch } from 'react-router-dom';
import { LOCAL_STORAGE } from '../../utils/constants';
import NotificationDrawer from '../NotificationDrawer/NotificationDrawer';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import jwt from 'jwt-decode';
import '../OZToolbar/OZToolbar.css';
import { stringAvatar } from "../../components/admin/common/Avatar";

const useStyles = makeStyles((theme: Theme) => ({
	menuButton: {
		marginRight: theme.spacing(2)
	},
	tabs: {
		flexGrow: 1,
		padding: '0px 8px'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1 + ' !important',
		height: 74,
		background: 'white',
		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06) !important',
		color: '#212121'
	},
	rightMenu: {
		display: 'flex'
	},
	selectComp: {
		// width: '99px',
		height: '35px',
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '16px',
		textAlign: 'center',
		color: '#212121',
		flex: 'none',
		order: '0',
		flexGrow: '0'
	}
}));

type Props = {
	selectedDashboard?: any;
	onSelectDashboard: any;
};

export default function OZToolbar({ onSelectDashboard }: Props) {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const userName = getUserName();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [value, setValue] = React.useState(0);
	const [showTabs, setShowTabs] = React.useState(false);
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
	const { selectedDashboard, dashboardTabs, setDashboardTabs } = React.useContext(OZContext);
	const inAdmin = useLocation().pathname.includes('/admin')
	// const [selectUser, setSelectUser] = React.useState(localStorage.getItem('selectedUser') ? localStorage.getItem('selectedUser')! : localStorage.getItem('userId')!);
	const user: any = jwt(localStorage.getItem('token')!);
	const [hoveredtabId, setHoveredTabId] = React.useState(null);
	const [hoverClose, setHoverClose] = React.useState(false);

	// const handleUserChange = (event: SelectChangeEvent,) => {
	// 	localStorage.setItem('userId', event.target.value);
	// 	localStorage.setItem('selectedUser', event.target.value);
	// 	setSelectUser(event.target.value);
	// 	window.location.reload();
	// };
	const storedUserId = localStorage.getItem('userId');
	const storedSelectedUser = localStorage.getItem('selectedUser');

	const [selectUser, setSelectUser] = React.useState(
		storedSelectedUser ? storedSelectedUser : (storedUserId ? Number(storedUserId) : null)
	);


	// Use the storedSelectedUser as the default label to display
	const [selectedUserName, setSelectedUserName] = React.useState(storedSelectedUser || userName);
	console.log('selectUser', selectedUserName)

	const handleUserChange = (event: React.SyntheticEvent, newValue: any) => {
		if (newValue) {
			localStorage.setItem('userId', newValue.value);
			localStorage.setItem('selectedUser', newValue.label);
			setSelectUser(newValue.value);
			setSelectedUserName(newValue.label);
			console.log('SelectedUSer', newValue.label)
			window.location.reload();
		}
	};



	// const userList = sessionStorage.getItem('userList') !== 'undefined' ? JSON.parse(sessionStorage.getItem('userList')!) : [];
	const usrLst = localStorage.getItem('userList');
	let userList: any[] = [];
	if (usrLst) {
		const parsedList = JSON.parse(usrLst);
		if (Object.keys(parsedList).length === 0) {
			userList = [];
		} else {
			userList = parsedList;
		}
	}
	const userSortedList: any[] = userList?.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		if (!hoverClose) {
			if (newValue === -1) return;
			if (newValue === 0) {
				navigate(`/preset`);
			}

			const newSelection = dashboardTabs.find((item: any) => item.id === newValue);
			onSelectDashboard(newSelection);
			setValue(newValue);
		}
	};

	const handleNotificationsDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	const toggleNotificationDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const open = Boolean(anchorEl);

	function handleMenu(event: any) {
		setAnchorEl(event.currentTarget);
	}
	const onAddNewDashboard = () => {
		setValue(0);
		navigate(`/preset`);
	};
	function handleClose() {
		setAnchorEl(null);
	}

	function logout() {
		clearSession();
		navigate(`/login`);
	}

	const updateDashboardTabs = () => {
		if (!selectedDashboard?.id) return;
		const newDashboardTabs = [...dashboardTabs];
		const existingDashboard = newDashboardTabs.find((item) => item.id === selectedDashboard?.id);
		if (!existingDashboard) {
			newDashboardTabs.push(selectedDashboard);
			setDashboardTabs(newDashboardTabs);
		}
		setValue(selectedDashboard.id);

		// const newDashboardTabs = [...dashboardTabs];
		// // eslint-disable-next-line array-callback-return
		// const newDashboardTab = dashboardTabs.map((data: any) => {
		// 	if (data.id === selectedDashboard.id) {
		// 		return "dashboard already exists"
		// 	}
		// })
		// if (!newDashboardTab.includes("dashboard already exists") && Object.keys(selectedDashboard).length) {
		// 	newDashboardTabs.push(selectedDashboard)
		// }
		// setDashboardTabs(newDashboardTabs)
		// setValue(selectedDashboard.id);
	};

	React.useEffect(() => {
		updateDashboardTabs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDashboard]);

	React.useEffect(() => {
		if (location.pathname.includes('reports')) {
			setShowTabs(false);
		} else {
			if (location.pathname.includes('preset')) {
				setValue(0);
			}
			setShowTabs(true);
		}
	}, [location]);

	const handleTabClose = (id: number) => {
		const newDashboardTabs = dashboardTabs.filter((item: any) => item.id !== id);
		setDashboardTabs(newDashboardTabs);
		if (id === selectedDashboard.id) {
			setValue(0);
			navigate('/preset');
		}
		setHoverClose(false);
	};

	function handleSetPageTitle_FavIcon() {
		let url = window.location.href;
		// let url = "https://cxhub.engage.personacloud.net"
		// let url = "https://admin.engage.personacloud.net";
		const titleElement = document.getElementById('title-ozonetel');
		if (url.includes('cxhub')) {
			if (titleElement) {
				titleElement.innerText = 'CxHub';
			}
		} else if (url.includes('admin')) {
			if (titleElement) {
				titleElement.innerText = 'Admin';
			}
		}
		//code to change fav icon
		const link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
		if (link) {
			let favIcon = localStorage.getItem('favIcon');

			if (favIcon !== null)
				link.href = favIcon
		}
	}

	function AdminHeaderLogo() {
		let domainNew = window.location.href;
		let imageLink = `${process.env.PUBLIC_URL}/img/logo-1x.svg`;
		if (!domainNew.includes("ozonetel")) {
			handleSetPageTitle_FavIcon()
			let headerLogo = localStorage.getItem('headerLogo');

			if (headerLogo !== null)
				imageLink = headerLogo;
		}
		return (
			<img src={imageLink} width="140" height='40' alt="header logo" ></img >
		)
	}

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar className={classes.appBar}>
				<div aria-label="logo" >
					<AdminHeaderLogo />
				</div>
				<div className={classes.tabs}>
					{(showTabs && !inAdmin) && (
						<div className="tab-container">
							<Tabs className="oz-tabs" value={value} onChange={handleChange}>
								<Tab
									disableRipple
									className="oz-tab"
									onClick={() => {
										deleteValueInStorage(LOCAL_STORAGE.SELECTED_DASHBOARD);
										navigate(`/preset`);
									}}
									label="Dashboard"
									value={0}
								/>
								{dashboardTabs.map((tab: any) => (
									<Tab
										key={tab.id}
										disableRipple
										onMouseEnter={() => setHoveredTabId(tab.id)}
										icon={(hoveredtabId === tab.id) ? <CloseIcon onMouseEnter={() => setHoverClose(true)} onMouseLeave={() => setHoverClose(false)} onClick={() => handleTabClose(tab.id)} /> : ''}
										onMouseLeave={() => setHoveredTabId(null)}
										iconPosition="end"
										className="oz-tab"
										label={tab?.dashboard?.dashboardName}
										value={tab.id}
									/>
								))}
								<Tab disableRipple icon={<AddIcon onClick={onAddNewDashboard} />} className="no-padding" iconPosition="start" label={''} value={-1} />
								{/* <IconButton onClick={onAddNewDashboard}>
								<AddIcon />
							</IconButton> */}
							</Tabs>
						</div>
					)}
				</div>
				<div className={classes.rightMenu}>
					<Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center" >
						{user.isSupperAdmin ?
							// <Grid item>
							// 	{(userSortedList && userSortedList.length>0) ? <Select className={classes.selectComp} sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
							// 		labelId="demo-simple-select-standard-label"
							// 		id="demo-simple-select-standard"
							// 		value={selectUser}
							// 		onChange={handleUserChange}
							// 		label="username"
							// 	>
							// 		{userSortedList?.map((data: any) => {
							// 			return <MenuItem key={data.value} value={data.value}>{data.label}</MenuItem>
							// 		})}
							// 	</Select>:<label>{localStorage.getItem('userName')}</label>}
							// </Grid> :
							// null
							<Grid item>
								{userSortedList && userSortedList.length > 0 ? (
									<Autocomplete
										options={userSortedList}
										getOptionLabel={(option) => option.label}
										value={userSortedList.find((option) => (option.label === selectUser) || (option.value === selectUser)) || null}
										onChange={(event, newValue) => handleUserChange(event, newValue)}
										renderInput={(params) => (
											<TextField
												sx={{
													height: "44px",
													width: "200px",
													input: {
														"&::placeholder": {
															fontWeight: 400,
															fontSize: '14px',
															color: '#99A0A8 !important',
															opacity: 1
														},
													},
													'& .MuiOutlinedInput-root': {
														minHeight: "44px",
														fontSize: "14px",
														fontWeight: 400,
														color: params.inputProps.value === "" ? "#99A0A8" : "#212121",
														borderRadius: '8px',
														paddingLeft: '12px',
														paddingTop: '0px',
														paddingRight: '0px',
														paddingBottom: '0px'
														,
													}
												}}

												{...params} variant="outlined" value={selectedUserName} />
										)}

									/>
								) : (
									<label>{localStorage.getItem('userName')}</label>
								)}
							</Grid> : null
						}
						{/* {(showTabs && inAdmin) && 
                            <Grid item>
                                <img
                                    style={{ marginLeft: '5px',cursor: 'pointer' }}
                                    src={`${process.env.PUBLIC_URL}/icons/questionIcon.svg`}
                                    alt="edit"
                                    // onClick={(e) => handleQuestionIcon(e, label)}
                                >
                                </img>
                            </Grid>
                        } */}
						{/* <Grid item>
                            <NotificationsOutlinedIcon onClick={handleNotificationsDrawer} style={{ cursor: 'pointer',marginLeft: '5px' }} />
                        </Grid> */}
						<Grid item>
							<Avatar className='toolBarAvatar' onClick={handleMenu} {...stringAvatar(userName)}></Avatar>
							{/* <AccountCircleOutlinedIcon onClick={handleMenu} /> */}
						</Grid>
					</Grid>
					<NotificationDrawer isOpen={isDrawerOpen} toggleNotificationDrawer={toggleNotificationDrawer}></NotificationDrawer>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						open={open}
						onClose={handleClose}
					>
						<MenuItem className="title-case" onClick={handleClose}>
							User: <i>{userName}</i>
						</MenuItem>
						{/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
						<MenuItem onClick={logout}>Logout</MenuItem>
					</Menu>
				</div >
			</Toolbar >
		</AppBar >
	);
}
