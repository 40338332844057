import React, { useState, useMemo, useEffect } from 'react';
import { Formik, connect, getIn } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Typography, Stack, Step, Stepper, StepLabel, Divider, Tooltip, TextField, FormControl, FormHelperText } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import Box from '@mui/material/Box';
import OZSelect from '../../../components/admin/common/OZSelect';
import OZFCheckbox from '../../../components/admin/common/OZFCheckbox';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import { updateCamapignOutbound, addNewCampaignOutbound, getCampaignResetDataWithMappingColumns, getAddCallWork, getSecondaryDidDropdown, getAdminSettingsList, getPluginDropdown, getHolidaysListForCampaigns } from '../../../services/api-service';
import { getSkillsDropdown, getSubUserDropdown, getBlockNumberGroupDropdown, getOnHoldMusicDropdown, getPreviewDataDropdown, getDispositionsDropdown, getIvrFlowDropdown, addNewMapping, addNewUploadFile } from '../../../services/api-service';
import OZRichText from '../../../components/admin/common/OZRichText/OZRichText';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from "@mui/lab/TabList";
import OZDialog from "../../../components/admin/common/OZDialog";
import PopupState from 'material-ui-popup-state';
import { showErrorNotification, showInfoNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import TimePicker from "rc-time-picker";
import "./Campaign.css";
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import CopyCampaignForm from './CopyCampaignForm';
import LockedAndStatusConfirmationDialog from '../../../components/admin/common/OZLockedAndStatusDialog';
import { DropzoneArea, DropzoneAreaBase } from "mui-file-dropzone";
import { checkRoles, checkSettings, fetchSettingValue } from '../../../utils/util';
import { authPage, AdminPages, newAuthPages, NewAdminPages, newAuthSubAdminAccess } from '../../../services/page-service';
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import RetryConfig from './RetryConfig';
import moment from 'moment';
import { OZCustomToggleButton } from "../../../components/admin/common/OZCustomToggleButton";
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import DispositionRetryConfig from './DispositionRetryConfig';
import Link from '@mui/material/Link'
import { APP_URL } from '../../../utils/properties';

let uploadedFiles = [];
let uploadedMappingFiles = [];

const CampaignDataTab = (props) => {
    let id = props?.props?.values?.campaignId;
    let finalColumnHeaderList = [];

    const [rowData, setRowData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [timzone, setTimezone] = useState();
    const [columnData, setColumnData] = useState([]);

    const [gridRef, setGridRef] = useState(null);
    const [pageNumber, setPageNumber] = useState("0");
    const [pageSize, setPageSize] = useState("10");
    const [filter, setFilter] = useState("all");

    let body = {
        // "status": (filter === "all") ? "" : filter ? filter.toString() : filter,
        "status": (filter === "all" || filter === "All" || filter === "") ? "" : filter.toString(),
        "pageNumber": pageNumber.toString(),
        "pageSize": pageSize.toString(),
        "dialStatus": "",
        "disposition": "",
        "skill": "",
        "agent": "",
        "priority": "",
        "fromDate": null,
        "toDate": null,
        "dateSortBy": "1",
    };
    useEffect(() => {
        getCampaignRegenerateData(body, id, props?.props);
    }, [pageSize, pageNumber, filter]);

    const DateFormatter = (params) => {
        if(params?.value){
        const date = new Date(params.value);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return date.toLocaleString("en-GB", options);
        }else{
            return null;
        }
    }

    async function getDynamicColumn(props,timezone) {
        let columnList = [];
        let newColumnHeaderOptionsList = [];
        let columnHeaderOptions = props.values.previewDataMap.value;
        newColumnHeaderOptionsList = columnHeaderOptions.split(",");
        newColumnHeaderOptionsList.map((headerValue) => {
            columnList.push({
                field: headerValue, headerName: headerValue, cellRenderer: (params) => {
                    return (
                        <Tooltip title={params.value ? params?.value : ''} placement='bottom-start'>
                            <span>{params.value ? params?.value : ''}</span>
                        </Tooltip>
                    )
                }
            });
            return null;
        })
        
        if (timezone ==="true") {
            columnList.push({ field: 'Timezone', headerName: 'Timezone'});
        }
        
        columnList.push({
            field: 'DateAdded', headerName: 'DateAdded', cellRenderer: DateFormatter,
        });
        columnList.push({
            field: 'DateUpdated', headerName: 'DateUpdated', cellRenderer: DateFormatter,
        });
        columnList.push({ field: 'Tried', headerName: 'Tried' });
        if (props.values.retryRules === true) {
            columnList.push({ field: 'DialCounter', headerName: 'DialCounter' });
            columnList.push({ field: 'System Expiry', headerName: 'System Expiry', cellRenderer: DateFormatter});
        }
        columnList.push({ field: 'Status', headerName: 'Status' });
        finalColumnHeaderList = columnList;
    }

    const getPageNumber = (value) => {
        setPageNumber(value);
    }
    const getPageSize = (value) => {
        setPageSize(value);
    }

    const gotFilterValue = (value) => {
        setFilter(value);
    }

    async function getCampaignRegenerateData(body, id, props) {
        try {
            getCampaignResetDataWithMappingColumns(body, id).then(resp => {
                if (resp.Status === 'Success') {
                    if (resp?.Message?.length !== 0) {
                        setTotalData(resp.Message.totalElements);
                        // getDynamicColumn(resp.Message.content, props);
                        getDynamicColumn(props,resp.Message.timeZone);
                        setColumnData(finalColumnHeaderList);
                        setRowData(resp.Message.content);
                    }
                    else {
                        setTotalData(0);
                        getDynamicColumn(props,"false");
                        setColumnData(finalColumnHeaderList);
                        setRowData([]);
                        showErrorNotification(`No Data Available to show in (${props.values.campaignName}) Campaign`);
                    }
                }
                else {
                    showErrorNotification(JSON.stringify(resp.Message));
                    setRowData([]);
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setRowData([])
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    return (
        <div style={{ marginLeft: "-24px" }}>

            <OZGridToolbar
                doFilterInDataTab
                getFilterSelected={gotFilterValue}
                title='List of Calls'
                hideSearch={true}
                rowCount={totalData}
                hideDownload={true}
                dataGridRef={gridRef}
                showFilters={true}
                filtertProps={{ column: 'Status', options: [{ Success: 'Success' }, { Fail: 'Fail' }, { Pending: 'Pending' }, { Expired: 'Expired' }, { "Limit Exceeded": 'DayLimitReached' }] }}
                // filtertProps={{ column: 'Status', options: [{ Success: 'Success' }, { Fail: 'Fail' }, { Pending: 'Pending' }] }}
            />
            <OZDataGrid
                doPagination
                totalData={totalData}
                pageSizeClicked={getPageSize}
                pageNumberClicked={getPageNumber}
                columns={columnData}
                data={rowData}
                height="22.3rem"
                setGridRef={setGridRef}
                resizable={true}
            />
        </div>
    );
};


const NewDataMappingForm = (props) => {

    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add

    const [mode] = useState(initMode);
    const [openDataMapping, setDataMappingform] = useState(true);
    const [fileObjsUploaded, setFileObjsUploaded] = useState(uploadedMappingFiles?.length > 0 ? uploadedMappingFiles : [])
    const maxSize = 25 * 1024 * 1024;

    const closeDataMappingform = () => {
        setDataMappingform(false);
        uploadedMappingFiles = []
        props.onClose();
    }

    let uploadHeadingStyle = useMemo(
        () => ({
            fontSize: '0.75rem',
            fontWeight: '400',
            color: '#212121',
            marginTop: '15px',
        }),
        []
    );

    function updateUploadedMappingFiles(data) {
        uploadedMappingFiles = data
    }

    function checkVariant(message, variant) {
        if (variant === "success") {
            showSuccessNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        }
        else if (variant === "error") {
            showErrorNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        }
        else {
            showInfoNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        }
    }

    async function addMappingForm(data) {
        if (uploadedMappingFiles?.length > 0) {
            var form = new FormData();
            form.append("file", uploadedMappingFiles[0], "AddMappingFile.xlsx");
            form.append("name", data.mapName);
            try {
                addNewMapping(form).then(resp => {
                    if (resp.Status === 'Success') {
                        showSuccessNotification(resp.Message);
                        props.updateMappingForm();
                        closeDataMappingform();
                        uploadedMappingFiles = []
                    } else {
                        showErrorNotification(JSON.stringify(resp.Message));
                        setFileObjsUploaded(uploadedMappingFiles = []);
                    }

                }).catch(e => {
                    showErrorNotification(JSON.stringify(e));
                    setFileObjsUploaded(uploadedMappingFiles = [])
                })
            }
            catch (e) {
                showErrorNotification(JSON.stringify(e));
                setFileObjsUploaded(uploadedMappingFiles = []);
            }
            uploadedMappingFiles = []
        }
    }

    function MappingIcon() {
        return (
            <div style={{ marginLeft: '17.5rem', paddingTop: '10px' }}>
                <img alt='edit' src={`${process.env.PUBLIC_URL}/icons/upload_data.svg`}></img>
            </div>
        )
    }

    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `map_upload_sample.xlsx`;
        link.href = `${process.env.PUBLIC_URL}/sample_files/map_upload_sample.xlsx`;
        link.click();
    };

    const DataForm = (props) => {

        let initData = props.data || {
            mapName: '',
            mappingFile: '',
        };

        return (
            <div >
                <Formik
                    initialValues={initData}
                    onSubmit={(values) => {
                        let newMappingValue = structuredClone(values);
                        if (newMappingValue.mapName === "" || newMappingValue.mapName === null) {
                            showErrorNotification("Map Name is required.");
                            return;
                        }
                        if (uploadedMappingFiles?.length <= 0 || uploadedMappingFiles === [] || uploadedMappingFiles === null) {
                            showErrorNotification("Mapping File is required.");
                            return;
                        }
                        if (mode === ADMIN_FORM_MODES.add) {
                            addMappingForm(newMappingValue);
                        }
                    }}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit} id="dataMapping-Form" className='ozcw-scrollbar' style={{
                                maxHeight: '80vh',
                                overflowY: 'auto',
                            }}>
                            <Grid container={true} spacing={2} direction='column'>
                                <Grid item xs={12}>
                                    <OZInput
                                        name="mapName"
                                        label="Map Name *"
                                        placeholder="Enter Name"
                                    />
                                </Grid>
                            </Grid>
                            <div style={uploadHeadingStyle}>Mapping File *</div>
                            <DropzoneArea
                                maxFileSize={maxSize}
                                Icon={MappingIcon}
                                initialFiles={uploadedMappingFiles}
                                dropzoneParagraphClass="mapping-dropzone-text"
                                dropzoneClass="mapping-drop-zone-area"
                                previewGridProps={{
                                    item: {
                                        position: 'absolute',
                                        display: 'flex',
                                        flexWrap: "wrap",
                                        alignItems: 'end',
                                        // height: '5px',
                                        // marginTop: "15px",
                                    },
                                    container: { spacing: 1, direction: 'row' }
                                }}
                                dropzoneText={" "}
                                acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                                onDelete={(fileObj) => { console.log('Removed File:', fileObj); uploadedMappingFiles = []; }}
                                fileObjects={fileObjsUploaded}
                                filesLimit={1}
                                onChange={(files) => {
                                    updateUploadedMappingFiles(files);
                                }}
                                onAlert={(message, variant) => { checkVariant(message, variant); }}
                                useChipsForPreview={true}
                                previewText="Selected files"
                                previewChipProps={{ marginLeft: "12px" }}
                                showAlerts={false}
                            />
                            <div style={{ color: '#99A0A8', fontSize: '12px', fontWeight: '400', paddingTop: '5px' }}>
                                Only XLSX Format is accepted.Download XLSX Sample from
                                <span style={{ color: '#3D8BF8', fontSize: '12px', fontWeight: '400', cursor: 'pointer' }} onClick={onDownload}> Here.</span>
                            </div>
                            <Grid style={{ marginTop: 15 }} container spacing={2} justifyContent='end'>
                                <Grid item>
                                    <Button onClick={closeDataMappingform} style={{ borderRadius: '8px', fontSize: '0.875rem', fontWeight: '400' }} type='submit' variant="outlined" color="primary">
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button style={{ backgroundColor: '#3D8BF8', borderRadius: '8px', fontWeight: '400', fontSize: '14px', color: '#FFFFFF' }} variant="contained" type="submit" form="dataMapping-Form">
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </div >
        );
    }
    return (
        <div>
            <OZDialog
                title={
                    <label style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                        Data Mapping
                    </label>
                }
                open={openDataMapping}
                hideActions={true}
                onClose={closeDataMappingform}
            >
                {(mode === ADMIN_FORM_MODES.add) && <DataForm />}
            </OZDialog>

        </div>
    );
};

const CampaignOutboundForm = (props) => {

    const position = useMemo(() => (props.data ? props.data.position ? props.data.position : null : null), [props]);

    let dialStatusData = useMemo(
        () => (
            [{ id: 'busy', label: 'Busy' }, { id: 'congestion', label: 'Congestion' }, { id: 'noanswer', label: 'NoAnswer' }, { id: 'noresponse', label: 'NoResponse' },
            { id: 'normalcallclearing', label: 'NormalCallClearing' }, { id: 'normalunspecified', label: 'NormalUnspecified' },
            { id: 'not_answered', label: 'Not_Answered' }, { id: 'notanswered', label: 'NotAnswered' }, { id: 'answered', label: 'Answered' }, { id: 'user_disconnected', label: 'user_disconnected' },
            { id: 'rejected', label: 'Rejected' }, { id: 'unknown', label: 'Unknown' }, { id: 'others', label: 'Others' }]
        ), []
    );

    let dialStatusDataForFail = useMemo(
        () => (
            [{ id: 'busy', label: 'Busy' }, { id: 'congestion', label: 'Congestion' }, { id: 'noanswer', label: 'NoAnswer' }, { id: 'noresponse', label: 'NoResponse' },
            { id: 'normalcallclearing', label: 'NormalCallClearing' }, { id: 'normalunspecified', label: 'NormalUnspecified' },
            { id: 'not_answered', label: 'Not_Answered' }, { id: 'notanswered', label: 'NotAnswered' }, { id: 'user_disconnected', label: 'user_disconnected' },
            { id: 'rejected', label: 'Rejected' }, { id: 'unknown', label: 'Unknown' }, { id: 'others', label: 'Others' }]
        ), []
    );

    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add
    const [mode, setMode] = useState(initMode);
    const [value, setValue] = useState('0');

    const validationSchema = useMemo(() => (yup.object({
        campaignName: yup
            .string()
            .min(2, "Campaign Name should be between 2 to 255 alphanumeric characters long and allows special characters like .,@,_,-")
            .max(255, "Campaign Name should be between 2 to 255 alphanumeric characters long and allows special characters like .,@,_,-")
            .matches(/^([A-Za-z0-9]((\.|@|_|-)?[A-Za-z0-9])+)$/, "Campaign Name should be between 2 to 255 alphanumeric characters long and allows special characters like .,@,_,-")
            .required("Campaign Name is required"),
        campaignType: yup
            .string("Enter Campaign Type")
            .required("Campaign Type is required"),
        did: yup
            .string()
            .test("maxDigits",
                "DID should be between 3 to 16 digits long and allows prefix +",
                (number) => String(number).length >= 3 && String(number).length <= 17)
            .matches(/^(\+)?[0-9]{3,16}$/, "DID should be between 3 to 16 digits long and allows prefix +")
            .required('DID is required'),
        fallbackDID: yup
            .string().matches(/^(None|(\+)?[0-9]{3,16})$/, "FallBack DID should be between 3 to 16 digits long and allows prefix +")
            .test("maxDigits",
                "FallBack DID should be between 3 to 16 digits long and allows prefix +",
                (number) => number === 'None' || (String(number).length >= 3 && String(number).length <= 17))
            .nullable(),
        // .string()
        // .matches(/^(\+)?[0-9]{3,16}$/, "FallBack DID should be between 3 to 16 digits long and allows prefix +")
        // .test("maxDigits",
        //   "FallBack DID should be between 3 to 16 digits long and allows prefix +",
        //   (number) => number === 'None' || (String(number).length >= 3 && String(number).length <= 17))
        // .nullable(),
        dispositions: yup
            .array()
            .of(yup.object())
            .required("Dispositions is required.")
            .min(1, "Dispositions must have at least 1 item."),
        skills: yup
            .array()
            .of(yup.object().shape({
                id: yup.number().required("Skills is required"),
                skillName: yup.string().required("skills is required"),
            }))
            .required("Skills is required")
            .min(1, "Skills field must have at least 1 item."),
        priority: yup.number()
            .when([], {
                is: () => (!checkRoles("ROLE_CAMP_PRIORITY")),
                then: yup.number().notRequired().nullable(),
                otherwise: yup.number("Enter Priority")
                    .min(1, 'Priority should be between 1 and 999')
                    .max(999, 'Priority should be between 1 and 999')
                    .typeError('The value must be a number')
                    .integer('The value must be a number')
                    .required('Priority is required'),
            }),
        runtimeStart: yup
            .string()
            .nullable()
            .test('both-null', 'Start Time and End Time are required.', function (runtimeStart) {
                const { runtimeEnd } = this.parent;
                if (!runtimeStart && !runtimeEnd) {
                    return false;
                }
                return true;
            })
            .required('Start Time is required.'),
        runtimeEnd: yup
            .string()
            .nullable()
            .test('both-null', 'Start Time and End Time are required.', function (runtimeEnd) {
                const { runtimeStart } = this.parent;
                if (!runtimeStart && !runtimeEnd) {
                    return false;
                }
                return true;
            })
            .required('End Time is required.')
            .test('end-valid', 'End Time should be greater.', function (runtimeEnd) {
                const { runtimeStart } = this.parent;
                if (runtimeStart === runtimeEnd || runtimeStart > runtimeEnd) {
                    return false;
                }
                return true;
            }),
        ruleNot: yup
            .number("Enter No.Of Tries")
            .min(1, 'No.Of Tries should be between 1 and 10.')
            .max(10, 'No.Of Tries should be between 1 and 10.')
            .typeError('The value must be a number')
            .integer('The value must be a number')
            .required('No.Of Tries is required'),
        callPrefix: yup
            .string()
            .matches(/^[0-9+][0-9]{0,5}$/, "Call Prefix should be between 1 to 6 digits long and allows prefix +")
            .nullable(),
        sla: yup
            .number()
            .typeError("Wrapup Time (in Sec) is required")
            .when('dispositionType.id', {
                is: id => id === 'API' || id === 'Toolbar',
                then: yup
                    .number()
                    .min(0, "Wrapup Time should be between 0 to 9999")
                    .max(9999, "Wrapup Time should be between 0 to 9999")
                    .typeError('Wrapup Time should be a Number')
                    .required("Wrapup Time (in Sec) is required.")
                    .nullable(),
                otherwise: yup.number().notRequired().nullable()
            }),
        dialInterval: yup
            .number()
            .typeError("Dial Interval(in Sec) is required.")
            .when('campaignType', {
                is: type => type === 'Progressive' || type === 'IVR',
                then: yup
                    .number()
                    .min(0, "Dial Interval should be between 0 to 9999")
                    .max(9999, "Dial Interval should be between 0 to 9999")
                    .typeError('Dial Interval should be a Number')
                    .required("Dial Interval(in Sec) is required.")
                    .nullable(),
                otherwise: yup.number().notRequired().nullable()
            }),
        customerRingingTime: yup
            .number()
            .typeError("Customer Ringing Time should be between 1 and 90")
            .when('campaignType', {
                is: type => type === 'Progressive' || type === 'IVR' || type === 'Predictive',
                then: yup.number()
                    .typeError("Customer Ringing Time should be between 1 and 90.")
                    .min(1, 'Customer Ringing Time should be between 1 and 90.')
                    .max(90, 'Customer Ringing Time should be between 1 and 90.')
                    .typeError('Customer Ringing Time should be a Number')
                    .required("Customer Ringing Time is required.")
                    .nullable(),
                otherwise: yup
                    .number()
                    .notRequired()
                    .when('customerFirst', {
                        is: value => value === true,
                        then: yup.number()
                            .typeError("Customer Ringing Time should be between 1 and 90.")
                            .min(1, 'Customer Ringing Time should be between 1 and 90.')
                            .max(90, 'Customer Ringing Time should be between 1 and 90.')
                            .typeError('Customer Ringing Time should be a Number')
                            .required("Customer Ringing Time is required.")
                            .nullable(),
                        otherwise: yup
                            .number()
                            .typeError("Customer Ringing Time should be between 1 and 90.")
                            .min(1, 'Customer Ringing Time should be between 1 and 90.')
                            .max(90, 'Customer Ringing Time should be between 1 and 90.')
                            .notRequired()
                            .nullable()
                    })
            }),
        dataLimit: yup
            .number('Max Concurrent Calls is required.')
            .typeError("Max Concurrent Calls is required.")
            .when('campaignType', {
                is: type => type === 'IVR',
                then: yup.number('Max Concurrent Calls is required.').typeError('Max Concurrent Calls should be a Number').required("Max Concurrent Calls is required.")
                    .nullable(),
                otherwise: yup.number('Max Concurrent Calls is required.').notRequired().nullable()
            }),
        dialCount: yup
            .number('Daily Outbound Limit per Number should be a Number.')
            .typeError("Daily Outbound Limit per Number should be a Number.")
            .when('campaignType', {
                is: type => checkRoles("ROLE_OBD_RESTRICTION") === true,
                then: yup.number('Daily Outbound Limit per Number is required.')
                    .min(1, 'Daily Outbound Limit per Number between 1 and 99.')
                    .max(99, 'Daily Outbound Limit per Number between 1 and 99.')
                    .typeError('Daily Outbound Limit per Number should be a Number.')
                    .nullable(),
                otherwise: yup.number('Daily Outbound Limit per Number is required.').notRequired().nullable()
            }),
        disclaimer: yup
            .string()
            .min(5, "Disclaimer Should start with http|https and Ends with .wav/.mp3 etc and atleast one character to be contain in between and allows Max Length of 255 characters")
            .max(255, "Disclaimer Should start with http|https and Ends with .wav/.mp3 etc and atleast one character to be contain in between and allows Max Length of 255 characters")
            .matches(/^(https?:\/\/)(.{1,255}\.)[a-zA-Z0-9]+$/, "Disclaimer Should start with http|https and Ends with .wav/.mp3 etc and atleast one character to be contain in between and allows Max Length of 255 characters"),
        dropRatio: yup
            .number('Max Drop Ratio (%) is required.')
            .typeError("Max Drop Ratio (%) is required.")
            .when('campaignType', {
                is: type => type === 'Predictive',
                then: yup.number('Max Drop Ratio (%) is required.')
                    .min(1, 'Max Drop Ratio should be between 1 and 99.')
                    .max(99, 'Max Drop Ratio should be between 1 and 99.')
                    .typeError('Max Drop Ratio should be a Number')
                    .required("Max Drop Ratio (%) is required.")
                    .nullable(),
                otherwise: yup.number('Max Drop Ratio (%) is required.').notRequired().nullable()
            }),
        ruleNac: yup
            .string()
            .typeError("Pacing Ratio(Calls:Agent) must be a ratio")
            .when('campaignType', {
                is: type => type === 'Predictive',
                then: yup
                    .string()
                    .typeError('Pacing Ratio(Calls: Agent) must be a ratio')
                    .required("Pacing Ratio(Calls:Agent) is required.")
                    .nullable()
                    .matches(/^\d+:\d+$/, 'Pacing Ratio(Calls: Agent) seems to be invalid'),
                otherwise: yup.string().notRequired().nullable()
            }),
        ivrFlow: yup
            .object()
            .when('campaignType', {
                is: type => type === 'IVR',
                then: yup
                    .object()
                    .nullable()
                    .required("IVR Flow is required."),
                otherwise: yup.object().notRequired().nullable()
            }),
        previewDataMap: yup
            .object()
            .nullable()
            .required("Mapping Name is required."),
        blockNumberGroups: yup
            .array()
            .when('blockNumber.id', {
                is: id => id === 'Allow' || id === 'Block',
                then: yup
                    .array()
                    .of(yup.object().shape({
                        id: yup.number().required("Block Number Group is required."),
                        name: yup.string().required("Block Number Group is required."),
                    }))
                    .min(1, "Block Number Group must have at least 1 item")
                    .required(),
                otherwise: yup
                    .array()
                    .notRequired()
                    .nullable()
            }),
        appURL: yup
            .string()
            .when(['ivrFlow.flowName', 'campaignType'], {
                is: (name, campaignType) =>
                    (campaignType === 'IVR' && (name === 'Other')),
                then: yup
                    .string()
                    .required("App. URL is required."),
                otherwise: yup
                    .string()
                    .notRequired()
                    .nullable()
            }),
        amdUrl: yup
            .string()
            .when(['amdType.id', 'amdActive'], {
                is: (id, active) => (id === 'PlayVoiceMessage' && active === true),
                then: yup
                    .string('Voice Message URL is required.')
                    .matches(/^(https?:\/\/)(.{1,255}\.)(wav|mp3)$/, 'Voice Message URL Should start with http|https and Ends with .wav/.mp3 and atleast one character to be contain in between')
                    .nullable()
                    .required("Voice Message URL is required."),
                otherwise: yup.string().notRequired().nullable()
            }),
        amdType: yup
            .object()
            .when('amdActive', {
                is: active => active === true || active === 'true',
                then: yup
                    .object()
                    .nullable()
                    .required("AMD Detected is required"),
                otherwise: yup
                    .object()
                    .notRequired()
                    .nullable()
            }),
        screenPopUrlInBound: yup
            .object()
            .when('popUrlAt.id', {
                is: (id) => (id === 'Plugin'),
                then: yup
                    .object()
                    .nullable()
                    .required("Plugin Name is required."),
                otherwise: yup.object().notRequired().nullable()
            }),
        transferSkills: yup
            .array()
            .when('isTransferSkill', {
                is: value => value === true || value === 'true',
                then: yup
                .array()
                .of(yup.object().shape({
                    id: yup.number().required("Select values for Transfer Skills"),
                    skillName: yup.string().required("Select values for Transfer Skills"),
                }))
                .required("Select values for Transfer Skills")
                .min(1, "Select values for Transfer Skills"),
                otherwise: yup.array().notRequired().nullable()
            }),
        script: yup
            .string()
            .test('is-html-length-valid','Script character length cannot exceed 7,000 characters and should not include icons or emojis.',(value) => {
                if (!value) return true;
                const regex = /^[\u0000-\u00FF]*$/;
                const isLatin1Text = regex.test(value);
                if (!isLatin1Text) {
                    return false;
                }
                const parser = new DOMParser();
                const parsedHtml = parser.parseFromString(value, 'text/html');
                const textContent = parsedHtml.documentElement.textContent;
                // const textContent = parsedHtml.documentElement.textContent.trim().replace(/\s+/g, ' ');
                return textContent.length <= 7000;
            }),
    })),
        []);

    const [copyCampaign, setCopyCampaign] = useState(false);

    const [secondaryFallbackDidOption, setSecondaryFallBackDidOption] = useState([]);
    const [secondaryDidOption, setSecondaryDidOption] = useState([]);
    const [secondaryDidOptionFullList, setSecondaryDidOptionFullList] = useState([]);
    const [skillOption, setSkillOption] = useState([]);
    const [subUserOption, setSubUserOption] = useState([]);
    const [settingsData, setSettingsData] = useState([])
    const [blockNumberGroupOption, setBlockNumberGroupOption] = useState([]);
    const [onHoldMusicOption, setOnHoldMusicOption] = useState([]);
    const [pluginOption, setpluginOption] = useState([]);
    const [dispositonsOption, setDispositonsOption] = useState([]);
    const [ivrFlowOption, setIvrflowOption] = useState([]);
    const [prePostOption, setprePostOption] = useState([]);
    const [holidaysList, setHolidaysList] = useState([]);
    const [loading, setLoading] = useState((mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? true : false);
    const [newLoading, setNewLoading] = useState(false);

    const onClickEdit = () => {
        setMode(ADMIN_FORM_MODES.edit);
    }

    const onClickDiscardEdit = () => {
        setMode(ADMIN_FORM_MODES.view);
    }

    const openCopyCampaign = () => {
        setCopyCampaign(true);
    }

    const closeCopyCampaignForm = (props) => {
        setCopyCampaign(false);
    }

    const closeCampaignOutboundForm = () => {
        // localStorage.clear();
        localStorage.removeItem("OutboundForm");
        uploadedFiles = []
        uploadedMappingFiles = []
        props.updateCampaignOutbound();
        props.onClose();
    }

    useEffect(() => {
        if (mode !== ADMIN_FORM_MODES.view) {
            getSkillsOptions()
            getSubUserOptions()
            getBlocknumberGroupOptions()
            getOnHoldMusicOptions()
            getDispositionsOptions()
            getIvrFlowOptions()
            getPrePostOptions()
            getHolidays()
        }
        getSecondaryDidOptions()
        getSettingsData()
        getPluginOptions()
    }, [mode])


    async function addUploadFile(id, name) {
        if (uploadedFiles?.length > 0) {
            var form = new FormData();
            // form.append("file", uploadedFiles[0].file, "AddUploadFile.xlsx");
            form.append("file", uploadedFiles[0].file);
        }
        try {
            addNewUploadFile(form, id).then(resp => {
                if (resp?.Status === 'Success') {
                    let NewAPIResponse = JSON.parse(resp?.Data?.body);
                    if (NewAPIResponse?.status === 'error') {
                        showErrorNotification(NewAPIResponse?.message);
                        return null;
                    } else if (NewAPIResponse?.status === 'success') {
                        showSuccessNotification(`Campaign Data upload is successfully initiated for (${name}) Campaign`);
                    }
                } else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }

            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
        uploadedFiles = []
    }

    async function addCampaignOutboundForm(data) {
        data.popUrlAt = data.popUrlAt.value;
        if (data.dispositionType.value === -1) {
            data.sla = -1;
        }
        if (data.campaignType === 'IVR') {
            if (data.ivrFlow.flowName === "Other") {
                data.ivrFlow = null;
            } else if (data.ivrFlow.flowName !== "Other") {
                data.appURL = `${APP_URL}${data?.ivrFlow?.flowId}`;
            }
        }

        if (data.screenPopUrl === null) {
            data.screenPopUrl = "";
            delete data.screenPopUrlInBound;
        }

        if (data.screenPopUrl === "") {
            delete data.screenPopUrlInBound;
        }

        if (data.amdActive === true) {
            data.amdType = data.amdType.id;
        } else {
            data.amdType = "";
        }
        if (data.campaignType !== 'Predictive') {
            data.ruleNac = null;
            data.dropRatio = null;
        }
        data.dispositionType = data.dispositionType.value;
        data.blockNumber = data.blockNumber.value;
        data.recOn = data.recOn.value;
        if (data.campaignType !== 'IVR') {
            data.ivrFlow = null;
            data.dataLimit = null;
        }
        if (data.holdMusic !== null) {
            data.holdMusic = data.holdMusic.name;
        }
        if (data.transferMusic !== null) {
            data.transferMusic = data.transferMusic.name;
        }
        console.log("in add", data);
        try {
            addNewCampaignOutbound(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${data.campaignName} Campaign is created Successfully`);
                    props.updateCampaignOutbound();
                    if (uploadedFiles?.length > 0) {
                        addUploadFile(resp.campaignId, data?.campaignName);
                    } else {
                        showInfoNotification(`No Data is uploaded for ${data?.campaignName} Campaign`);
                    }
                    closeCampaignOutboundForm();
                    setNewLoading(false);
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                    // showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function updateCampaignOutboundForm(data, id) {
        data.popUrlAt = data.popUrlAt.value;
        if (data.dispositionType.value === -1) {
            data.sla = -1;
        }
        if (data.campaignType === 'IVR') {
            if (data.ivrFlow.flowName === "Other") {
                data.ivrFlow = null;
            } else if (data.ivrFlow.flowName !== "Other") {
                data.appURL = `${APP_URL}${data?.ivrFlow?.flowId}`;
            }
        }
        if (data.campaignType !== 'Predictive') {
            data.ruleNac = null;
            data.dropRatio = null;
        }

        if (!data.screenPopUrl || data.screenPopUrl === "") {
            data.screenPopUrl = "";
            delete data.screenPopUrlInBound;
        }



        if (data.campaignType !== 'IVR') {
            data.ivrFlow = null;
            data.dataLimit = null;
        }
        if (data.amdActive === true) {
            data.amdType = data.amdType.id;
        } else {
            data.amdType = "";
        }
        data.dispositionType = data.dispositionType.value;
        data.blockNumber = data.blockNumber.value;
        data.recOn = data.recOn.value;
        if (data.holdMusic !== null) {
            data.holdMusic = data.holdMusic.name;
        }
        if (data.transferMusic !== null) {
            data.transferMusic = data.transferMusic.name;
        }

        //for this discussion needs::::::::::::://
        if (data.retryRules === false || data.retryRules === null) {
            if (data.prevRetryConditionValue === null) {
                if (data?.retryConditions?.length === 1 && data?.retryConditions[0]?.dialStatus?.length === 0 && data?.retryConditions[0]?.dispositions?.length === 0 && data?.retryConditions[0]?.condition === "") {
                    data.retryConditions = null;
                }
            }
        }
        //for this discussion needs::::::::::::://

        if (data.retryConditions !== null || data.retryConditions !== "") {
            data.retryConditions = JSON.stringify(data.retryConditions);
        }

        //for this discussion needs::::::::::::://
        delete data.prevRetryConditionValue;
        //for this discussion needs::::::::::::://
        console.log("in edit", data);
        try {
            updateCamapignOutbound(data, id).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${data.campaignName} Campaign Updated Successfully`);
                    props.updateCampaignOutbound();
                    closeCampaignOutboundForm();
                    setNewLoading(false)
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false)
                    // showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false)
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false)
        }

    }

    async function getPluginOptions() {
        setNewLoading(true);
        try {
            getPluginDropdown().then(resp => {
                setpluginOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Plugin Name, Check your Internet Connection');
                setpluginOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getSkillsOptions() {
        setNewLoading(true);
        try {
            getSkillsDropdown().then(resp => {
                setSkillOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                setSkillOption([]);
                showErrorNotification('No Skill Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getSecondaryDidOptions() {
        setNewLoading(true);
        try {
            getSecondaryDidDropdown().then(resp => {
                // console.log("response here ", resp);
                // console.log("::::::::::::", resp.Data)
                setSecondaryDidOptionFullList(resp?.Data !== null ? JSON.parse(resp.Data) : [])
                const newDIArray = resp.Data !== null ? JSON.parse(resp.Data).map(item => item?.DID) : [];
                // const newFallBackDidArray = resp.Data !== null ? JSON.parse(resp.Data).map(item => item?.DID) : [];
                const newFallBackDidArray = resp.Data !== null ? ["None", ...JSON.parse(resp.Data).map(item => item?.DID)] : [];

                setSecondaryDidOption(newDIArray);
                setSecondaryFallBackDidOption(newFallBackDidArray)
                // console.log("newFallBackDidArray", newFallBackDidArray)
                setNewLoading(false);
            }).catch(e => {
                setSecondaryDidOption([]);
                showErrorNotification('No Secondary DID Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getDispositionsOptions() {
        setNewLoading(true);
        try {
            getDispositionsDropdown().then(resp => {
                setDispositonsOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Dispostions Data, Check your Internet Connection');
                setDispositonsOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getSettingsData() {
        setNewLoading(true);
        try {
            getAdminSettingsList().then(resp => {
                setSettingsData(resp.Data);
                // console.log("settingsData", settingsData)
                setNewLoading(false);
            }).catch(e => {
                setSettingsData([])
                console.log(e)
                setNewLoading(false);
            })
        }
        catch (e) {
            console.log(e)
            setNewLoading(false);
        }
    }


    async function getSubUserOptions() {
        setNewLoading(true);
        try {
            getSubUserDropdown().then(resp => {
                setSubUserOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                setSubUserOption([]);
                showErrorNotification('No User Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getBlocknumberGroupOptions() {
        setNewLoading(true);
        try {
            getBlockNumberGroupDropdown().then(resp => {
                setBlockNumberGroupOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No BlockNumber Group Data, Check your Internet Connection');
                setBlockNumberGroupOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getOnHoldMusicOptions() {
        setNewLoading(true);
        try {
            getOnHoldMusicDropdown().then(resp => {
                setOnHoldMusicOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Hold Music Data, Check your Internet Connection');
                setOnHoldMusicOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getIvrFlowOptions() {
        setNewLoading(true);
        try {
            getIvrFlowDropdown().then(resp => {
                if (resp.Status === "Success") {
                    const ivrNewFlow = [{
                        "flowId": "1_Other",
                        "flowName": "Other"
                    }, ...resp?.Data];
                    setIvrflowOption(ivrNewFlow);
                } else {
                    setIvrflowOption([]);
                    setNewLoading(false);
                    showErrorNotification('No IVRFlow Data, Check your Internet Connection');
                }
                setNewLoading(false);
            }).catch(e => {
                setIvrflowOption([]);
                showErrorNotification('No IVRFlow Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getPrePostOptions() {
        setNewLoading(true);
        try {
            getAddCallWork().then(resp => {
                let tempCallData = [];
                resp?.Data?.map((item) => {
                    const data = {
                        id: item?.flowName,
                        flowName: item?.flowName,
                    }
                    tempCallData.push(data);
                    return null;
                })
                setprePostOption(tempCallData);
                setNewLoading(false);
            }).catch(e => {
                setprePostOption([]);
                showErrorNotification('No PrePost Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    const getHolidays = async () => {
        setNewLoading(true);
        try {
            const resp = await getHolidaysListForCampaigns();
            if (resp && resp.Data) {
                setHolidaysList(resp.Data);
            }
        }
        catch (e) {
            setHolidaysList([]);
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    const DataForm = (props) => {
        // let newRunStartTime = null;
        // let newRunEndTime = null;

        // let retryConditions = useMemo(
        //     () => (
        //         [{
        //             dialStatus: [],
        //             config: [{
        //                 intervalDays: 0,
        //                 intervalHours: 0,
        //                 intervalMinutes: 0,
        //             }]
        //         }]
        //     ), []
        // );

        let retryConditions = useMemo(
            () => (
                [{
                    "parameters": [
                        {
                            "type": "Customer Status",
                            "value": [],
                            "condition": ""
                        }
                    ],
                    "config": [
                        {
                            "intervalDays": 0,
                            "intervalHours": 0,
                            "intervalMinutes": 0,
                            "retryCount": 1
                        }
                    ],
                    "noOfTries": 1,
                    'callStatus': 'Fail',
                }]
            ), []
        );

        if (props?.data) {

            // if (typeof props.data.retryConditions === 'string') {
            //     props.data.retryConditions = JSON.parse(props.data.retryConditions);
            //     let newRetryConditions = structuredClone(props.data.retryConditions);
            //     newRetryConditions.map((item, index) => {
            //         item.config.map((configItem, configIndex) => {
            //             if (configItem.intervalDays === "" || configItem.intervalDays === " ") {
            //                 configItem.intervalDays = 0;
            //             }
            //             if (configItem.intervalHours === "" || configItem.intervalHours === " ") {
            //                 configItem.intervalHours = 0;
            //             }
            //             if (configItem.intervalMinutes === "" || configItem.intervalMinutes === " ") {
            //                 configItem.intervalMinutes = 0;
            //             }
            //             return null;
            //         })
            //         return null;
            //     })
            //     props.data.retryConditions = newRetryConditions;
            // }
            // else {
            //     if (props.data.retryConditions === null) {
            //         props.data.retryConditions = retryConditions;
            //     }
            // }

            if (props?.data?.retryConditions === null || props?.data?.retryConditions === "") {
                props.data.retryConditions = retryConditions;
            } else if (props?.data?.retryConditions !== null || props?.data?.retryConditions !== "") {
                let newRetry = structuredClone(props?.data?.retryConditions);
                newRetry?.map((item) => {
                    item?.config?.map((configItem) => {
                        if (configItem.intervalDays === "" || configItem.intervalDays === " ") {
                            configItem.intervalDays = 0;
                        }
                        if (configItem.intervalHours === "" || configItem.intervalHours === " ") {
                            configItem.intervalHours = 0;
                        }
                        if (configItem.intervalMinutes === "" || configItem.intervalMinutes === " ") {
                            configItem.intervalMinutes = 0;
                        }
                        return null;
                    })
                    return null;
                })
                props.data.retryConditions = newRetry;
            }

            const popUrlAt = [{ id: 'Client Side', value: 0 }, { id: 'Server Side', value: 1 }, { id: 'Client&Server', value: 2 }, { id: 'Client Side OnBusy', value: 3 }, { id: 'Plugin', value: 4 }, { id: 'Server Side OnBusy', value: 5 }];
            popUrlAt.forEach(item => {
                if (item.value === props.data.popUrlAt) {
                    props.data.popUrlAt = item;
                }
            })

            if (props?.data?.popUrlAt?.id === "Plugin") {
                // console.log("here plugins", pluginOption);
                pluginOption.forEach(item => {
                    // console.log("match",item.id === parseInt(props?.data?.screenPopUrl))
                    // console.log("match id" ,item.id);
                    // console.log("match id2" , parseInt(props?.data?.screenPopUrl))
                    // console.log("match id33333" , item)
                    props.data.screenPopUrlInBound = item
                })
                const match = pluginOption.find(item => item.id === parseInt(props?.data?.screenPopUrl));
                // console.log("here plugins match", match);
                props.data.screenPopUrlInBound = match
                // props.data.screenPopUrlInBound;
            }

            // if(props?.data?.popUrlAt?.id === "Plugin"){
            //     console.log("here plugins", pluginOption);
            //     pluginOption.forEach(item=>{
            //         // console.log("match",item.id === parseInt(props?.data?.screenPopUrl))
            //         // console.log("match id" ,item.id);
            //         // console.log("match id2" , parseInt(props?.data?.screenPopUrl))
            //         // console.log("match id33333" , item)
            //         props.data.screenPopUrlInBound = item
            //     })
            //     const match = pluginOption.find(item => item.id === parseInt(props?.data?.screenPopUrl));
            //     // console.log("here plugins match", match);
            //     props.data.screenPopUrlInBound = match
            //     // props.data.screenPopUrlInBound;
            // }

            if (props?.data?.fallbackDID === null || props?.data?.fallbackDID.length === 0) {
                props.data.fallbackDID = "None";
            }

            const blockNumber = [{ id: 'None', value: 0 }, { id: 'Block', value: 1 }, { id: 'Allow', value: 2 }];
            blockNumber.forEach(item => {
                if (item.value === props.data.blockNumber) {
                    props.data.blockNumber = item;
                }
            })

            const recOn = [{ id: 'Record the call in Full', value: 0 }, { id: 'Record only the conversation', value: 1 }, { id: 'Do not record calls', value: 2 }];
            recOn.forEach(item => {
                if (item.value === props.data.recOn) {
                    props.data.recOn = item;
                }
            })

            if (props?.data?.appURL !== null || props?.data?.appURL !== "") {
                if (props?.data.ivrFlow === null) {
                    props.data.ivrFlow = {
                        "flowId": "1_Other",
                        "flowName": "Other"
                    }
                }
            }

            const dispositionType = [{ id: 'None', value: -1 }, { id: 'API', value: -2 }, { id: 'Toolbar', value: 1 }];
            dispositionType.forEach(item => {
                if (item.value === props.data.dispositionType) {
                    props.data.dispositionType = item;
                }
            })

            const amdType = [{ id: 'PlayVoiceMessage', label: 'Play Voice Message' }, { id: 'DisconnectAndAnswered', label: 'Disconnect and Answered' }, { id: 'DisconnectAndUnAnswered', label: 'Disconnect and Unanswered' }];
            amdType.forEach(item => {
                if (item.id === props.data.amdType) {
                    props.data.amdType = item;
                }
            })

            // if (props?.data?.runtimeStart) {
            //     newRunStartTime = moment(props.data.runtimeStart, "HH:mm:ss");
            // }
            // if (props?.data?.runtimeEnd) {
            //     newRunEndTime = moment(props.data.runtimeEnd, "HH:mm:ss");
            // }
        }
        let initData = props.data || {
            campaignName: '',
            campaignType: "",
            users: [],
            did: "",
            fallbackDID: null,
            ivrFlow: null,
            dataLimit: '',
            dialCount: null,
            amdActive: false,
            popUrlAt: { id: 'Client Side', value: 0 },
            callbackUrl: "",
            disclaimer: "",
            screenPopUrl: "",
            screenPopUrlInBound: null,
            callPrefix: null,
            dispositions: [],
            dispositionType: { id: 'Toolbar', value: 1 },
            sla: null,
            blockNumber: { id: 'None', value: 0 },
            recOn: { id: 'Record only the conversation', value: 1 },
            blockNumberGroups: [],
            holdMusic: null,
            transferMusic: null,
            ruleNot: null,
            runtimeStart: null,
            runtimeEnd: null,
            priority: null,
            dialInterval: null,
            customerRingingTime: null,
            previewDataMap: null,
            uploadFile: "",
            recentFirst: false,
            formatPhoneNumber: true,
            // dndEnable: checkRoles("ROLE_DND_CHECK") ? false : true,
            dndEnable: true,
            voiceBot: false,
            acwNac: false,
            customerFirst: false,
            offLineMode: false,
            holdAgent: false,
            allowedManual: false,
            dncEnabled: false,
            // didMasking: false,
            Advanced: "",
            dialMethod: "Nonagentwise",
            dropRatio: null,
            ruleNac: null,
            voiceMessageURL: '',
            aMDDetected: [],
            skills: [],
            callWork: [],
            dynamicDID: [],
            fallbackDynamicDID: [],
            perDayRetries: null,
            noOfDaysToRetry: null,
            retryFirst: false,
            customerStatusFirst: [],
            advancedCheck: false,
            retryRules: false,
            position: "READY",

            retryConditions: retryConditions,
            appURL: "",
            script: "",
            amdType: { id: 'DisconnectAndAnswered', label: 'Disconnect and Answered' },
            amdUrl: "",
            holiday: [],
            transferSkills : [],
            isTransferSkill: false
        };
        setLoading(false);

        const handleChange = (event, newValue, props) => {
            localStorage.setItem('OutboundForm', JSON.stringify(props.values));
            setValue(newValue);
        }

        // Stepper is merging from here
        const steps = ["Basic Settings", "General Information", "Skills", "Script"];
        const [activeStep, setActiveStep] = useState(0);
        const [completed] = useState({});

        // const [dispatchStartTime, setDispatchStartTime] = useState(newRunStartTime);
        // const [dispatchEndTime, setDispatchEndTime] = useState(newRunEndTime);

        // const handleStartTimeChange = (value) => {
        //     setDispatchStartTime(value);
        // };

        // const handleEndTimeChange = (value) => {
        //     setDispatchEndTime(value);
        // };

        const handleStep = (step) => () => {
            setActiveStep(step);
        };

        const handleNext = () => {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            setActiveStep(newActiveStep);
        };

        const totalSteps = () => {
            return steps.length;
        };

        const completedSteps = () => {
            return Object.keys(completed).length;
        };

        const isLastStep = () => {
            return activeStep === totalSteps() - 1;
        };

        const allStepsCompleted = () => {
            return completedSteps() === totalSteps();
        };

        let formHeading = useMemo(
            () => ({
                fontWeight: '400',
                fontSize: '22px',
                color: '#212121',
                fontStyle: 'normal',
                fontFamily: 'Roboto',
            }),
            []
        );

        let scriptStyle = useMemo(
            () => ({
                color: '#E65B5C',
                fontWeight: '400',
                fontSize: '0.75rem',
                marginLeft: '10px',
                marginTop: '5px'
            }),
            []
        );

        let copyStyle = useMemo(
            () => ({
                paddingLeft: '20px',
                fontSize: '14px',
                flex: 'right',
                color: '#536580',
            }),
            []
        );

        const tabButtonStyle = useMemo(
            () => ({
                color: "#212121",
                fontFamily: "Roboto",
                fontSize: " 0.75rem",
                fontWeight: 400,
                letterSpacing: "0em",
                backgroundColor: "#F5F8FA",
                width: "5.8125rem",
                height: "1.875rem",
                borderRadius: 0,
                '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: '#FFFF',
                },
            }),
            []
        );

        const tabMappingColumnButtonStyle = useMemo(
            () => ({
                marginTop: '5px',
                color: "#212121",
                fontFamily: "Roboto",
                fontSize: " 0.75rem",
                fontWeight: 400,
                letterSpacing: "0em",
                backgroundColor: "#F6F7F9",
                // width: "8.8125rem",
                height: "2.75rem",
                borderRadius: 0,
                '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: '#FFFF',
                },
            }),
            []
        );

        let stepperStyle = useMemo(
            () => ({
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
                paddingTop: '1.25rem',
            }),
            []
        );

        let advancedButtonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400, color: '#99A0A8' }), [])

        const formButtonStyle = useMemo(
            () => ({
                borderRadius: '8px',
                fontWeight: '400',
                fontSize: '14px',
            }),
            []
        );

        const CampaignSettings = (props) => {
            const propsSettings = props?.props;
            const newToModifyType = propsSettings?.props?.values?.campaignType;
            const campaignPosition = propsSettings?.props?.values?.position;
            const isProgressiveOrIVR = newToModifyType === 'Progressive' || newToModifyType === 'IVR';

            return (
                <Stack>
                    <div style={{ color: '#212121', fontSize: '18px', marginTop: '20px', fontWeight: '400' }}>
                        <label>Campaign Settings</label>
                    </div>
                    <Grid container spacing={2} style={{ marginTop: '1px', fontSize: '14px', color: '#212121' }} display='flex' flexWrap={true} direction={'row'} >
                        <Grid item xs={3}>
                            <OZFCheckbox
                                disabled={mode === ADMIN_FORM_MODES.view  || campaignPosition === "RUNNING"}
                                name="recentFirst"
                                label="Recent First"
                                labelPosition="end"
                            />
                        </Grid>
                        <Grid item xs={3} >
                            <OZFCheckbox
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="formatPhoneNumber"
                                label="STD Enabled"
                                labelPosition="end"
                            />
                        </Grid>
                        {/* {(propsSettings?.props?.values?.campaignType === "Preview" || propsSettings?.props?.values?.campaignType === "Progressive") &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view || propsSettings?.props?.values?.holdAgent === true}
                                    name="customerFirst"
                                    label="Dial Customer First"
                                    labelPosition="end"
                                />
                            </Grid>
                        } */}
                        {((propsSettings?.props?.values?.campaignType === "Preview" || propsSettings?.props?.values?.campaignType === "Progressive") && (!propsSettings?.props?.values?.holdAgent)) &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view || propsSettings?.props?.values?.holdAgent === true}
                                    name="customerFirst"
                                    label="Dial Customer First"
                                    labelPosition="end"
                                />
                            </Grid>
                        }
                        <Grid item xs={3} >
                            <OZFCheckbox
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="allowedManual"
                                label="Allow Manual Dialing"
                                labelPosition="end"
                            />
                        </Grid>

                        {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_DND_CHECK")) &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="dndEnable"
                                    label="DND Enable"
                                    labelPosition="end"
                                />
                            </Grid>
                        }
                        {(propsSettings?.props?.values?.campaignType === "IVR") &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="voiceBot"
                                    label="Voice Bot"
                                    labelPosition="end"
                                />
                            </Grid>
                        }
                        {/* {propsSettings?.props?.values?.campaignType !== 'Predictive' && (
                            <Grid item xs={4} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view || propsSettings?.props?.values?.customerFirst === true
                                        || propsSettings?.props?.values?.holdAgent === true}
                                    name="acwNac"
                                    label="ACW for Unanswered Call"
                                    labelPosition="end"
                                />
                            </Grid>
                        )} */}
                        {(propsSettings?.props?.values?.dispositionType?.id !== 'None' && (!propsSettings?.props?.values?.holdAgent)) &&
                            (propsSettings?.props?.values?.dispositionType?.id !== 'None' && (!propsSettings?.props?.values?.offLineMode)) &&
                            (propsSettings?.props?.values?.dispositionType?.id !== 'None' && (!propsSettings?.props?.values?.customerFirst)) && (
                                <Grid item xs={4} >
                                    <OZFCheckbox
                                        disabled={mode === ADMIN_FORM_MODES.view || propsSettings?.props?.values?.customerFirst === true
                                            || propsSettings?.props?.values?.holdAgent === true || propsSettings?.props?.values?.campaignType === 'Predictive'}
                                        name="acwNac"
                                        label="ACW for Unanswered Call"
                                        labelPosition="end"
                                    />
                                </Grid>
                            )
                        }
                        {checkRoles("ROLE_DNC_REGULATION") &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    // disabled={(mode === ADMIN_FORM_MODES.view) || (propsSettings?.props?.values?.dispositionType?.id === 'None')
                                    //     || propsSettings?.props?.values?.dispositionType === null}
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="dncEnabled"
                                    label="Apply DNC Regulation"
                                    labelPosition="end"
                                />
                            </Grid>
                        }
                        {/* {checkRoles("ROLE_DIDMasking") &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="didMasking"
                                    label="Apply DID Masking"
                                    labelPosition="end"
                                />
                            </Grid>
                        } */}

                        {/* {(propsSettings?.props?.values?.campaignType === 'Progressive' || propsSettings?.props?.values?.campaignType === 'IVR') && ( */}
                        {(isProgressiveOrIVR && !propsSettings?.props?.values?.customerFirst && !propsSettings?.props?.values?.acwNac && !propsSettings?.props?.values?.offLineMode) && (
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view || propsSettings?.props?.values?.acwNac === true
                                        || propsSettings?.props?.values?.customerFirst === true}
                                    name="holdAgent"
                                    label="Dial by holding agent"
                                    labelPosition="end"
                                />
                            </Grid>
                        )}
                        {/* {propsSettings?.props?.values?.campaignType === 'Progressive' && ( */}
                        {propsSettings?.props?.values?.campaignType === 'Progressive' && propsSettings?.props?.values?.dialMethod === 'Nonagentwise' && (
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="offLineMode"
                                    label="OffLine Mode"
                                    labelPosition="end"
                                />
                            </Grid>
                        )}
                    </Grid>
                </Stack>
            )
        }

        const [retryConfiguration, setRetryConfiguration] = useState(false);

        const openRetryDialogue = () => {
            setRetryConfiguration(true);
        }

        // const closeRetryDialogue = (formikData) => {
        //     setRetryConfiguration(false);
        //     if (formikData.retryConditions !== null) {
        //         formikData.retryRules = true;
        //     }
        // }

        const CheckConditionsForRetry = (props) => {
            let flag = 0;
            // let dialFlag = 0;
            // let dispFlag = 0;

            if (props?.values?.perDayRetries === null || props?.values?.noOfDaysToRetry === null || props?.values?.perDayRetries === "" || props?.values?.noOfDaysToRetry === "") {
                flag = 1;
                showErrorNotification("Please check the Mandatory Parameters in Retry Configurations");
                return;
            }
            if (props?.values?.perDayRetries < 1 || props?.values?.perDayRetries > 20) {
                flag = 1;
                showErrorNotification("Max no of tries per day should be a digit and between 1 and 20.");
                return;
            }
            if (props?.values?.noOfDaysToRetry < 1 || props?.values?.noOfDaysToRetry > 20) {
                flag = 1;
                showErrorNotification("Max no of days to Retry should be a digit and between 1 and 20.");
                return;
            }

            //:::::::::::::::This to check duplicate values :::::::::::::::::::::::::://
            // let uniqueCustomerStatusValues = [];
            // let uniquedispositionsValues = [];

            // props?.values?.retryConditions?.forEach((retryItem, retryIndex) => {
            //     retryItem?.parameters?.forEach((paramItem, paraIndex) => {
            //         if (paramItem?.type === "Customer Status") {
            //             paramItem?.value?.forEach((subValue) => {
            //                 const isDuplicate = uniqueCustomerStatusValues?.some((existingValue) => existingValue.id === subValue.id);
            //                 if (isDuplicate) {
            //                     dialFlag = 1;
            //                     showErrorNotification(`Duplicate (${subValue.label}) is there at index ${retryIndex + 1} for Customer Status`);
            //                 } else {
            //                     uniqueCustomerStatusValues.push(subValue);
            //                 }
            //             });
            //         }
            //         if (paramItem?.type === "Dispositions") {
            //             paramItem?.value?.forEach((subValue) => {
            //                 const isDuplicate = uniquedispositionsValues?.some((existingValue) => existingValue.id === subValue.id);
            //                 if (isDuplicate) {
            //                     dispFlag = 1;
            //                     showErrorNotification(`Duplicate (${subValue.reason}) is there at index ${retryIndex + 1} for Dispositions`);
            //                 } else {
            //                     uniquedispositionsValues.push(subValue);
            //                 }
            //             });
            //         }
            //     });
            // });

            // if (dialFlag === 1 || dispFlag === 1) {
            //     return;
            // }
            //:::::::::::::::This to check duplicate values :::::::::::::::::::::::::://

            props?.values?.retryConditions?.map((retryItem, retryIndex) => {
                retryItem?.parameters?.map((paramItem, paraIndex) => {
                    if (paramItem?.type !== "" || paramItem?.type !== " ") {
                        if (paramItem?.value?.length === 0) {
                            flag = 1;
                            showErrorNotification(`Please select value in Retry Configuration for Case ${retryIndex + 1} for parameter ${paramItem.type}`);
                            return null;
                        }
                    }
                    return null;
                })

                retryItem?.config?.map((configitem, configIndex) => {
                    if (configitem?.intervalDays === "" || configitem?.intervalDays === " " || configitem?.intervalDays < 0 || configitem?.intervalDays > 20) {
                        flag = 1;
                        showErrorNotification(`Retry Interval Days in retry Configuration Case ${retryIndex + 1} cannot be empty and should be between 0 and 20`);
                        return null;
                    }
                    if (configitem.intervalHours === "" || configitem.intervalHours === " " || configitem?.intervalHours < 0 || configitem?.intervalHours > 23) {
                        flag = 1;
                        showErrorNotification(`Retry Interval Hours in retry Configuration Case ${retryIndex + 1} cannot be empty and should be between 0 and 23`);
                        return null;
                    }
                    if (configitem.intervalMinutes === "" || configitem.intervalMinutes === " " || configitem?.intervalMinutes < 0 || configitem?.intervalMinutes > 59) {
                        flag = 1;
                        showErrorNotification(`Retry Interval Minutes in retry Configuration Case ${retryIndex + 1} cannot be empty and should be between 0 and 59`);
                        return null;
                    }
                    if (configitem.intervalDays === 0 && configitem.intervalHours === 0 && configitem.intervalMinutes === 0) {
                        flag = 1;
                        showErrorNotification(`All values in Retry Interval ${configIndex + 1}${configIndex === 0 ? 'st' : configIndex === 1 ? 'nd' : configIndex === 2 ? 'rd' : 'th'} for Case ${retryIndex + 1} cannot be 0`);
                        return null;
                    }
                    return null;
                })
                return null;
            })
            if (flag === 1) {
                return;
            } else {
                props.values.retryRules = true;
                setRetryConfiguration(false);
            }
        }

        function AddButtonInRetry(props) {

            return (
                <div style={{ backgroundColor: '#FFFFFF', borderRadius: '8px', minHeight: '22.9375rem', marginTop: '15px' }}>
                    <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                        <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: '-15px' }} />
                    </span>
                    <div style={{ marginTop: '20px', fontSize: '18px', display: 'flex', color: '#212121' }}>
                        <label>Retry Configuration</label>
                    </div>
                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Tooltip title="Max no of tries per day cannot be empty and should be a digit and between 1 and 20.">
                            <Grid item xs={6} >
                                <OZInput
                                    name="perDayRetries"
                                    label="Max No. of tries per Day *"
                                    placeholder="Eg: 2"
                                    type='number'
                                />
                            </Grid>
                        </Tooltip>
                        <Tooltip title="Max no of days to Retry cannot be empty and should be a digit and between 1 and 20.">
                            <Grid item xs={6} >
                                <OZInput
                                    name="noOfDaysToRetry"
                                    label="Max No. of Days to Retry *"
                                    placeholder="Eg: 2"
                                    type='number'
                                />
                            </Grid>
                        </Tooltip>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} >
                            <OZFCheckbox
                                name='retryFirst'
                                label={<label style={{ fontWeight: '400', fontSize: '14px', color: '#212121' }}>Prioritize Schedule Retries</label>}
                                labelPosition="end"
                            />
                        </Grid>
                    </Grid>
                    <div style={{ fontSize: '12px', display: 'flex', color: '#99A0A8', fontWeight: '400', marginTop: '-10px' }}>
                        <label>Note: Contacts that haven't been reached yet will be tried First after the scheduled retries</label>
                    </div>
                    <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                        <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: '12px' }} />
                    </span>
                    <DispositionRetryConfig
                        // dispositions={dispositonsOption}
                        dispositions={props?.formikProps?.values?.dispositions ? props?.formikProps?.values?.dispositions : []}
                        dialStatusData={dialStatusData}
                        formikProps={props?.formikProps}
                        onCancel={() => {
                            setRetryConfiguration(false);
                            props.formikProps.values.retryConditions = props.formikProps.initialValues.retryConditions;
                            props.onCancelAddButtonRetry();
                        }}
                        onSave={() => CheckConditionsForRetry(props?.formikProps)}
                    />
                    {/* <RetryConfig
                        dialStatusData={dialStatusData}
                        formikProps={props?.formikProps}
                        onCancel={() => {
                            setRetryConfiguration(false);
                            props.formikProps.values.retryConditions = props.formikProps.initialValues.retryConditions;
                            props.onCancelAddButtonRetry();
                        }}
                        onSave={() => CheckConditionsForRetry(props?.formikProps)}
                    /> */}
                </div>
            )
        }

        const Advanced = (props) => {
            const advancedProps = props?.props;
            return (
                <Tooltip title={mode === ADMIN_FORM_MODES.edit ? advancedProps?.position !== "RUNNING" ? retryConfiguration === false ? "For Retry Conditions Please click on Advanced" : retryConfiguration === true ? "" : "" : "Advanced Retry Conditions is accessible when Campaign is not Running" : mode === ADMIN_FORM_MODES.add ? "Advanced Retry Conditions is accessible while Editting the Campaign" : ""}>
                    <div style={{ marginBottom: '-30px', marginTop: '-10px' }}>
                        <OZFCheckbox
                            disabled={advancedProps?.position === "RUNNING" || mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.add}
                            name='retryRules'
                            label={
                                <Button
                                    disabled={advancedProps?.position === "RUNNING" || mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.add}
                                    onClick={() => { openRetryDialogue(); props.formikProps.values.ruleNot = 1 }}
                                    style={advancedButtonStyle}
                                >
                                    {/* Advanced */}
                                    <Link underline="always" color="inherit">Advanced</Link>
                                </Button>
                            }
                            labelPosition='end'
                        />
                        {retryConfiguration &&
                            <OZDialog
                                hideActions
                                hideCross
                                title="No. of Tries (Advanced functionality)"
                                open={retryConfiguration}
                            // onClose={() => {
                            //     closeRetryDialogue(props?.formikProps?.values);
                            //     if (props.formikProps.values.retryRules === false) {
                            //         props.formikProps.values.retryRules = true;
                            //     }
                            // }}
                            // onSave={() => { props.formikProps.values.retryRules = true; setRetryConfiguration(false) }}
                            >
                                <AddButtonInRetry
                                    formikProps={props.formikProps}
                                    onCancelAddButtonRetry={() => {
                                        props.formikProps.values.noOfDaysToRetry = props.formikProps.initialValues.noOfDaysToRetry;
                                        props.formikProps.values.perDayRetries = props.formikProps.initialValues.perDayRetries;
                                    }}
                                />
                            </OZDialog>
                        }
                    </div >
                </Tooltip>
            )
        }

        //mappign form file open here 

        const [dataMappingFileForm, setDataMappingFileForm] = useState(false);

        const openCreateNewMappingForm = () => {
            setDataMappingFileForm(true);
        }

        const closeDataMappingForm = () => {
            setDataMappingFileForm(false);
        }

        //mappign form file closees here 

        const [leaveCampaign, setLeaveCampaign] = useState(false);

        const LeaveCampaign = (props) => {
            const closeLeaveCampaign = () => {
                setOpenLeaveCampaign(false);
                props.onClose();
            }
            const [openLeaveCampaign, setOpenLeaveCampaign] = useState(true);

            return (
                <div>
                    <LockedAndStatusConfirmationDialog
                        hideActions
                        title={
                            <div>
                                <div style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                                    <label>Leave Campaign?</label>
                                </div>
                            </div>
                        }
                        content={
                            <>
                                <div style={{ fontSize: '0.75rem', color: '#000000', fontWeight: '400' }}>
                                    Your entries will be lost when you leave this page.
                                </div>
                                <div style={{ fontSize: '0.75rem', color: '#000000', fontWeight: '400' }}>
                                    Are you sure you want leave the campaign ?
                                </div>
                                <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 1 }} justifyContent='end'>
                                    <Grid item >
                                        <Button style={formButtonStyle} variant='outlined' type="submit" onClick={closeLeaveCampaign}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item >
                                        <Button style={formButtonStyle} onClick={closeCampaignOutboundForm} variant="contained" type="submit">
                                            Yes, Leave Campaign
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        open={openLeaveCampaign}
                        onClose={closeLeaveCampaign}
                    >
                    </LockedAndStatusConfirmationDialog>
                </div>
            )
        }

        const openLeaveCampaignForm = () => {
            setLeaveCampaign(true);
        }

        const closeLeaveCampaignForm = () => {
            setLeaveCampaign(false);
        }

        const BasicSettings = (props) => {
            const basicProps = props?.props;
            let didSetting = null
            const [previousDid, setPreviousDid] = useState(basicProps?.values?.did);
            const [previousFallBackDid, setPreviousFallBackDid] = useState(basicProps?.values?.fallbackDID);
            if (basicProps.values.did !== previousDid) {
                basicProps.values.dynamicDID = []
            }
            if (basicProps.values.fallbackDID !== previousFallBackDid) {
                basicProps.values.fallbackDynamicDID = []
            }
            // if (basicProps.values.fallbackDID === 'None') {
            //     basicProps.values.fallbackDID = 'None';
            // }
            // console.log(basicProps);
            useEffect(() => {
                setPreviousDid(basicProps?.values?.did);
                setPreviousFallBackDid(basicProps?.values?.fallbackDID)
            }, [basicProps?.values?.did, basicProps?.values?.fallbackDID]);


            const targetObjectDid = (basicProps?.values?.did !== null && basicProps?.values?.did !== undefined)
                ? secondaryDidOptionFullList?.find(item => item.DID === basicProps?.values?.did)
                : "";

            const targetObjectFallBackDid = (basicProps?.values?.fallbackDID !== null && basicProps?.values?.fallbackDID !== undefined)
                ? secondaryDidOptionFullList?.find(item => item.DID === basicProps?.values?.fallbackDID)
                : "";

            const targetDIdTrunkName = (targetObjectDid !== null && targetObjectDid !== undefined)
                ? targetObjectDid?.TrunkName
                : [];

            const targetFallbackDIdTrunkName = (targetObjectFallBackDid !== null && targetObjectFallBackDid !== undefined)
                ? targetObjectFallBackDid?.TrunkName
                : [];

            const filteredDidArray = (targetDIdTrunkName !== null && targetDIdTrunkName !== undefined)
                ? secondaryDidOptionFullList?.filter(item => item.TrunkName === targetDIdTrunkName)
                : [];

            const filteredFallbackDidArray = (targetFallbackDIdTrunkName !== null && targetFallbackDIdTrunkName !== undefined)
                ? secondaryDidOptionFullList?.filter(item => item.TrunkName === targetFallbackDIdTrunkName)
                : [];

            const filteredDynamicDid = (filteredDidArray !== "" && filteredDidArray !== null && filteredDidArray !== undefined)
                ? filteredDidArray?.map(item => ({
                    id: item.DID,
                    dynamicDID: item.DID
                }))
                : [];

            const filteredDynamicFallbackDid = (filteredFallbackDidArray !== "" && filteredFallbackDidArray !== null && filteredFallbackDidArray !== undefined)
                ? filteredFallbackDidArray?.map(item => ({
                    id: item.DID,
                    fallbackDynamicDID: item.DID
                }))
                : [];

            if (settingsData?.length > 0) {
                settingsData?.map((item) => {
                    if (item?.ParameterCode === "DYNAMIC_DID")
                        if (item?.ParameterValue === null) {
                            didSetting = item?.DefaultValue;
                            return didSetting
                        }
                        else {
                            didSetting = item?.ParameterValue
                            return didSetting
                        }

                })
            }

            function getCampaignTypeOptions(role, campaignTypeProps) {
                if (role === true) {
                    if (campaignTypeProps?.campaignType === 'Preview') {
                        if (campaignTypeProps?.dialMethod !== 'Nonagentwise') {
                            return ['Preview', 'Progressive']
                        } else if (campaignTypeProps?.dialMethod === 'Nonagentwise') {
                            return ['Preview', 'Progressive', 'IVR', 'Predictive']
                        }
                    }
                    else if (campaignTypeProps?.campaignType === 'Progressive') {
                        if (campaignTypeProps?.dialMethod !== 'Nonagentwise') {
                            return ['Preview', 'Progressive']
                        } else if (campaignTypeProps?.dialMethod === 'Nonagentwise') {
                            return ['Preview', 'Progressive', 'IVR', 'Predictive']
                        }
                    }
                    else if (campaignTypeProps?.campaignType === 'Predictive' || campaignTypeProps?.campaignType === 'IVR') {
                        return ['Preview', 'Progressive', 'IVR', 'Predictive']
                    }
                } else {
                    return ['Preview', 'Progressive', 'IVR']
                }
            }

            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>Basic Settings</Typography>

                        {mode !== ADMIN_FORM_MODES.add && (
                            <div>
                                <Grid container direction='row'>
                                    {/* {authPage(AdminPages.OUTBOUND_CAMP_EDIT) && (
                                        <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                            <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit Basic Settings' : 'Discard Edit'}</label>
                                        </div>
                                    )} */}
                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                        <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                            <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit Basic Settings' : 'Discard Edit'}</label>
                                        </div>
                                    )}
                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                    <>
                                    {
                                        <PopupState variant="popover" popupId="demo-popup-menu-OutboundCampaign" >
                                            {(popupState) => (
                                                <React.Fragment>
                                                    <div style={copyStyle} onClick={openCopyCampaign}>
                                                        <label style={{ cursor: "pointer" }}>Copy Campaign</label>
                                                    </div>
                                                    {copyCampaign && (
                                                        <div>
                                                            <CopyCampaignForm
                                                                onClose={closeCopyCampaignForm}
                                                                id={props?.props?.values?.campaignId}
                                                                type={props?.props?.values?.campaignType}
                                                                campaignName={props?.props?.values?.campaignName}
                                                                updateCopyCampaignOutbound={closeCopyCampaignForm}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    }
                                    </>
                                    )}
                                </Grid>
                            </div>
                        )}
                    </div>
                    <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} >
                            <OZInput
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="campaignName"
                                label="Campaign Name *"
                                placeholder="Campaign Name"
                            />
                        </Grid>
                        {(mode === ADMIN_FORM_MODES.add || mode === ADMIN_FORM_MODES.view) &&
                            <Grid item xs={6} >
                                <OZSelect
                                    disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position === "RUNNING"}
                                    name="campaignType"
                                    label="Campaign Type *"
                                    options={checkRoles("ROLE_PREDICTIVE") ? ['Preview', 'Progressive', 'IVR', 'Predictive'] : ['Preview', 'Progressive', 'IVR']}
                                    placeholder="Please Select"
                                />
                            </Grid>
                        }
                        {(mode === ADMIN_FORM_MODES.edit) &&
                            <Grid item xs={6} >
                                <OZSelect
                                    disabled={basicProps?.values?.position === "RUNNING"}
                                    name="campaignType"
                                    label="Campaign Type *"
                                    options={getCampaignTypeOptions(checkRoles("ROLE_PREDICTIVE"), basicProps?.values)}
                                    placeholder="Please Select"
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        {localStorage.getItem('parentUser') === 'null' &&
                            (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CAMPAIGNSUB")) &&
                            <Grid item xs={6} >
                                <CustomAutoComplete
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    mode='Add'
                                    name="users"
                                    limitTags={2}
                                    placeholder={"Please Select"}
                                    Datas={subUserOption}
                                    labelHeader='Sub Admin Assigned '
                                    optionLabelName='username'
                                    showCheckBox={true}
                                    multiple={true}
                                    showAvatar={true}
                                    showAvatarInChip
                                    showselectall="true"
                                    popupIcon={null}
                                />
                            </Grid>}
                        <Grid item xs={6} >
                            <OZSelect
                                disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position === "RUNNING"}
                                name="did"
                                label="DID *"
                                options={secondaryDidOption}
                                placeholder="DID No."
                            />
                        </Grid>
                    </Grid>
                    {basicProps?.values?.campaignType === 'Predictive' && (
                        <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="ruleNac"
                                    label="Pacing Ratio(Calls:Agent)*"
                                    placeholder="1:1"
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="dropRatio"
                                    label="Max Drop Ratio (%)*"
                                    placeholder="Max Drop Ratio (%)"
                                />
                            </Grid>
                        </Grid>
                    )}
                    {basicProps?.values?.campaignType === 'IVR' && (
                        <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                            <Grid item xs={6} >
                                <OZSelect
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="ivrFlow"
                                    label="IVR Flow*"
                                    optionLabel='flowName'
                                    options={ivrFlowOption}
                                    placeholder="Please Select IVR Flow"
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="dataLimit"
                                    label="Max Concurrent Calls*"
                                    placeholder="Max Concurrent Calls"
                                />
                            </Grid>
                            {basicProps?.values?.ivrFlow?.flowName === "Other" &&
                                <Grid item xs={6} >
                                    <OZInput
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="appURL"
                                        label="App. URL*"
                                        placeholder="App. URL"
                                    />
                                </Grid>
                            }
                        </Grid>
                    )}
                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} >
                            {checkRoles("ROLE_FallBackDID") &&
                                <OZSelect
                                    disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position === "RUNNING"}
                                    name="fallbackDID"
                                    label="FallBack DID"
                                    options={secondaryFallbackDidOption}
                                    placeholder="FallBack DID"
                                />
                            }
                        </Grid>
                        {(basicProps?.values?.campaignType === 'Preview' || basicProps?.values?.campaignType === 'Progressive') && (
                            <Grid item xs={6} >
                                <OZSelect
                                    disabled={mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit}
                                    name="dialMethod"
                                    label="Dial Method *"
                                    showCheckbox={true}
                                    options={['Nonagentwise', 'Agentwise', 'Skillwise']}
                                    placeholder="Nonagentwise"
                                />
                            </Grid>
                        )}
                        {didSetting === "true" && <Grid item xs={6} >
                            <CustomAutoComplete
                                disabled={mode === ADMIN_FORM_MODES.view}
                                mode='Add'
                                limitTags={3}
                                name="dynamicDID"
                                labelHeader='Dynamic DID'
                                placeholder={"Search Dynamic DID"}
                                Datas={filteredDynamicDid}
                                optionLabelName='dynamicDID'
                                showCheckBox={true}
                                multiple={true}
                                showselectall="true"
                                popupIcon={null}
                            />
                        </Grid>}
                        {didSetting === "true" &&
                            <Grid item xs={6} >
                                <CustomAutoComplete
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    mode='Add'
                                    limitTags={3}
                                    name="fallbackDynamicDID"
                                    labelHeader='Dynamic Fallback DID'
                                    placeholder={"Search Dynamic Fallback DID"}
                                    Datas={filteredDynamicFallbackDid}
                                    optionLabelName='fallbackDynamicDID'
                                    showCheckBox={true}
                                    multiple={true}
                                    showselectall="true"
                                    popupIcon={null}
                                />
                            </Grid>}
                    </Grid>

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.add) ? { marginTop: 9, marginBottom: '10px' } : { marginTop: 9 }} justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                Cancel
                            </Button>
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view}
                                // onClick={(mode === ADMIN_FORM_MODES.add) ? handleNext : null}
                                onClick={() => {
                                    if (mode === ADMIN_FORM_MODES.add) {
                                        handleNext();
                                    } else if (mode === ADMIN_FORM_MODES.edit) {
                                        if (!basicProps.isValid) {
                                            basicProps.handleSubmit();
                                            showErrorNotification("Please Check the values and fill all the mandatory field");
                                        }
                                    }
                                }}
                                variant="contained" type="submit" form="campaignOutbound-form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack >
            )
        }

        const MappingFile = (props) => {
            // const mappingProps = props?.props;
            const [previewDataOption, setPreviewDataOption] = useState([]);

            useEffect(() => {
                getPreviewDataOptions()
            }, []);
            const onDownload = () => {
                const link = document.createElement("a");
                link.download = `map_upload_sample.xlsx`;
                link.href = `${process.env.PUBLIC_URL}/sample_files/map_upload_sample.xlsx`;
                link.click();
            };

            async function getPreviewDataOptions() {
                try {
                    getPreviewDataDropdown().then(resp => {
                        setPreviewDataOption(resp.Data);
                    }).catch(e => {
                        setPreviewDataOption([{ id: '1', name: 'No Internet' }]);
                        showErrorNotification('No Preview Data, Check your Internet Connection');
                    })
                }
                catch (e) {
                    showErrorNotification(JSON.stringify(e));
                }
            }
            return (
                <Grid item xs={6} style={{ marginTop: '3px' }}>
                    <div style={{ display: 'flex', alignContent: 'center', paddingBottom: '4px' }}>
                        <Typography sx={{
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '12px',
                            color: (mode === ADMIN_FORM_MODES.add) ? '#212121' : '#99A0A8',
                        }} flexGrow={1} >Mapping Name*</Typography>
                        <div style={{ opacity: '0.5', cursor: "pointer" }} onClick={onDownload}>Download Sample</div>
                    </div>
                    <CustomAutoComplete
                        disabled={mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit}
                        name='previewDataMap'
                        optionLabelName='name'
                        mode={'Add'}
                        showBottomButton
                        buttonName='Create New Data Mapping'
                        bottomButtonFunction={openCreateNewMappingForm}
                        Datas={previewDataOption}
                        placeholder="Please Select"
                        multiple={false}
                    />
                    <PopupState variant="popover" popupId="demo-popup-menu" >
                        {(popupState) => (
                            <React.Fragment>
                                {dataMappingFileForm && (newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                    <div>
                                        <NewDataMappingForm onClose={closeDataMappingForm} updateMappingForm={getPreviewDataOptions} />
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </PopupState>
                </Grid >
            )
        }

        const UploadFileComp = (props) => {

            const [fileObjsUploaded, setFileObjsUploaded] = useState(uploadedFiles?.length > 0 ? uploadedFiles : [])
            const maxSize = 25 * 1024 * 1024;

            function updateUploadedFiles(data, id) {
                id = 12345;
                uploadedFiles = data;
            }

            function checkVariant(message, variant) {
                if (variant === "success") {
                    showSuccessNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
                }
                else if (variant === "error") {
                    showErrorNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
                }
                else {
                    showInfoNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
                }
            }

            const onDownload = () => {
                const link = document.createElement("a");
                link.download = `data_upload_sample.xlsx`;
                link.href = `${process.env.PUBLIC_URL}/sample_files/data_upload_sample.xlsx`;
                link.click();
            };
            function UploadIcon() {
                return (
                    <div style={{ paddingTop: '14px', float: 'right', paddingRight: '15px' }}>
                        <img alt='edit' src={`${process.env.PUBLIC_URL}/icons/upload_data.svg`}></img>
                    </div>
                )
            }
            // let clone = structuredClone(props?.props);
            // const uploadPropsId = clone?.campaignId;
            return (
                <Grid item xs={6}>
                    <div style={{ marginBottom: '4px' }}>
                        <label style={{ height: '10px', width: '10px' }}>Upload File <span style={{ opacity: '0.5', float: 'right', cursor: "pointer" }} onClick={onDownload}>Download Sample</span></label>
                    </div>
                    <div style={{ backgroundColor: 'white' }}>
                        <DropzoneAreaBase
                            maxFileSize={maxSize}
                            Icon={UploadIcon}
                            initialFiles={uploadedFiles}
                            fileObjects={fileObjsUploaded}
                            dropzoneParagraphClass="campaign-dropzone-text"
                            dropzoneClass="campaign-drop-zone-area"
                            previewGridProps={{
                                item: {
                                    position: 'absolute',
                                    display: 'flex',
                                    flexWrap: "wrap",
                                    alignItems: 'end',
                                    // height: '10px',
                                    marginTop: "-40px",
                                },
                                container: { spacing: 1, direction: 'row' }
                            }}
                            dropzoneText={" "}
                            // acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                            acceptedFiles={[ 
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
                                'text/csv', 
                                'application/csv' 
                            ]}
                            onDelete={(fileObj) => { console.log('Removed File:', fileObj); uploadedFiles = []; setFileObjsUploaded([]) }}
                            onAdd={(files) => {
                                if (files?.length > 0) {
                                    setFileObjsUploaded([files[0]])
                                }
                                updateUploadedFiles(files);
                            }}
                            onAlert={(message, variant) => { checkVariant(message, variant); }}
                            filesLimit={1}
                            useChipsForPreview={true}
                            previewText="Selected files"
                            previewChipProps={{ marginLeft: "12px" }}
                            showAlerts={false}
                        />
                    </div>
                </Grid>
            )
        }

        const RunTimeComp = connect(({ props, formik }) => {
            const startTime = 'runtimeStart'
            const endTime = 'runtimeEnd'

            const errorStart = getIn(formik.errors, startTime);
            const touchStart = getIn(formik.touched, startTime);

            const errorEnd = getIn(formik.errors, endTime);
            const touchEnd = getIn(formik.touched, endTime);

            const handleTimeChangeStart = (value) => {
                formik.setFieldValue(startTime, value ? moment(value).format("HH:mm:ss") : '');
            };

            const handleTimeChangeEnd = (value) => {
                formik.setFieldValue(endTime, value ? moment(value).format("HH:mm:ss") : '');
            };

            const handleBlurStart = () => {
                formik.setFieldTouched(startTime, true);
            };

            const handleBlurEnd = () => {
                formik.setFieldTouched(endTime, true);
            };

            // const handleBlurEnd = () => {
            //     formik.setFieldTouched(endTime, true);
            // };
        
            return (
                <FormControl error={(touchStart && !!errorStart) || (touchEnd && !!errorEnd)}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography
                                fontSize='12px'
                                fontWeight={400}
                                lineHeight='14px'
                                marginBottom={'6px'}
                                marginLeft='1px'
                                color="#212121"
                            >
                                Run Time*
                            </Typography>
                            <TimePicker
                                format="HH:mm:ss"
                                disabled={mode === ADMIN_FORM_MODES.view || props?.position === "RUNNING" || props?.dncEnabled}
                                value={props?.props?.dncEnabled ? moment('00:00:00', 'HH:mm:ss') : formik.values[startTime] ? moment(formik.values[startTime], 'HH:mm:ss') : null}
                                onChange={handleTimeChangeStart}
                                onBlur={handleBlurStart}
                                placeholder='Start Time'
                                popupStyle={{
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    zIndex: '9999',
                                }}
                                showSecond={true}
                                use12Hours={false}
                                name={startTime}
                                id={startTime}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: '20px' }} >
                            <TimePicker
                                format="HH:mm:ss"
                                disabled={mode === ADMIN_FORM_MODES.view || props?.position === "RUNNING" || props?.dncEnabled}
                                value={props?.props?.dncEnabled ? moment('00:00:00', 'HH:mm:ss') : formik.values[endTime] ? moment(formik.values[endTime], 'HH:mm:ss') : null}
                                onChange={handleTimeChangeEnd}
                                onBlur={handleBlurEnd}
                                placeholder='End Time'
                                popupStyle={{
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    zIndex: '9999',
                                }}
                                showSecond={true}
                                use12Hours={false}
                                name={endTime}
                                id={endTime}
                            />
                        </Grid>
                    </Grid>
                    <FormHelperText color="#E56B6B ! important">{(touchStart && errorStart) || (touchEnd && errorEnd)}</FormHelperText>
                </FormControl>
            );
        });

        const GeneralInformation = (props) => {
            const propsGeneral = props?.props;
            let selectedPrevList = [];
            const [prevCustomerFirst, setPrevCustumerFirst] = useState(propsGeneral?.values?.customerFirst);
            let selectedPrev = propsGeneral?.values?.previewDataMap?.value;
            if (selectedPrev) {
                selectedPrevList = selectedPrev.split(',');
            }

            if (propsGeneral?.values?.customerFirst !== prevCustomerFirst && propsGeneral?.values?.customerFirst === false) {
                propsGeneral.values.amdActive = false;
                propsGeneral.values.amdType = "";
                propsGeneral.values.amdUrl = "";
            }

            useEffect(() => {
                setPrevCustumerFirst(propsGeneral?.values?.customerFirst);
            }, [propsGeneral?.values?.customerFirst]);

            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>General Information</Typography>

                        {mode !== ADMIN_FORM_MODES.add && (
                            <div>
                                {/* {authPage(AdminPages.OUTBOUND_CAMP_EDIT) && (
                                    <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                    </div>

                                )} */}
                                {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                    <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                    <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                </div>

                                )}
                            </div>
                        )}
                    </div>
                    <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        {(checkRoles("ROLE_CBK_CAMPAIGN") || checkRoles("ROLE_ADMIN")) &&
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="callbackUrl"
                                    label="URL to Push"
                                    placeholder="URL to Push"
                                />
                            </Grid>
                        }
                        <Grid item xs={6} >
                            <OZSelect
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name='popUrlAt'
                                label="Hit ScreenPop URL at"
                                optionLabel='id'
                                options={[{ id: 'Client Side', value: 0 }, { id: 'Server Side', value: 1 }, { id: 'Client&Server', value: 2 }, { id: 'Client Side OnBusy', value: 3 }, { id: 'Plugin', value: 4 }, { id: 'Server Side OnBusy', value: 5 }]}
                                placeholder="Please Select"
                            />
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        {propsGeneral?.values?.popUrlAt?.id !== 'Plugin' && (
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="screenPopUrl"
                                    label="ScreenPop URL"
                                    placeholder="URL"
                                />
                            </Grid>
                        )}
                        {propsGeneral?.values?.popUrlAt?.id === 'Plugin' && (
                            <Grid item xs={6} >
                                <OZSelect
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name='screenPopUrlInBound'
                                    label="Plugin Name *"
                                    optionLabel='name'
                                    options={pluginOption}
                                    placeholder="Please Select"
                                />
                            </Grid>
                        )}
                        <Grid item xs={6} >
                            {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CALLPREFIX")) &&
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="callPrefix"
                                    label="Call Prefix"
                                    placeholder="Call Prefix"
                                // type='number'
                                />
                            }
                        </Grid>
                    </Grid>
                    {/* <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        {propsGeneral?.values?.popUrlAt?.id === 'Plugin' && (
                            <Grid item xs={6} >
                                <OZSelect
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name='screenPopUrlInBound'
                                    label="Plugin Name *"
                                    optionLabel='name'
                                    options={pluginOption}
                                    placeholder="Please Select"
                                />
                            </Grid>
                        )}
                    </Grid> */}
                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} >
                            <OZSelect
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="dispositionType"
                                label="Disposition Type *"
                                optionLabel='id'
                                options={[{ id: 'None', value: -1 }, { id: 'API', value: -2 }, { id: 'Toolbar', value: 1 }]}
                                placeholder="None"
                            />
                        </Grid>
                        {propsGeneral?.values?.dispositionType?.id !== 'None' &&
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={(mode === ADMIN_FORM_MODES.view) || (propsGeneral?.values?.dispositionType?.id === 'None') || (propsGeneral?.values?.dispositionType === null)}
                                    name='sla'
                                    label={
                                        <>
                                            <label>{(propsGeneral?.values?.dispositionType?.id === 'None' || (propsGeneral?.values?.dispositionType === null)) ? 'Wrapup Time (In Seconds)' : 'Wrapup Time (In Seconds)*'}</label>
                                            <span style={{ opacity: '0.5', marginLeft: '10px' }} >(Note:value 0 means infinite time)</span>
                                        </>
                                    }
                                    placeholder="Time in seconds"
                                    type='number'
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} >
                            <CustomAutoComplete
                                disabled={mode === ADMIN_FORM_MODES.view}
                                mode='Add'
                                limitTags={2}
                                name="dispositions"
                                labelHeader='Dispositions *'
                                placeholder={"Please Select"}
                                Datas={dispositonsOption}
                                optionLabelName='reason'
                                showCheckBox={true}
                                multiple={true}
                                showselectall="true"
                                popupIcon={null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <div style={{ display: 'flex', alignContent: 'center' }}>
                                    <Typography sx={{
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: '#212121',
                                    }} flexGrow={1} >No.Of Tries*</Typography>
                                    {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_RETRYRULES")) && <Advanced props={propsGeneral?.values} formikProps={props.formikProps} />}
                                </div>
                                <div style={{ marginTop: '2px' }}>
                                    <OZInput
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='ruleNot'
                                        placeholder='Eg: 1'
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} >
                            <OZSelect
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="blockNumber"
                                label="Block Number"
                                optionLabel='id'
                                options={[{ id: 'None', value: 0 }, { id: 'Block', value: 1 }, { id: 'Allow', value: 2 }]}
                                placeholder="None"
                            />
                        </Grid>
                        {propsGeneral?.values?.blockNumber?.id !== 'None' &&
                            <Grid item xs={6} >
                                <CustomAutoComplete
                                    disabled={(mode === ADMIN_FORM_MODES.view) || (propsGeneral?.values?.blockNumber?.id === 'None') || (propsGeneral?.values?.blockNumber === null)}
                                    mode='Add'
                                    limitTags={2}
                                    name="blockNumberGroups"
                                    labelHeader={propsGeneral?.values?.blockNumber?.id === 'None' ? 'Block Number Group' : 'Block Number Group *'}
                                    placeholder={"Please Select"}
                                    Datas={blockNumberGroupOption}
                                    optionLabelName='name'
                                    showCheckBox={true}
                                    multiple={true}
                                    showselectall="true"
                                    popupIcon={null}
                                />
                            </Grid>
                        }

                        {/* {propsGeneral?.values?.allowedManual === true && */}
                        <Grid item xs={6} >
                            <OZSelect
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="recOn"
                                label="Record *"
                                optionLabel='id'
                                options={[{ id: 'Record the call in Full', value: 0 }, { id: 'Record only the conversation', value: 1 }, { id: 'Do not record calls', value: 2 }]}
                                placeholder="Please Select"
                            />
                        </Grid>
                        {(checkRoles("ROLE_OBD_RESTRICTION")) &&
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="dialCount"
                                    label="Daily Outbound Limit per Number"
                                    placeholder="Daily Outbound Limit per Number"
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid style={{ marginTop: '4px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} style={{ display: 'flex', alignContent: 'center' }}  >
                            <RunTimeComp
                                formik={props}
                                props={propsGeneral?.values}
                            />
                        </Grid>
                        {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CAMP_PRIORITY")) &&
                            <Grid item xs={6}  >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="priority"
                                    label={<><label>Priority* </label><span style={{ opacity: '0.5' }}>(Higher the value, higher the priority)</span></>}
                                    placeholder=""
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid style={{ marginTop: '4px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >

                        <Grid item xs={6}  >
                            <OZInput
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="disclaimer"
                                label="Play Disclaimer URL"
                                placeholder="Play Disclaimer URL"
                            />
                        </Grid>

                    </Grid>
                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        {(propsGeneral?.values?.campaignType === "Progressive" || propsGeneral?.values?.campaignType === "IVR") &&
                            <Grid item xs={6} style={{ marginTop: '2px' }}>
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view || propsGeneral?.values?.campaignType === "Preview"}
                                    name="dialInterval"
                                    label={(propsGeneral?.values?.campaignType === 'IVR' || propsGeneral?.values?.campaignType === 'Progressive') ? 'Dial Interval*' : "Dial Interval"}
                                    placeholder="In Seconds"
                                />
                            </Grid>
                        }
                        {((propsGeneral?.values?.campaignType === "Progressive" || propsGeneral?.values?.campaignType === "IVR" || propsGeneral?.values?.campaignType === "Predictive") ||
                            (propsGeneral?.values?.campaignType === 'Preview' && (propsGeneral?.values?.customerFirst))) &&
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="customerRingingTime"
                                    // label={(propsGeneral?.values?.campaignType === 'Preview') ? "Customer Ringing Time" : "Customer Ringing Time*"}
                                    label="Customer Ringing Time*"
                                    placeholder="Customer ringing time"
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <MappingFile props={propsGeneral?.values} />
                        {mode === ADMIN_FORM_MODES.add && (newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                            <UploadFileComp props={propsGeneral?.values} />
                        )}
                    </Grid>

                    {propsGeneral?.values?.previewDataMap !== null && propsGeneral?.values?.previewDataMap?.name !== "Button" && (
                        <>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={12}>
                                    <div>
                                        <label style={{ height: '10px' }}>Mapping Columns</label>
                                    </div>
                                    <Grid>
                                        {selectedPrevList?.length > 0 && selectedPrevList?.map((value, index) => {
                                            if (selectedPrevList?.length === 1) {
                                                return < OZCustomToggleButton label={value} sx={{ ...tabMappingColumnButtonStyle, borderRadius: '8px 8px 8px 8px' }} value={value}>{value}</OZCustomToggleButton>
                                            }
                                            else if (index === 0) {
                                                return < OZCustomToggleButton label={value} sx={{ ...tabMappingColumnButtonStyle, borderRadius: '8px 0px 0px 8px', borderRight: 0 }} value={value}>{value}</OZCustomToggleButton>
                                            } else if (index === selectedPrevList?.length - 1) {
                                                return <OZCustomToggleButton label={value} sx={{ ...tabMappingColumnButtonStyle, borderRadius: '0px 8px 8px 0px' }} value={value}>{value}</OZCustomToggleButton>
                                            } else {
                                                return <OZCustomToggleButton label={value} sx={{ ...tabMappingColumnButtonStyle, borderRight: 0 }} value={value}>{value}</OZCustomToggleButton>
                                            }
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} style={{ marginTop: '2px' }}>
                            <CustomAutoComplete
                                disabled={mode === ADMIN_FORM_MODES.view}
                                mode='Add'
                                name="callWork"
                                limitTags={2}
                                placeholder={"Please Select"}
                                Datas={prePostOption}
                                labelHeader='Pre & Post Call Work'
                                optionLabelName='flowName'
                                showCheckBox={true}
                                multiple={true}
                                showselectall="true"
                            />
                        </Grid>
                        <Grid item xs={1} md={6} style={{ marginTop: '2px' }}>
                            <CustomAutoComplete
                                disabled={mode === ADMIN_FORM_MODES.view || position === "RUNNING"}
                                mode='Add'
                                name="holiday"
                                limitTags={2}
                                placeholder={"Please Select"}
                                Datas={holidaysList}
                                labelHeader='Holidays'
                                optionLabelName='holidayName'
                                showCheckBox={true}
                                multiple={true}
                                showselectall="true"
                            />
                        </Grid>
                    </Grid>

                    <CampaignSettings props={props} />

                    {checkRoles("ROLE_CAMPAIGNHOLDMUSIC") &&
                        <>
                            <div style={{ marginTop: '20px', fontSize: '18px', display: 'flex' }}>
                                <label>Music Files</label>
                            </div>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="holdMusic"
                                        label="On Hold"
                                        optionLabel='name'
                                        options={onHoldMusicOption}
                                        placeholder="Please Select"
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="transferMusic"
                                        label="On Transfer"
                                        optionLabel='name'
                                        options={onHoldMusicOption}
                                        placeholder="Please Select"
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                    {checkRoles("ROLE_AMD") &&
                        (((propsGeneral?.values?.campaignType === "Preview" || propsGeneral?.values?.campaignType === "Progressive") && propsGeneral?.values?.customerFirst) ||
                            (propsGeneral?.values?.campaignType === "Predictive" || propsGeneral?.values?.campaignType === "IVR")) &&
                        (
                            <div>
                                <Grid style={{ marginTop: '2px' }} container spacing={2} display='flex' direction={'row'} >
                                    <Grid item xs={6} >
                                        <OZFCheckbox
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name='amdActive'
                                            label='AMD Detection'
                                            labelPosition="end"
                                            labelSize='17px'
                                        />
                                    </Grid>
                                </Grid>
                                {propsGeneral?.values?.amdActive === true && (
                                    <div>
                                        <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                            <Grid item xs={6} >
                                                <OZSelect
                                                    disabled={mode === ADMIN_FORM_MODES.view}
                                                    name='amdType'
                                                    label='AMD Detected *'
                                                    optionLabel='label'
                                                    options={[{ id: 'PlayVoiceMessage', label: 'Play Voice Message' }, { id: "DisconnectAndAnswered", label: 'Disconnect and Answered' }, { id: "DisconnectAndUnAnswered", label: 'Disconnect and Unanswered' }]}
                                                    placeholder="AMD Detected"
                                                />
                                            </Grid>
                                            {propsGeneral?.values?.amdType?.id === "PlayVoiceMessage" &&
                                                <Grid item xs={6} >
                                                    <OZInput
                                                        disabled={mode === ADMIN_FORM_MODES.view}
                                                        name='amdUrl'
                                                        label='Voice Message URL *'
                                                        placeholder="Voice Message URL"
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </div>
                                )}
                            </div>
                        )
                    }

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.add) ? { marginTop: 9, marginBottom: '10px' } : { marginTop: 9 }} justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined">
                                Cancel
                            </Button>
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view}
                                //  onClick={(mode === ADMIN_FORM_MODES.add) ? handleNext : null} 
                                onClick={() => {
                                    if (mode === ADMIN_FORM_MODES.add) {
                                        handleNext();
                                    } else if (mode === ADMIN_FORM_MODES.edit) {
                                        if (!propsGeneral.isValid) {
                                            propsGeneral.handleSubmit();
                                            showErrorNotification("Please Check the values and fill all the mandatory field");
                                        }
                                    }
                                }}
                                variant="contained" type="submit" form="campaignOutbound-form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack >
            )
        }

        const Skills = (props) => {
            const propsSkills = props?.props;
            return (
                <Stack >
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>Skills *</Typography>

                        {mode !== ADMIN_FORM_MODES.add && (
                            <div>
                                {/* {authPage(AdminPages.OUTBOUND_CAMP_EDIT) && (
                                    <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                    </div>

                                )} */}
                                {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                    <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                    </div>

                                )}
                            </div>
                        )}
                    </div>
                    {(mode === ADMIN_FORM_MODES.add && (
                        <div style={{ marginTop: '2px', fontSize: '14px', display: 'flex', color: '#99A0A8' }}>
                            <span style={{ opacity: '0.4' }}>Please select Skill to add</span>
                        </div>
                    ))}
                    <div style={{ minHeight: '22.0625rem', marginTop: 22, border: '1px solid #D6D6D7', borderRadius: '8px 8px 8px 8px', backgroundColor: '#FFFFFF' }}>
                        <div style={{ paddingTop: '10px', paddingLeft: '15px', fontSize: '14px', fontWeight: '400', color: '#212121' }}>
                            {(mode === ADMIN_FORM_MODES.add) ? propsSkills?.values?.skills?.length + ' Selected...' : propsSkills?.values?.skills?.length + ' Skills Selected'}
                        </div>
                        <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                            <Divider sx={{ borderColor: "#D6D6D7" }} />
                        </span>
                        <div style={{ marginTop: '20px', marginLeft: '17px', minHeight: '2.75rem', paddingRight: '17px' }}>
                            <CustomAutoComplete
                                disabled={mode === ADMIN_FORM_MODES.view}
                                mode='Add'
                                limitTags={5}
                                name="skills"
                                placeholder={"Search Skill Name"}
                                Datas={skillOption}
                                optionLabelName='skillName'
                                showCheckBox={true}
                                multiple={true}
                                showselectall="true"
                                popupIcon={null}
                            />
                        </div>
                    </div>

                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} >
                            <OZFCheckbox
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="isTransferSkill"
                                label="Use for Skill Transfers"
                                labelPosition="end"
                            />
                        </Grid>
                    </Grid>

                    {propsSkills?.values?.isTransferSkill === true &&
                        <>
                            <div style={{ display: 'flex', alignContent: 'center', marginTop: '10px' }}>
                                <Typography sx={formHeading} flexGrow={1}>{propsSkills?.values?.isTransferSkill === true ? 'Transfer Skills *' : 'Transfer Skills'}</Typography>
                            </div>

                            <Grid style={{fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={12} >
                                    <div style={{ marginTop: '20px', marginLeft: '13px', minHeight: '2.75rem', paddingRight: '17px' }}>
                                        <CustomAutoComplete
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            labelHeader='Value *'
                                            mode='Add'
                                            limitTags={5}
                                            name="transferSkills"
                                            Datas={skillOption}
                                            optionLabelName='skillName'
                                            showCheckBox={true}
                                            multiple={true}
                                            showselectall="true"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    }

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.add) ? { marginTop: 9, marginBottom: '10px' } : { marginTop: 9 }} justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                Cancel
                            </Button>
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view}
                                onClick={() => {
                                    if (mode === ADMIN_FORM_MODES.add) {
                                        handleNext();
                                    } else if (mode === ADMIN_FORM_MODES.edit) {
                                        if (!propsSkills.isValid) {
                                            propsSkills.handleSubmit();
                                            showErrorNotification("Please Check the values and fill all the mandatory field");
                                        }
                                    }
                                }}
                                variant="contained" type="submit" form="campaignOutbound-form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            )
        }

        const Script = (props) => {
            const scriptProps = props?.props
            const tempScripts = 'script';
            const errorScript = getIn(scriptProps.errors, tempScripts);
            const touchScript = getIn(scriptProps.touched, tempScripts);

            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>Script</Typography>

                        {mode !== ADMIN_FORM_MODES.add && (
                            <div>
                                {/* {authPage(AdminPages.OUTBOUND_CAMP_EDIT) && (
                                    <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                    </div>

                                )} */}
                                {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                      <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                      <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                  </div>

                                )}
                            </div>
                        )}
                    </div>
                    <div style={{ marginTop: '2px', fontSize: '14px', display: 'flex', color: '#99A0A8' }}>
                        <span style={{ opacity: '0.5' }}>Write script for the campaign</span>
                    </div>
                    <div style={{ minHeight: '22.0625rem', marginTop: 10, border: '1px solid #D6D6D7', borderRadius: '8px 8px 8px 8px', backgroundColor: '#FFFFFF' }}>
                        <div style={{ marginTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                            <OZRichText disable={mode === ADMIN_FORM_MODES.view} name="script" placeholder=' ' />
                        </div>
                    </div>
                    <Typography sx={scriptStyle} >{(errorScript || touchScript) ? errorScript : ""}</Typography>

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.add) ? { marginTop: 9, marginBottom: '10px' } : { marginTop: 9 }} justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                Cancel
                            </Button>
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={() => {
                                if (!scriptProps.isValid) {
                                    scriptProps.handleSubmit();
                                    showErrorNotification("Please Check the values and fill all the mandatory field")
                                }
                            }} variant="contained" type={(scriptProps.isValid) ? "submit" : null} form="campaignOutbound-form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            )
        }

        const StepperIC = (props) => {
            let iconColor = props.active || props.completed ? '#3D8BF8' : '#7A7A7A'
            return (
                <div style={{
                    paddingTop: '2.5px', border: `1px dashed ${iconColor}`,
                    borderRadius: '50%',
                    height: '24px',
                    width: '24px',
                    color: iconColor,
                    fontWeight: '400',
                    fontSize: '12px', textAlign: 'center',
                    cursor: "pointer",
                }}>
                    {props.icon}
                </div>

            )
        }

        function getInitData() {
            // if (mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) {
            let localData = localStorage.getItem('OutboundForm');
            if (localData) {
                return JSON.parse(localData);
            } else {
                return initData;
            }
            // } else {
            //     let localData = localStorage.getItem('OutboundForm');
            //     if (localData) {
            //         return JSON.parse(localData);
            //     } else {
            //         return initData;
            //     }
            // }
        }

        // function getInitData(){
        //     if(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit){
        //         let localData = localStorage.getItem('OutboundForm');
        //         if (localData) {
        //             return JSON.parse(localData);
        //         } else {
        //             return initData;
        //         }
        //     }else if(mode === ADMIN_FORM_MODES.add){
        //         return initData;
        //     }
        // }

        return (
            <div className='oz-campaignOut-add-form'>
                <Formik
                    // initialValues={(JSON.parse(localStorage.getItem('OutboundForm'))) || initData}
                    validateOnMount
                    initialValues={getInitData()}
                    // initialValues={(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? (JSON.parse(localStorage.getItem('OutboundForm')) || initData) : initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mode === ADMIN_FORM_MODES.add || mode === ADMIN_FORM_MODES.edit) {
                            localStorage.setItem('OutboundForm', JSON.stringify(values));
                        }
                        let saveData = structuredClone(values);
                        if(saveData.script){
                            let newParser = "";
                            const regex = /^[\u0000-\u00FF]*$/;
                            const isLatin1Text = regex.test(saveData.script);
                            if (!isLatin1Text) {
                                showErrorNotification("Script character length cannot exceed 7,000 characters and should not include icons or emojis.");
                                return null;
                            }

                            const parser = new DOMParser();
                            const parsedHtml = parser.parseFromString(saveData.script, 'text/html');
                            const textContent = parsedHtml.documentElement.textContent;
                            // newParser = textContent.trim();
                            newParser = textContent.trim().replace(/\s+/g, ' ');
                            if(textContent.length > 7000){
                                showErrorNotification("Script character length cannot exceed 7,000 characters and should not include icons or emojis.");
                                return null;
                            }
                        }
                        saveData.dynamicDID = saveData?.dynamicDID?.length !== 0 ? saveData?.dynamicDID?.map(item => item.dynamicDID).join(',') : "";
                        saveData.fallbackDynamicDID = saveData?.fallbackDynamicDID?.length !== 0 ? saveData?.fallbackDynamicDID?.map(item => item.fallbackDynamicDID).join(',') : "";
                        if (saveData.campaignType) {
                            if (saveData.campaignType === 'Progressive') {
                                if (saveData.dialInterval === null || saveData.customerRingingTime === null) {
                                    showErrorNotification("Please Check the Mandatory parameters for the Progressive Type Campaign");
                                    return
                                }
                            }
                            else if (saveData.campaignType === 'IVR') {
                                if (saveData.dialInterval === null || saveData.customerRingingTime === null || saveData.ivrFlow === null || saveData.dataLimit === null || saveData.dataLimit === '') {
                                    showErrorNotification("Please Check the Mandatory parameters for the IVR Type Campaign");
                                    return
                                }
                            }
                            else if (saveData.campaignType === 'Predictive') {
                                if (saveData.customerRingingTime === null || saveData.ruleNac === null || saveData.ruleNac === '' || saveData.dropRatio === null || saveData.dropRatio === '') {
                                    showErrorNotification("Please Check the Mandatory parameters for the Predictive Type Campaign");
                                    return
                                }
                            }
                            else if (saveData.campaignType === 'Preview') {
                                let flag = 0;
                                if (saveData.customerFirst === true) {
                                    if (saveData.customerRingingTime === null || saveData.customerRingingTime === "") {
                                        flag = 1;
                                        showErrorNotification("Please Check the Mandatory parameters for the Preview Type Campaign");
                                        return
                                    }
                                }
                                if (flag === 1) {
                                    return;
                                }
                            }
                        }

                        if (saveData?.retryRules === true) {
                            let flag = 0
                            if (saveData?.perDayRetries === null || saveData?.noOfDaysToRetry === null || saveData?.perDayRetries === "" || saveData?.noOfDaysToRetry === "") {
                                flag = 1;
                                showErrorNotification("Please check the Mandatory Parameters in Retry Configurations");
                                return
                            }
                            if (props?.values?.perDayRetries < 1 || props?.values?.perDayRetries > 20) {
                                flag = 1;
                                showErrorNotification("Max no of tries per day should be a digit and between 1 and 20.");
                                return;
                            }
                            if (props?.values?.noOfDaysToRetry < 1 || props?.values?.noOfDaysToRetry > 20) {
                                flag = 1;
                                showErrorNotification("Max no of days to Retry should be a digit and between 1 and 20.");
                                return;
                            }
                            // saveData?.retryConditions?.map((item) => {
                            //     if (item?.dialStatus?.length === 0 || item?.dialStatus === null) {
                            //         flag = 1;
                            //         showErrorNotification("Customer Status in retry Configuration cannot be empty");
                            //         return null;
                            //     }
                            //     item?.config?.map((configItem) => {
                            //         if (configItem?.intervalDays === "" || configItem?.intervalDays === " ") {
                            //             flag = 1;
                            //             showErrorNotification("InterVal Days in retry Configuration cannot be empty");
                            //             return null;
                            //         }
                            //         if (configItem?.intervalHours === "" || configItem?.intervalHours === " ") {
                            //             flag = 1;
                            //             showErrorNotification("InterVal Hours in retry Configuration cannot be empty");
                            //             return null;
                            //         }
                            //         if (configItem?.intervalMinutes === "" || configItem?.intervalMinutes === " ") {
                            //             flag = 1;
                            //             showErrorNotification("InterVal Minutes in retry Configuration cannot be empty");
                            //             return null;
                            //         }
                            //         return null;
                            //     })
                            //     return null;
                            // })

                            //::::::::::::::::::::This i am doing when all options is selected for the APi to call:::::://
                            const transformedDatasNew = saveData?.retryConditions?.map((item) => {
                                const updatedParameters = item?.parameters?.map((param) => {
                                    if (param?.type === "Customer Status") {
                                        // if (param?.value?.length === dialStatusData?.length) {
                                        if (param?.value?.length === dialStatusDataForFail?.length) {
                                            return {
                                                ...param,
                                                value: [
                                                    {
                                                        "id": "all",
                                                        "label": "all"
                                                    }
                                                ]
                                            };
                                        }
                                    }
                                    if (param?.type === "Dispositions") {
                                        if (param?.value?.length === saveData?.dispositions?.length) {
                                            return {
                                                ...param,
                                                value: [
                                                    {
                                                        "id": "all",
                                                        "reason": "all"
                                                    }
                                                ]
                                            };
                                        }
                                    }
                                    return param;
                                });

                                return {
                                    ...item,
                                    parameters: updatedParameters
                                };
                            });

                            saveData.retryConditions = transformedDatasNew;
                            //::::::::::This i am doing when all options is selected for the APi to call:::::://

                            saveData?.retryConditions?.map((retryItem, retryIndex) => {
                                if(retryItem?.callStatus === null || retryItem?.callStatus === undefined || retryItem?.callStatus === ""){//this is new written for the Call Status condition new:::::::::::::::://
                                    flag = 1;
                                    showErrorNotification(`Please select Call Status in Retry Configuration for Case ${retryIndex + 1}`);
                                    return null;
                                }else{//this is new written for the Call Status condition new:::::::::::::::://
                                    retryItem.parameters.map((paramItem, paraIndex) => {
                                        if (paramItem.type !== "" || paramItem.type !== " ") {
                                            if (paramItem.value?.length === 0) {
                                                flag = 1;
                                                showErrorNotification(`Please select value in Retry Configuration for Case ${retryIndex + 1} for parameter ${paramItem.type}`);
                                                return null;
                                            }
                                        }
                                        return null;
                                    })
                                }//this is new written for the Call Status condition new:::::::::::::::://

                                retryItem?.config?.map((configitem, configIndex) => {
                                    if (configitem?.intervalDays === "" || configitem?.intervalDays === " " || configitem?.intervalDays < 0 || configitem?.intervalDays > 20) {
                                        flag = 1;
                                        showErrorNotification(`Retry Interval Days in retry Configuration Case ${retryIndex + 1} cannot be empty and should be between 0 and 20`);
                                        return null;
                                    }
                                    if (configitem.intervalHours === "" || configitem.intervalHours === " " || configitem?.intervalHours < 0 || configitem?.intervalHours > 23) {
                                        flag = 1;
                                        showErrorNotification(`Retry Interval Hours in retry Configuration Case ${retryIndex + 1} cannot be empty and should be between 0 and 23`);
                                        return null;
                                    }
                                    if (configitem.intervalMinutes === "" || configitem.intervalMinutes === " " || configitem?.intervalMinutes < 0 || configitem?.intervalMinutes > 59) {
                                        flag = 1;
                                        showErrorNotification(`Retry Interval Minutes in retry Configuration Case ${retryIndex + 1} cannot be empty and should be between 0 and 59`);
                                        return null;
                                    }
                                    if (configitem.intervalDays === 0 && configitem.intervalHours === 0 && configitem.intervalMinutes === 0) {
                                        flag = 1;
                                        showErrorNotification(`All values in Retry Interval ${configIndex + 1}${configIndex === 0 ? 'st' : configIndex === 1 ? 'nd' : configIndex === 2 ? 'rd' : 'th'} for Case ${retryIndex + 1} cannot be 0`);
                                        return null;
                                    }
                                    return null;
                                })
                                return null;
                            })
                            if (flag === 1) {
                                return;
                            }
                        }

                        if (saveData.dispositionType.id !== 'None') {
                            if (saveData.sla === null || saveData.sla === '') {
                                showErrorNotification("Please pass Wraup Time");
                                return
                            }
                        }


                        if (!saveData?.fallbackDID || saveData?.fallbackDID?.length === 0 || saveData?.fallbackDID === 'None') {
                            saveData.fallbackDID = null;
                        }

                        if (saveData.popUrlAt.id === 'Plugin') {
                            if (saveData.screenPopUrlInBound === null || saveData?.screenPopUrlInBound?.length === 0) {
                                showErrorNotification("Plugin Name is required.");
                                return
                            }
                        }

                        if (saveData?.amdActive === true) {
                            if (saveData?.amdType?.id === 'PlayVoiceMessage') {
                                if (saveData.amdUrl === "") {
                                    showErrorNotification("Voice Message URL is required.");
                                    return;
                                }
                            } else if (saveData?.amdType?.id !== 'PlayVoiceMessage') {
                                saveData.amdUrl = "";
                            }
                        }

                        if (saveData?.dncEnabled) {
                            saveData.runtimeStart = "00:00:00";
                            saveData.runtimeEnd = "23:59:59";
                        }

                        if (saveData?.campaignType === "Predictive") {
                            saveData.acwNac = false;
                            saveData.customerFirst = true;
                        }
                        if (saveData?.blockNumber?.id === 'None') {
                            saveData.blockNumberGroups = [];
                        }
                        if (saveData?.skills?.length === 0) {
                            showErrorNotification("Please pass at least one valid Skill");
                            return
                        }
                        if (saveData.previewDataMap === null) {
                            showErrorNotification("Please pass at least one valid Mapping Name");
                            return
                        }
                        if (mode === ADMIN_FORM_MODES.add) {
                            if (saveData.popUrlAt.id === 'Plugin') {
                                if (saveData.screenPopUrlInBound !== null) {
                                    saveData.screenPopUrl = saveData.screenPopUrlInBound?.id;
                                    delete saveData.screenPopUrlInBound;
                                }
                            } else {
                                delete saveData.screenPopUrlInBound; //to delete the screenPopURL Inbound
                            }
                            setNewLoading(true); //this is commented for now so no spinner will come
                            delete saveData.retryConditions;
                            addCampaignOutboundForm(saveData);
                        }
                        if (mode === ADMIN_FORM_MODES.edit) {
                            setNewLoading(true);//this is commented for now so no spinner will come
                            let idEdit = props.data.campaignId;

                            if (saveData.popUrlAt.id === 'Plugin') {
                                if (saveData.screenPopUrlInBound !== null) {
                                    saveData.screenPopUrl = saveData.screenPopUrlInBound?.id;
                                    delete saveData.screenPopUrlInBound;
                                }
                            } else {
                                delete saveData.screenPopUrlInBound; //to delete the screenPopURL Inbound
                            }

                            if (!saveData?.callPrefix || saveData?.callPrefix?.length === 0) {
                                saveData.callPrefix = null;
                            }
                            if (!saveData?.fallbackDID || saveData?.fallbackDID?.length === 0 || saveData?.fallbackDID === 'None') {
                                saveData.fallbackDID = null;
                            }
                            // if (saveData.retryRules === false) {
                            //     saveData.perDayRetries = null;
                            //     saveData.noOfDaysToRetry = null;
                            // }

                            //for this discussion needs::::::::::::://
                            if (saveData.retryRules === false || saveData.retryRules === null) {
                                saveData.perDayRetries = null;
                                saveData.noOfDaysToRetry = null;
                                // if (saveData.prevRetryConditionValue === null) {
                                //     saveData.retryConditions = null;
                                // }
                            }
                            // if (saveData.retryRules === false || saveData.retryRules === null) {
                            //     if (saveData.prevRetryConditionValue === null) {
                            //         if (saveData?.retryConditions?.length === 1 && saveData?.retryConditions[0]?.dialStatus?.length === 0 && saveData?.retryConditions[0]?.dispositions?.length === 0 && saveData?.retryConditions[0]?.condition === "") {
                            //             saveData.retryConditions = null;
                            //         }
                            //     }
                            // }
                            //for this discussion needs::::::::::::://

                            // if (saveData.retryConditions !== null) {
                            //     let temporarayRetryData = structuredClone(saveData?.retryConditions);
                            //     let temp = temporarayRetryData?.map((retryVal, index) => {
                            //         temporarayRetryData[index].noOfTries = retryVal?.config?.length;
                            //         return temporarayRetryData[index];
                            //     })
                            //     saveData.retryConditions = JSON.stringify(temp);
                            // }
                            if (saveData.retryConditions !== null) {
                                let newConvertedData = [];

                                //form here we will remove parameters :::::::::::::://
                                const convertedData = saveData?.retryConditions?.map((item) => {
                                    const dispositions = item?.parameters?.find((param) => param.type === "Dispositions");
                                    const dialStatus = item?.parameters?.find((param) => param.type === "Customer Status");

                                    return {
                                        dispositions: dispositions?.value || [],
                                        dialStatus: dialStatus?.value || [],
                                        config: item.config,
                                        noOfTries: item.noOfTries,
                                        callStatus: item?.callStatus || ""
                                    };
                                });

                                saveData?.retryConditions?.map((item, itemIndex) => {
                                    const data = {
                                        ...convertedData[itemIndex],
                                        condition: `${item.parameters[0].condition}`
                                    }
                                    newConvertedData.push(data);
                                })
                                //::::::::::till here parameters removed :::::::::::::::::::://

                                //::::::::::::::::::::::from here making in array of strings::::::::::::::::::::::::::::::::///
                                function convertDialStatus(dialStatus) {
                                    return dialStatus.map(status => {
                                        return `${status.id}`;
                                    });
                                }

                                function convertDispositions(dispositions) {
                                    return dispositions.map((dispo, dispoIndex) => {
                                        return `${dispo.reason}`;
                                    });
                                }

                                function convertCallStatus(callStatus) {
                                    if(callStatus === 'Success'){
                                        return 'success'
                                    }else if(callStatus === 'Fail'){
                                        return 'fail'
                                    }else{
                                        return 'fail';
                                    }
                                }

                                const transformedData = newConvertedData?.map(item => {
                                    if (item.hasOwnProperty("dialStatus") && item.dialStatus.length !== 0) {
                                        item.dialStatus = convertDialStatus(item.dialStatus);
                                    }
                                    if (item.hasOwnProperty('dispositions') && item.dispositions.length !== 0) {
                                        item.dispositions = convertDispositions(item.dispositions);
                                    }
                                    if (item.hasOwnProperty('callStatus')) {
                                        item.callStatus = convertCallStatus(item.callStatus);
                                    }
                                    return item;
                                });
                                saveData.retryConditions = transformedData;
                                //::::::::::::::::::::::Till here  making in array of strings ::::::::::::::::::::::::::::::::///
                            }
                            updateCampaignOutboundForm(saveData, idEdit);
                        }
                    }}
                >
                    {
                        props => (
                            <div>
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) && (
                                    <div>
                                        <form onSubmit={props.handleSubmit} id='campaignOutbound-form' className='ozcw-scrollbar' style={{
                                            maxHeight: 'auto',
                                            overflowY: 'auto',
                                            padding: '5px'
                                        }}>
                                            {loading ?
                                                (
                                                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <LoadingScreen />
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div style={{ marginTop: '18px' }}>
                                                        <TabContext value={value}>
                                                            <Box sx={{ borderColor: 'divider', borderRadius: '0px 0px 3px 3px' }} >
                                                                <TabList
                                                                    TabIndicatorProps={{
                                                                        style: { backgroundColor: "#008EFF", marginBottom: "1.125rem" },
                                                                    }}
                                                                    value={value}
                                                                    onChange={(e, newvalue) => handleChange(e, newvalue, props)}
                                                                >
                                                                    <OZCustomToggleButton label="Settings" value="0" sx={{ ...tabButtonStyle, borderRight: 0 }}>Settings</OZCustomToggleButton>
                                                                    <OZCustomToggleButton label="General Info" value="1" sx={{ ...tabButtonStyle, borderRight: 0 }}>General Info</OZCustomToggleButton>
                                                                    <OZCustomToggleButton label="Skills" value="2" sx={{ ...tabButtonStyle, borderRight: 0 }}>Skills</OZCustomToggleButton>
                                                                    <OZCustomToggleButton label="Script" value="3" sx={{ ...tabButtonStyle, borderRight: 0 }}>Script</OZCustomToggleButton>
                                                                    <OZCustomToggleButton label="Data" value="4" sx={{ ...tabButtonStyle }}>Data</OZCustomToggleButton>
                                                                </TabList>
                                                            </Box>
                                                            <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                                                <Divider sx={{ borderColor: "#E6E5E6", width: (value === '4') ? '89vw' : '60vw', marginTop: '-19px' }} />
                                                            </span>
                                                            <div style={{ display: 'flex', alignContent: 'center' }}>
                                                                <div style={{ width: (value === '4') ? '100vw' : '60vw', minHeight: (value === '4') ? "35rem" : '23rem', borderRadius: '0px 0px 8px 8px', backgroundColor: (value === '4') ? '' : '#FFFFFF' }}>
                                                                    <TabPanel value="0">
                                                                        <BasicSettings props={props} />
                                                                    </TabPanel>
                                                                    <TabPanel value="1">
                                                                        <GeneralInformation props={props} formikProps={props} />
                                                                    </TabPanel>
                                                                    <TabPanel value="2">
                                                                        <Skills props={props} />
                                                                    </TabPanel>
                                                                    <TabPanel value="3">
                                                                        <Script props={props} />
                                                                    </TabPanel>
                                                                    <TabPanel value="4">
                                                                        <CampaignDataTab props={props} />
                                                                    </TabPanel>
                                                                </div>
                                                            </div>
                                                        </TabContext>
                                                    </div>
                                                )
                                            }
                                            {newLoading && (
                                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                                    <LoadingScreen />
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                )}

                                {mode === ADMIN_FORM_MODES.add && (
                                    <div>
                                        <div>
                                            <Box sx={{ borderRadius: '0px 0px 3px 3px', width: '45vw', marginTop: 2 }}>
                                                <Stepper activeStep={activeStep}>
                                                    {steps.map((label, index) => (
                                                        <Step key={label} completed={completed[index]}>
                                                            <StepLabel color='inherit' onClick={handleStep(index)} StepIconComponent={StepperIC}>
                                                                {label}
                                                            </StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                            </Box>
                                        </div>
                                        <form onSubmit={props.handleSubmit} id='campaignOutbound-form' className='ozcw-scrollbar' style={{
                                            maxHeight: 'auto',
                                            overflowY: 'auto',
                                            padding: '5px'
                                        }}>
                                            <div style={{ display: 'flex', alignContent: 'center' }}>
                                                <div style={{ width: '60vw', minHeight: '23rem', borderRadius: '8px', marginTop: 4, backgroundColor: '#FFFFFF' }}>
                                                    <React.Fragment >
                                                        {activeStep === 0 && (
                                                            <div style={stepperStyle}>
                                                                <BasicSettings props={props} />
                                                            </div>
                                                        )}
                                                        {activeStep === 1 && (
                                                            <div style={stepperStyle} >
                                                                <GeneralInformation props={props} />
                                                            </div>
                                                        )}
                                                        {activeStep === 2 && (
                                                            <div style={stepperStyle} >
                                                                <Skills props={props} />
                                                            </div>
                                                        )}
                                                        {activeStep === 3 && (
                                                            <div style={stepperStyle} >
                                                                <Script props={props} />
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                </div>
                                            </div>
                                        </form>
                                        {newLoading && (
                                            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                                <LoadingScreen />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    }
                </Formik>
            </div >
        )
    }
    return (
        <div
            onClose={closeCampaignOutboundForm}
            // onEdit={onClickEdit}
            mode={mode}
        >
            {mode === ADMIN_FORM_MODES.add && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeCampaignOutboundForm}>{'Campaign / ' + mode + ' Campaign'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#000000',
                            }} flexGrow={1} alignSelf='center'>{mode + ' Outbound Campaign'}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.view && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} onClick={closeCampaignOutboundForm} alignSelf='center'>{'Campaign / Outbound Campaign'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center'>{props.data.campaignName}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.edit && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeCampaignOutboundForm}>{'Campaign / Outbound Campaign'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center'>{props.data.campaignName}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.add && <DataForm />}
            {mode === ADMIN_FORM_MODES.view && <DataForm data={props.data} />}
            {mode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}

        </div>
    );
};

export default CampaignOutboundForm;